import {
  AsyncPaginate,
  WithAsyncPaginateType,
} from "react-select-async-paginate";
import "./style.css";

const AsyncPaginateBootstrap: WithAsyncPaginateType = (props) => {
  return (
    <div className="react-select form-control p-0">
      <AsyncPaginate {...props}></AsyncPaginate>
    </div>
  );
};

export default AsyncPaginateBootstrap;
