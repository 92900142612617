import InvoiceForm from "../components/invoice/InvoiceForm";

const InvoicePage = () => {
  return (
    <>
      <InvoiceForm />
    </>
  );
};

export default InvoicePage;
