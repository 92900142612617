import TaxPayerForm from "../components/tax-payer/TaxPayerForm";

const TaxPayerPage = () => {
  return (
    <>
      <TaxPayerForm />
    </>
  );
};

export default TaxPayerPage;
