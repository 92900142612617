import { MINIO_URL_SAME_ADDR, MINIO_URL_SAME_ADDR_PORT } from "../config/minio";

export const getObjectMinioUrl = (minioUrl: string): string => {
  if (MINIO_URL_SAME_ADDR) {
    const hostname = window.location.hostname;
    return `http://${hostname}:${MINIO_URL_SAME_ADDR_PORT}/get_object/?presigned_url=${encodeURIComponent(
      minioUrl
    )}`;
  }

  return minioUrl;
};

export const putObjectMinioUrl = (minioUrl: string): string => {
  if (MINIO_URL_SAME_ADDR) {
    const hostname = window.location.hostname;
    return `http://${hostname}:${MINIO_URL_SAME_ADDR_PORT}/put_object/?presigned_url=${encodeURIComponent(
      minioUrl
    )}`;
  }

  return minioUrl;
};
