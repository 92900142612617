import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import Department from "../../models/department";
const RECORS_PER_SEARCH = 20;

type DepartmentSelectProps = {
  value: Department | null;
  placeholder?: React.ReactNode;
  onChange: (_: Department | null) => void;
};

const DepartmentSelect: React.FC<DepartmentSelectProps> = ({
  value,
  placeholder,
  onChange,
}) => {
  const loadDepartmentOptions = async (
    s: string,
    currentOpts: readonly (
      | Department
      | { options: Department[]; label?: string }
    )[]
  ): Promise<
    Response<Department, { options: Department[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = undefined;

    if (s !== "") {
      additionalParams = new Map();
      additionalParams.set("search", s);
    }

    const departmentsPaginated = await getList<Department>(
      "/locations/departments/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Department[] = [];
    let count = 0;

    if (departmentsPaginated.data !== undefined) {
      newOpts = departmentsPaginated.data.items;
      count = departmentsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={placeholder ? placeholder : "Seleccione un Departamento"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadDepartmentOptions}
      getOptionLabel={(option: Department) => `${option.description}`}
      getOptionValue={(option: Department) => `${option.code}`}
      value={value}
    />
  );
};

export default DepartmentSelect;
