import { DashboardState, DashboardActionTypes } from "../types/dashboard";

const initialState: DashboardState = {
  sidebarShow: false,
  sidebarMinimize: false,
  initialized: false,
};

export const INITIALIZE_DASHBOARD = "initialize_dashboard";
export const SET_SHOW_DASHBOARD = "set_show_dashboard";
export const SET_MINIMIZE_DASHBOARD = "set_minimize_dashboard";
export const SET_DASHBOARD_INITIALIZED = "set_dashboard_initialized";

export default function DashboardReducer(
  state: DashboardState = initialState,
  action: DashboardActionTypes
): DashboardState {
  switch (action.type) {
    case INITIALIZE_DASHBOARD:
      const initializePayload = action.payload as DashboardState;
      return {
        ...state,
        sidebarShow: initializePayload.sidebarShow,
        sidebarMinimize: initializePayload.sidebarMinimize,
        initialized: initializePayload.initialized,
      };
    case SET_SHOW_DASHBOARD:
      return {
        ...state,
        sidebarShow: action.payload as boolean,
        initialized: true,
      };
    case SET_MINIMIZE_DASHBOARD:
      return {
        ...state,
        sidebarMinimize: action.payload as boolean,
        initialized: true,
      };
    case SET_DASHBOARD_INITIALIZED:
      return {
        ...state,
        initialized: true,
      };
    default:
      return state;
  }
}
