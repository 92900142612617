import {
  RECEIPT_STATUS_CANCELLATION_FAILED,
  RECEIPT_STATUS_CANCELLATION_REQUESTED,
  RECEIPT_STATUS_CANCELLED,
  RECEIPT_STATUS_CHOICES,
  RECEIPT_STATUS_ERROR,
  RECEIPT_STATUS_GENERATED,
  Receipt,
} from "../../models/receipt";

interface ReceiptCrudStatusProps {
  receipt: Receipt;
}

const ReceiptCrudStatus: React.FC<ReceiptCrudStatusProps> = ({ receipt }) => {
  let badgeClassName = "badge-info";

  if (receipt.status === RECEIPT_STATUS_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (receipt.status === RECEIPT_STATUS_GENERATED) {
    badgeClassName = "badge-success";
  }

  if (receipt.status === RECEIPT_STATUS_CANCELLATION_REQUESTED) {
    badgeClassName = "badge-light";
  }

  if (receipt.status === RECEIPT_STATUS_CANCELLED) {
    badgeClassName = "badge-info";
  }

  if (receipt.status === RECEIPT_STATUS_CANCELLATION_FAILED) {
    badgeClassName = "badge-danger";
  }

  return (
    <td className="text-center">
      <span
        className={`badge badge-pill ${badgeClassName}`}
        style={{ fontSize: "14px" }}
      >
        {RECEIPT_STATUS_CHOICES.get(receipt.status!)}
      </span>
    </td>
  );
};

export default ReceiptCrudStatus;
