import { useCallback, useEffect, useState } from "react";
import {
  INVOICE_STATUS_CHOICES,
  Invoice,
  formatInvoiceNumber,
} from "../../models/invoice";
import { useHistory } from "react-router-dom";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert, warningAlert } from "../utils/messages";
import { getItem, getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CInputGroup,
  CInputGroupText,
  CLabel,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from "@coreui/react";

import moment from "moment";

import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import { BsFillPlusCircleFill } from "react-icons/bs";
import InvoiceDeleteModal from "./InvoiceDeleteModal";
import { CURRENCY_CHOICES } from "../../currency/available-currencies";
import { formatToCurrency } from "../../currency/format";
import InvoiceCancellationModal from "./InvoiceCancellationModal";
import { formatUTCDateTime } from "../../utils/dates";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getObjectMinioUrl } from "../../minio/urls";
import InvoiceConsultByCdcModal from "./InvoiceConsultByCdcModal";
import InvoiceCrudActions from "./InvoiceCrudActions";
import InvoiceCrudStatus from "./InvoiceCrudStatus";
import InvoiceResendModal from "./InvoiceResendModal";
import {
  SET_ENVIRONMENT_CHOICES,
  SET_ENVIRONMENT_DEVELOPMENT,
  SET_ENVIRONMENT_PRODUCTION,
} from "../../models/set-environment";
import InvoiceSendEmailModal from "./InvoiceSendEmailModal";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import { INVOICES_CREATE, INVOICES_RETRIEVE } from "../../auth/permissions";
import Establishment from "../../models/establishment";
import EstablishmentMultiSelect from "../establishments/EstablishmentMultiSelect";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointMultiSelect from "../dispatch-point/DispatchPointMultiSelect";
import { emptyValueOnUndefined } from "../../utils/fields";
import InvoiceCancellationRetryModal from "./InvoiceCancellationRetryModal";
import InvoiceCancellationRevertModal from "./InvoiceCancellationRevertModal";

const ITEMS_PER_PAGE = 20;

const InvoiceCrud = () => {
  const fields = [
    { key: "invoiceDate", _classes: ["text-center"], label: "Fecha" },
    { key: "stampIdentifier", _classes: ["text-center"], label: "Timbrado" },
    { key: "invoiceNumber", _classes: ["text-center"], label: "Nro. Factura" },
    { key: "clientRuc", _classes: ["text-center"], label: "RUC" },
    {
      key: "clientSocialName",
      _classes: ["text-center"],
      label: "Razón Social",
    },
    { key: "currency", _classes: ["text-center"], label: "Moneda" },
    { key: "totalDiscount", _classes: ["text-center"], label: "Descuento" },
    {
      key: "amountAfterDiscount",
      _classes: ["text-center"],
      label: "Monto",
    },
    { key: "status", _classes: ["text-center"], label: "Estado" },
    { key: "files", _classes: ["text-center"], label: "Archivos" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  // Filters
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [setEnvironment, setSetEnvironment] = useState<string | undefined>(
    undefined
  );
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [establishments, setEstablishments] = useState<Establishment[]>([]);
  const [dispatchPoints, setDispatchPoints] = useState<DispatchPoint[]>([]);

  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  // Delete control
  const [showInvoiceDeleteModal, setShowInvoiceDeleteModal] = useState(false);
  const [deletingInvoice, setDeletingInvoice] = useState<Invoice | undefined>(
    undefined
  );

  // Cancel control
  const [showInvoiceCancellationModal, setShowInvoiceCancellationModal] =
    useState(false);
  const [cancellingInvoice, setCancellingInvoice] = useState<
    Invoice | undefined
  >(undefined);

  // Consult by cdc control
  const [showInvoiceConsultByCdcModal, setShowInvoiceConsultByCdcModal] =
    useState(false);
  const [consultingInvoice, setConsultingInvoice] = useState<
    Invoice | undefined
  >(undefined);

  // Resend control
  const [showInvoiceResendModal, setShowInvoiceResendModal] = useState(false);
  const [resendingInvoice, setResendingInvoice] = useState<Invoice | undefined>(
    undefined
  );

  // Send Email control
  const [showSendEmailModal, setShowSendEmailModal] = useState(false);
  const [sendingEmail, setSendingEmail] = useState<Invoice | undefined>(
    undefined
  );

  // Retry cancel control
  const [
    showInvoiceCancellationRetryModal,
    setShowInvoiceCancellationRetryModal,
  ] = useState(false);
  const [retryCancelInvoice, setRetryCancelInvoice] = useState<
    Invoice | undefined
  >(undefined);

  // Revert cancel control
  const [
    showInvoiceCancellationRevertModal,
    setShowInvoiceCancellationRevertModal,
  ] = useState(false);
  const [revertCancelInvoice, setRevertCancelInvoice] = useState<
    Invoice | undefined
  >(undefined);

  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    let search = urlParams.get("search") ? urlParams.get("search") : "";

    let startDateStr = urlParams.get("start_date")
      ? urlParams.get("start_date")
      : null;

    let startDate: Date | null = null;
    if (startDateStr !== null && startDateStr !== "") {
      startDate = new Date(startDateStr);
    }

    let endDateStr = urlParams.get("end_date")
      ? urlParams.get("end_date")
      : null;

    let endDate: Date | null = null;
    if (endDateStr !== null && endDateStr !== "") {
      endDate = new Date(endDateStr);
    }

    let setEnvironmentStr = urlParams.get("set_environment");

    let setEnvironment: string | undefined;
    if (setEnvironmentStr !== null) {
      setEnvironment = setEnvironmentStr;
    }

    let statusStr = urlParams.get("status");

    let status: string | undefined;
    if (statusStr !== null) {
      status = statusStr;
    }

    let establishmentCodes = urlParams.getAll("establishment_code");

    let dispatchPointIdentifiers = urlParams.getAll(
      "dispatch_point_identifier"
    );

    return {
      page: page,
      search: search,
      startDate: startDate,
      endDate: endDate,
      setEnvironment: setEnvironment,
      status: status,
      establishmentCodes: establishmentCodes,
      dispatchPointIdentifiers: dispatchPointIdentifiers,
    };
  };

  const fetchInvoices = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string | string[]>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (urlParams.search !== null && urlParams.search !== "") {
      additionalParams.set("search", urlParams.search);
    }

    if (urlParams.startDate !== null) {
      additionalParams.set("start_date", urlParams.startDate.toISOString());
    }

    if (urlParams.endDate !== null) {
      additionalParams.set("end_date", urlParams.endDate.toISOString());
    }

    if (urlParams.setEnvironment !== undefined) {
      additionalParams.set("set_environment", urlParams.setEnvironment);
    }

    if (urlParams.status !== undefined) {
      additionalParams.set("status", urlParams.status);
    }

    if (urlParams.establishmentCodes.length > 0) {
      additionalParams.set("establishment_code", urlParams.establishmentCodes);
    }

    if (urlParams.dispatchPointIdentifiers.length > 0) {
      additionalParams.set(
        "dispatch_point_identifier",
        urlParams.dispatchPointIdentifiers
      );
    }

    setSearch(urlParams.search ? urlParams.search : "");
    setStartDate(urlParams.startDate);
    setEndDate(urlParams.endDate);
    setSetEnvironment(urlParams.setEnvironment);
    setStatus(urlParams.status);

    const invoicesState = await getList<Invoice>(
      "/invoices/",
      limit,
      offset,
      additionalParams
    );

    if (invoicesState.status === SUCCESS) {
      if (invoicesState.data !== undefined) {
        const count = invoicesState.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setInvoices(invoicesState.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = invoicesState.detail
        ? invoicesState.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  const onAddClick = () => {
    history.push(getRoutePath("addInvoice"));
  };

  const onUpdateClick = (invoice: Invoice) => {
    const baseUrl = getRoutePath("addInvoice");
    history.push(`${baseUrl}?id=${invoice.id!}`);
  };

  const onDeleteClick = (invoice: Invoice) => {
    setDeletingInvoice(invoice);
    setShowInvoiceDeleteModal(true);
  };

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("invoices")) {
        return;
      }
      fetchInvoices();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, taxPayer]);

  useEffect(() => {
    setLoading(true);
    fetchInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onInvoiceDeleteCancel = () => {
    setShowInvoiceDeleteModal(false);
  };

  const onInvoiceDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowInvoiceDeleteModal(false);
    setDeletingInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onCancellationClick = (invoice: Invoice) => {
    setCancellingInvoice(invoice);
    setShowInvoiceCancellationModal(true);
  };

  const onInvoiceCancellationCancel = () => {
    setShowInvoiceCancellationModal(false);
  };

  const onInvoiceCancellationSuccess = () => {
    successAlert("Registro anulado con éxito!");
    setShowInvoiceCancellationModal(false);
    setCancellingInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onCancellationRetryClick = (invoice: Invoice) => {
    setRetryCancelInvoice(invoice);
    setShowInvoiceCancellationRetryModal(true);
  };

  const onInvoiceCancellationRetryCancel = () => {
    setShowInvoiceCancellationRetryModal(false);
  };

  const onInvoiceCancellationRetrySuccess = () => {
    successAlert("Anulación reintentada con éxito!");
    setShowInvoiceCancellationRetryModal(false);
    setRetryCancelInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onCancellationRevertClick = (invoice: Invoice) => {
    setRevertCancelInvoice(invoice);
    setShowInvoiceCancellationRevertModal(true);
  };

  const onInvoiceCancellationRevertCancel = () => {
    setShowInvoiceCancellationRevertModal(false);
  };

  const onInvoiceCancellationRevertSuccess = () => {
    successAlert("Anulación revertida con éxito!");
    setShowInvoiceCancellationRevertModal(false);
    setRevertCancelInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onConsultByCdcClick = (invoice: Invoice) => {
    setConsultingInvoice(invoice);
    setShowInvoiceConsultByCdcModal(true);
  };

  const onConsultByCdcCancel = () => {
    setShowInvoiceConsultByCdcModal(false);
  };

  const onConsultByCdcSuccess = () => {
    successAlert("Factura consultada con éxito!");
    setShowInvoiceConsultByCdcModal(false);
    setConsultingInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onResendClick = (invoice: Invoice) => {
    setResendingInvoice(invoice);
    setShowInvoiceResendModal(true);
  };

  const onResendCancel = () => {
    setShowInvoiceResendModal(false);
  };

  const onResendSuccess = () => {
    successAlert("Factura reenviada con éxito!");
    setShowInvoiceResendModal(false);
    setResendingInvoice(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onSendEmailClick = (invoice: Invoice) => {
    setSendingEmail(invoice);
    setShowSendEmailModal(true);
  };

  const onSendEmailCancel = () => {
    setShowSendEmailModal(false);
  };

  const onSendEmailSuccess = () => {
    successAlert("Envío de correo solicitado con éxito!");
    setShowSendEmailModal(false);
    setSendingEmail(undefined);
    setLoading(true);
    fetchInvoices();
  };

  const onXMLClick = async (invoice: Invoice) => {
    const xmlState = await getItem<{ url: string }>(
      `/invoices/${invoice.id}/xml/`
    );

    if (xmlState.status !== SUCCESS || xmlState.data === undefined) {
      errorAlert("Error al tratar de obtener el XML");
      return;
    }
    window.open(getObjectMinioUrl(xmlState.data.url), "_blank");
  };

  const onPDFClick = async (invoice: Invoice) => {
    const pdfState = await getItem<{ url: string }>(
      `/invoices/${invoice.id}/pdf/`
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const onStartDateChange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const date = new Date((value as moment.Moment).toISOString());

    setStartDate(date);
  };

  const onEndDateChange = (value: string | moment.Moment) => {
    const newDate = value as moment.Moment;
    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const date = new Date((value as moment.Moment).toISOString());

    setEndDate(date);
  };

  const onSetEnvironmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSetEnvironment = e.target.value;

    if (
      newSetEnvironment === SET_ENVIRONMENT_DEVELOPMENT ||
      newSetEnvironment === SET_ENVIRONMENT_PRODUCTION
    ) {
      setSetEnvironment(newSetEnvironment);
    } else {
      setSetEnvironment(undefined);
    }
  };

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = e.target.value;

    if (INVOICE_STATUS_CHOICES.has(newStatus)) {
      setStatus(newStatus);
    } else {
      setStatus(undefined);
    }
  };

  const onEstablishmentsChange = (newEstablishments: Establishment[]) => {
    setEstablishments(newEstablishments);
  };

  const onDispatchPointsChange = (newDispatchPoints: Establishment[]) => {
    setDispatchPoints(newDispatchPoints);
  };

  const onSearch = useCallback(() => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);

    if (search !== undefined) {
      urlParams.set("search", search);
    } else {
      urlParams.delete("search");
    }

    if (startDate !== null) {
      urlParams.set("start_date", startDate.toISOString());
    } else {
      urlParams.delete("start_date");
    }

    if (endDate !== null) {
      urlParams.set("end_date", endDate.toISOString());
    } else {
      urlParams.delete("end_date");
    }

    if (setEnvironment !== undefined) {
      urlParams.set("set_environment", setEnvironment);
    } else {
      urlParams.delete("set_environment");
    }

    if (status !== undefined) {
      urlParams.set("status", status);
    } else {
      urlParams.delete("status");
    }

    urlParams.delete("establishment_code");

    if (establishments.length > 0) {
      for (const establishment of establishments) {
        if (establishment.code === undefined) {
          continue;
        }
        urlParams.append("establishment_code", establishment.code);
      }
    }

    urlParams.delete("dispatch_point_identifier");

    if (dispatchPoints.length > 0) {
      for (const dispatchPoint of dispatchPoints) {
        if (dispatchPoint.identifier === undefined) {
          continue;
        }
        urlParams.append("dispatch_point_identifier", dispatchPoint.identifier);
      }
    }

    let url = `?${urlParams.toString()}`;
    history.push(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    startDate,
    endDate,
    setEnvironment,
    status,
    establishments,
    dispatchPoints,
  ]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  const onDownloadKudesClick = useCallback(async () => {
    let additionalParams = new Map<string, string>();

    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (search !== undefined && search !== "") {
      additionalParams.set("search", search);
    }

    if (startDate !== null) {
      additionalParams.set("start_date", startDate.toISOString());
    }
    if (endDate !== null) {
      additionalParams.set("end_date", endDate.toISOString());
    }

    if (setEnvironment !== undefined && setEnvironment !== "") {
      additionalParams.set("set_environment", setEnvironment);
    }

    if (status !== undefined && status !== "") {
      additionalParams.set("status", status);
    }

    const pdfState = await getItem<{ url: string }>(
      "/invoices/kudes/",
      additionalParams
    );

    if (pdfState.status !== SUCCESS || pdfState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(pdfState.data.url), "_blank");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer, search, startDate, endDate, setEnvironment, status]);

  const onDownloadCsvClick = useCallback(async () => {
    let additionalParams = new Map<string, string>();

    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (search !== undefined && search !== "") {
      additionalParams.set("search", search);
    }

    if (startDate !== null) {
      additionalParams.set("start_date", startDate.toISOString());
    }
    if (endDate !== null) {
      additionalParams.set("end_date", endDate.toISOString());
    }

    if (setEnvironment !== undefined && setEnvironment !== "") {
      additionalParams.set("set_environment", setEnvironment);
    }

    if (status !== undefined && status !== "") {
      additionalParams.set("status", status);
    }

    const csvState = await getItem<{ url: string }>(
      "/invoices/csv/",
      additionalParams
    );

    if (csvState.status !== SUCCESS || csvState.data === undefined) {
      errorAlert("Error al tratar de obtener el PDF");
      return;
    }
    window.open(getObjectMinioUrl(csvState.data.url), "_blank");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer, search, startDate, endDate, setEnvironment, status]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Facturas Electrónicas</h3>
              </div>
              <div className="p-2 ">
                <PermissionRequiredComponent permissionName={INVOICES_CREATE}>
                  <CButton
                    color="primary"
                    className="float-right"
                    onClick={onAddClick}
                  >
                    <BsFillPlusCircleFill />
                    &nbsp; Agregar Nuevo
                  </CButton>
                </PermissionRequiredComponent>
              </div>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <h5>Filtros</h5>
                </CCol>
              </CRow>
              <CRow className={"mt-2"}>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Desde:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onStartDateChange}
                    value={startDate !== null ? startDate : ""}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    utc={true}
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Hasta:</CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onEndDateChange}
                    value={endDate !== null ? endDate : ""}
                    locale="es/PY"
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    utc={true}
                    closeOnSelect={true}
                  ></Datetime>
                </CCol>
              </CRow>

              <CRow className="mb-2 mt-2">
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Ambiente SET:</CLabel>
                </CCol>
                <CCol md={4}>
                  <CSelect
                    type="text"
                    value={setEnvironment}
                    onChange={onSetEnvironmentChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(SET_ENVIRONMENT_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Estado:</CLabel>
                </CCol>
                <CCol md={4}>
                  <CSelect
                    type="text"
                    value={setEnvironment}
                    onChange={onStatusChange}
                  >
                    <option value={""}>-----</option>
                    {Array.from(INVOICE_STATUS_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                </CCol>
              </CRow>

              <CRow className="mb-2 mt-2">
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Establecimiento:</CLabel>
                </CCol>
                <CCol md={4}>
                  <EstablishmentMultiSelect
                    onChange={onEstablishmentsChange}
                    value={establishments}
                  />
                </CCol>
                <CCol md={2}>
                  <CLabel className={"mt-2"}>Punto de Expedición:</CLabel>
                </CCol>
                <CCol md={4}>
                  <DispatchPointMultiSelect
                    onChange={onDispatchPointsChange}
                    value={dispatchPoints}
                  />
                </CCol>
              </CRow>

              <CRow className="mb-4">
                <CCol>
                  <CLabel
                    className="visually-hidden"
                    htmlFor="autoSizingInputGroup"
                  ></CLabel>
                  <CInputGroup>
                    <CInputGroupText>
                      <i className="fa fa-search"></i>
                    </CInputGroupText>
                    <CInput
                      type="text"
                      placeholder="Introduzca el termino de búsqueda"
                      onChange={onSearchChange}
                      value={emptyValueOnUndefined(search)}
                      onKeyDown={handleKeyDown}
                    />
                    <CButton
                      type="button"
                      color="primary"
                      variant="outline"
                      onClick={onSearch}
                    >
                      Filtrar
                    </CButton>
                  </CInputGroup>
                </CCol>
              </CRow>

              <CRow className="mb-2">
                <CCol>
                  <CButtonGroup>
                    <CButton
                      color="danger"
                      className="fa fa-file-pdf"
                      onClick={onDownloadKudesClick}
                    ></CButton>

                    <CButton
                      color="success"
                      className="fa fa-file-excel"
                      onClick={onDownloadCsvClick}
                    ></CButton>
                  </CButtonGroup>
                </CCol>
              </CRow>

              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={invoices}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    invoiceDate: (item: Invoice) => {
                      return (
                        <td className="text-center">
                          {formatUTCDateTime(new Date(item.invoiceDate!))}
                        </td>
                      );
                    },
                    invoiceNumber: (item: Invoice) => {
                      if (item.invoiceNumber === undefined) {
                        return <td className="text-center">-</td>;
                      }
                      return (
                        <td className="text-center">
                          {formatInvoiceNumber(item)}
                        </td>
                      );
                    },
                    currency: (item: Invoice) => {
                      return (
                        <td className="text-center">
                          {CURRENCY_CHOICES.get(item.currency!)}
                        </td>
                      );
                    },
                    amount: (item: Invoice) => {
                      return (
                        <td className="text-center">
                          {formatToCurrency(item.amount!, item.currency!)}
                        </td>
                      );
                    },
                    totalDiscount: (item: Invoice) => {
                      return (
                        <td className="text-center">
                          {item.totalDiscount !== undefined &&
                          item.totalDiscount > 0
                            ? formatToCurrency(
                                item.totalDiscount!,
                                item.currency!
                              )
                            : "-"}
                        </td>
                      );
                    },
                    amountAfterDiscount: (item: Invoice) => {
                      return (
                        <td className="text-center">
                          {item.amountAfterDiscount !== undefined &&
                          item.amountAfterDiscount > 0
                            ? formatToCurrency(
                                item.amountAfterDiscount!,
                                item.currency!
                              )
                            : formatToCurrency(item.amount!, item.currency!)}
                        </td>
                      );
                    },
                    status: (item: Invoice) => (
                      <InvoiceCrudStatus invoice={item} />
                    ),
                    files: (item: Invoice) => {
                      let xmlButton = null;
                      let pdfButton = null;

                      if (item.xmlPath !== undefined) {
                        xmlButton = (
                          <PermissionRequiredComponent
                            permissionName={INVOICES_RETRIEVE}
                          >
                            <CTooltip content="XML">
                              <CButton
                                className="text-white"
                                color="info"
                                onClick={() => {
                                  onXMLClick(item);
                                }}
                              >
                                <i className="fa fa-code"></i>
                              </CButton>
                            </CTooltip>
                          </PermissionRequiredComponent>
                        );
                      }

                      if (item.pdfPath !== undefined) {
                        pdfButton = (
                          <PermissionRequiredComponent
                            permissionName={INVOICES_RETRIEVE}
                          >
                            <CTooltip content="PDF">
                              <CButton
                                className="text-white"
                                color="danger"
                                onClick={() => {
                                  onPDFClick(item);
                                }}
                              >
                                <i className="fa fa-file"></i>
                              </CButton>
                            </CTooltip>
                          </PermissionRequiredComponent>
                        );
                      }

                      return (
                        <td className="text-center">
                          <CButtonGroup>
                            {xmlButton}
                            {pdfButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                    actions: (item: Invoice) => (
                      <InvoiceCrudActions
                        invoice={item}
                        onCancellationClick={onCancellationClick}
                        onCancellationRetryClick={onCancellationRetryClick}
                        onCancellationRevertClick={onCancellationRevertClick}
                        onUpdateClick={onUpdateClick}
                        onDeleteClick={onDeleteClick}
                        onConsultByCdcClick={onConsultByCdcClick}
                        onResendClick={onResendClick}
                        onSendEmailClick={onSendEmailClick}
                      />
                    ),
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <InvoiceDeleteModal
        show={showInvoiceDeleteModal}
        onCancel={onInvoiceDeleteCancel}
        onSuccess={onInvoiceDeleteSuccess}
        invoice={deletingInvoice}
      />
      <InvoiceCancellationModal
        show={showInvoiceCancellationModal}
        onCancel={onInvoiceCancellationCancel}
        onSuccess={onInvoiceCancellationSuccess}
        invoice={cancellingInvoice}
      />
      <InvoiceCancellationRetryModal
        show={showInvoiceCancellationRetryModal}
        onCancel={onInvoiceCancellationRetryCancel}
        onSuccess={onInvoiceCancellationRetrySuccess}
        invoice={retryCancelInvoice}
      />
      <InvoiceCancellationRevertModal
        show={showInvoiceCancellationRevertModal}
        onCancel={onInvoiceCancellationRevertCancel}
        onSuccess={onInvoiceCancellationRevertSuccess}
        invoice={revertCancelInvoice}
      />
      <InvoiceConsultByCdcModal
        show={showInvoiceConsultByCdcModal}
        onCancel={onConsultByCdcCancel}
        onSuccess={onConsultByCdcSuccess}
        invoice={consultingInvoice}
      />
      <InvoiceResendModal
        show={showInvoiceResendModal}
        onCancel={onResendCancel}
        onSuccess={onResendSuccess}
        invoice={resendingInvoice}
      />
      <InvoiceSendEmailModal
        show={showSendEmailModal}
        onCancel={onSendEmailCancel}
        onSuccess={onSendEmailSuccess}
        invoice={sendingEmail}
      />
    </>
  );
};

export default InvoiceCrud;
