export const INVOICES_RETRIEVE = "invoices.retrieve";
export const INVOICES_CREATE = "invoices.create";
export const INVOICES_UPDATE = "invoices.update";
export const INVOICES_DESTROY = "invoices.destroy";

export const CREDIT_NOTES_RETRIEVE = "credit_notes.retrieve";
export const CREDIT_NOTES_CREATE = "credit_notes.create";
export const CREDIT_NOTES_UPDATE = "credit_notes.update";
export const CREDIT_NOTES_DESTROY = "credit_notes.destroy";

export const DEBIT_NOTES_RETRIEVE = "debit_notes.retrieve";
export const DEBIT_NOTES_CREATE = "debit_notes.create";
export const DEBIT_NOTES_UPDATE = "debit_notes.update";
export const DEBIT_NOTES_DESTROY = "debit_notes.destroy";

export const REMISSION_NOTES_RETRIEVE = "remission_notes.retrieve";
export const REMISSION_NOTES_CREATE = "remission_notes.create";
export const REMISSION_NOTES_UPDATE = "remission_notes.update";
export const REMISSION_NOTES_DESTROY = "remission_notes.destroy";

export const AUTO_INVOICES_RETRIEVE = "auto_invoices.retrieve";
export const AUTO_INVOICES_CREATE = "auto_invoices.create";
export const AUTO_INVOICES_UPDATE = "auto_invoices.update";
export const AUTO_INVOICES_DESTROY = "auto_invoices.destroy";

export const RECEIPTS_RETRIEVE = "receipts.retrieve";
export const RECEIPTS_CREATE = "receipts.create";
export const RECEIPTS_UPDATE = "receipts.update";
export const RECEIPTS_DESTROY = "receipts.destroy";

export const DISABLEMENTS_RETRIEVE = "disablements.retrieve";
export const DISABLEMENTS_CREATE = "disablements.create";
export const DISABLEMENTS_UPDATE = "disablements.update";
export const DISABLEMENTS_DESTROY = "disablements.destroy";

export const TAX_PAYER_SETTINGS_UPDATE = "tax_payer_settings.update";

export const ALL_PERMISSIONS: string[] = [
  // Invoices
  INVOICES_RETRIEVE,
  INVOICES_CREATE,
  INVOICES_UPDATE,
  INVOICES_DESTROY,

  // Credit Notes
  CREDIT_NOTES_RETRIEVE,
  CREDIT_NOTES_CREATE,
  CREDIT_NOTES_UPDATE,
  CREDIT_NOTES_DESTROY,

  // Debit Notes
  DEBIT_NOTES_RETRIEVE,
  DEBIT_NOTES_CREATE,
  DEBIT_NOTES_UPDATE,
  DEBIT_NOTES_DESTROY,

  // Remission Notes
  REMISSION_NOTES_RETRIEVE,
  REMISSION_NOTES_CREATE,
  REMISSION_NOTES_UPDATE,
  REMISSION_NOTES_DESTROY,

  // Auto Invoices
  AUTO_INVOICES_RETRIEVE,
  AUTO_INVOICES_CREATE,
  AUTO_INVOICES_UPDATE,
  AUTO_INVOICES_DESTROY,

  // Receipts
  RECEIPTS_RETRIEVE,
  RECEIPTS_CREATE,
  RECEIPTS_UPDATE,
  RECEIPTS_DESTROY,

  // Disablements
  DISABLEMENTS_RETRIEVE,
  DISABLEMENTS_CREATE,
  DISABLEMENTS_UPDATE,
  DISABLEMENTS_DESTROY,

  // Tax Payer settings
  TAX_PAYER_SETTINGS_UPDATE,
];
