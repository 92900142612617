export const DOCUMENT_TYPE_ELECTRONIC_INVOICE = "electronic_invoice";
export const DOCUMENT_TYPE_ELECTRONIC_INVOICE_LABEL = "Factura Electrónica";
export const DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE = "electronic_credit_note";
export const DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE_LABEL = "Nota de Crédito";
export const DOCUMENT_TYPE_ELECTRONIC_DEBIT_NOTE = "electronic_debit_note";
export const DOCUMENT_TYPE_ELECTRONIC_DEBIT_NOTE_LABEL = "Nota de Débito";
export const DOCUMENT_TYPE_ELECTRONIC_REMISSION_NOTE = "electronic_debit_note";
export const DOCUMENT_TYPE_ELECTRONIC_REMISSION_NOTE_LABEL = "Nota de Débito";
export const DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE = "electronic_auto_invoice";
export const DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE_LABEL =
  "Autofactura Electrónica";

export const DOCUMENT_TYPE_CHOICES: Map<string, string> = new Map([
  [DOCUMENT_TYPE_ELECTRONIC_INVOICE, DOCUMENT_TYPE_ELECTRONIC_INVOICE_LABEL],
  [
    DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE,
    DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE_LABEL,
  ],
  [
    DOCUMENT_TYPE_ELECTRONIC_DEBIT_NOTE,
    DOCUMENT_TYPE_ELECTRONIC_DEBIT_NOTE_LABEL,
  ],
  [
    DOCUMENT_TYPE_ELECTRONIC_REMISSION_NOTE,
    DOCUMENT_TYPE_ELECTRONIC_REMISSION_NOTE_LABEL,
  ],
  [
    DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE,
    DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE_LABEL,
  ],
]);
