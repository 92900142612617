import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import Establishment from "../../models/establishment";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type EstablishmentMultiSelectProps = {
  value: Establishment[];
  onChange: (_: Establishment[]) => void;
};

const EstablishmentMultiSelect: React.FC<EstablishmentMultiSelectProps> = ({
  value,
  onChange,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadEstablishmentOptions = async (
    s: string,
    currentOpts: readonly (
      | Establishment
      | { options: Establishment[]; label?: string }
    )[]
  ): Promise<
    Response<Establishment, { options: Establishment[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const establishmentsPaginated = await getList<Establishment>(
      "/establishments/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Establishment[] = [];
    let count = 0;

    if (establishmentsPaginated.data !== undefined) {
      newOpts = establishmentsPaginated.data.items;
      count = establishmentsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };

  const onEstablishmentsChange = (establishments: readonly Establishment[]) => {
    const newEstablishments: Establishment[] = [];

    for (const establishment of establishments) {
      newEstablishments.push(establishment);
    }

    onChange(newEstablishments);
  };

  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Establecimiento"}
      onChange={onEstablishmentsChange}
      isMulti={true}
      debounceTimeout={300}
      loadOptions={loadEstablishmentOptions}
      getOptionLabel={(option: Establishment) => `${option.code}`}
      getOptionValue={(option: Establishment) => `${option.id}`}
      value={value}
    />
  );
};

export default EstablishmentMultiSelect;
