import { useEffect, useRef, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";

import Loading from "../indicators/Loading";
import TaxPayer, { TAX_PAYER_TYPE_CHOICES } from "../../models/tax-payer";
import { Spinner } from "react-bootstrap";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert, successAlert } from "../utils/messages";
import { createItem, getItem, getList } from "../../api/generics";
import RegimeTypeSelect from "../regime-type/RegimeTypeSelect";
import RegimeType from "../../models/regime-type";

const TaxPayerForm = () => {
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [defaultRegimeType, setDefaultRegimeType] = useState<RegimeType | null>(
    null
  );

  const taxPayerRef = useRef<TaxPayer | null>(null);

  const onRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.ruc = e.target.value;
  };

  const onSocialNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.socialName = e.target.value;
  };

  const onFantasyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.fantasyName = e.target.value;
  };

  const onRegimeTypeChange = (newRegimeType: RegimeType | null) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.regimeTypeId = newRegimeType?.id;
  };

  const onTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (taxPayerRef.current === null) {
      return;
    }

    taxPayerRef.current.type = e.target.value;
  };

  const loadTaxPayer = async () => {
    const taxPayerRequestStatus = await getItem<TaxPayer>(
      "/tax_payers/default_for_user/"
    );

    if (taxPayerRequestStatus.data !== undefined) {
      const regimeType = await loadInitialRegimeType(
        taxPayerRequestStatus.data.regimeTypeId
      );
      setDefaultRegimeType(regimeType);
      taxPayerRef.current = taxPayerRequestStatus.data;
    }

    setLoading(false);
  };

  const loadInitialRegimeType = async (
    id?: number
  ): Promise<RegimeType | null> => {
    if (id === undefined) {
      return null;
    }
    const additionalParams = new Map();
    additionalParams.set("id", id.toString());
    const initialRegimeTypeStatus = await getList<RegimeType>(
      "/regime_types/",
      1,
      0,
      additionalParams
    );

    if (
      initialRegimeTypeStatus.status === SUCCESS &&
      initialRegimeTypeStatus.data !== undefined &&
      initialRegimeTypeStatus.data.items.length > 0
    ) {
      return initialRegimeTypeStatus.data.items[0];
    }

    return null;
  };

  useEffect(() => {
    loadTaxPayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Loading />;
  }

  const onSaveClick = async () => {
    if (taxPayerRef.current === null) {
      return;
    }

    setSubmitting(true);
    setDisabled(true);

    const taxPayer = taxPayerRef.current;

    const taxPayerRequestStatus = await createItem<TaxPayer>(
      "/tax_payers/default_for_user/create_or_update/",
      taxPayer
    );

    if (taxPayerRequestStatus.status !== SUCCESS) {
      let message = "Ha ocurrido un error!!";
      if (taxPayerRequestStatus.detail !== undefined) {
        message = taxPayerRequestStatus.detail;
      }
      errorAlert(message);
    } else {
      successAlert("Datos guardados con éxito!");
    }

    setErrors(taxPayerRequestStatus.errors!);

    setSubmitting(false);
    setDisabled(false);
  };

  return (
    <>
      <CCard>
        <CCardHeader>Contribuyente</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> RUC
                </CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  placeholder="Ej: 123456-7"
                  disabled={disabled}
                  defaultValue={
                    taxPayerRef.current ? taxPayerRef.current.ruc : ""
                  }
                  onChange={onRucChange}
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("ruc", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nombre Social
                </CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  placeholder="Ej: Mi Compañía SRL"
                  disabled={disabled}
                  onChange={onSocialNameChange}
                  defaultValue={
                    taxPayerRef.current ? taxPayerRef.current.socialName : ""
                  }
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("socialName", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Nombre de Fantasía
                </CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  placeholder="Ej: Mi Compañía"
                  disabled={disabled}
                  onChange={onFantasyNameChange}
                  defaultValue={
                    taxPayerRef.current ? taxPayerRef.current.fantasyName : ""
                  }
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("fantasyName", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Tipo de Contribuyente:
                </CLabel>
              </CCol>
              <CCol md={2}>
                <CSelect
                  type="text"
                  defaultValue={
                    taxPayerRef.current ? taxPayerRef.current.type : ""
                  }
                  onChange={onTypeChange}
                >
                  <option value={""} disabled>
                    -----
                  </option>
                  {Array.from(TAX_PAYER_TYPE_CHOICES.entries()).map((entry) => {
                    return (
                      <option key={entry[0]} value={entry[0]}>
                        {entry[1]}
                      </option>
                    );
                  })}
                </CSelect>
              </CCol>
            </CRow>
          </CFormGroup>
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>
                  <span className="text-danger">*</span> Régimen:
                </CLabel>
              </CCol>
              <CCol>
                <CInputGroup>
                  <RegimeTypeSelect
                    defaultValue={defaultRegimeType}
                    onChange={onRegimeTypeChange}
                  ></RegimeTypeSelect>
                </CInputGroup>
                <FieldErrors
                  errors={getFieldErrors("clientName", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        </CCardBody>
        <CCardFooter>
          <CButton
            className="btn btn-primary"
            disabled={disabled}
            onClick={onSaveClick}
          >
            {submitting ? (
              <Spinner
                animation="grow"
                style={{
                  height: "17px",
                  width: "17px",
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
              />
            ) : (
              <></>
            )}
            {submitting ? "Guardando..." : "Guardar"}
          </CButton>
        </CCardFooter>
      </CCard>
    </>
  );
};

export default TaxPayerForm;
