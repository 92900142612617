interface Establishment {
  id?: number;
  taxPayerId?: number;
  code?: string;
  address?: string;
  houseNumber?: string;
  complementaryAddress1?: string;
  complementaryAddress2?: string;
  department?: number;
  departmentDescription?: string;
  district?: number;
  districtDescription?: string;
  city?: number;
  cityDescription?: string;
  phoneNumber?: string;
  email?: string;
  denomination?: string;
  logoPath?: string;
}

export const newEstablishment = (): Establishment => {
  return {};
};

export default Establishment;
