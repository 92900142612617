import { UserState, UserActionTypes } from "../types/user";

export const SET_LOGIN_INITIALIZED = "set_login_initialized";
export const SET_USER_DATA = "set_user_data";
export const SET_LOGGING_IN = "set_loging_in";
export const SET_LOGING_FAILED = "set_loging_failed";
export const LOG_IN = "log_in";
export const LOG_OUT = "log_out";
export const REFRESH_TOKEN = "refresh_token";

const initialState: UserState = {
  loggingIn: false,
  loggedIn: false,
  loginFailed: false,
  username: "",
  email: "",
  id: 0,
};

export default function UserReducer(
  state: UserState = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case SET_LOGIN_INITIALIZED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loggedIn: action.payload.loggedIn,
        loginInitialized: true,
      };
    case SET_USER_DATA:
      return {
        ...state,
        email: action.payload.email,
        id: action.payload.id,
      };
    case SET_LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loginFailed: false,
      };
    case LOG_IN:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loginFailed: false,
        email: action.payload.email,
        id: action.payload.id,
        accessToken: action.payload.accessToken,
      };
    case LOG_OUT:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginFailed: false,
        email: "",
        accessToken: "",
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    default:
      return state;
  }
}
