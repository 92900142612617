import { PYG } from "../../currency/available-currencies";
import PYGField from "./PYGField";
import USDField from "./USDField";

interface CurrencyFieldProps {
  currency: string;
  limit?: number;
  placeholder?: string;
  value?: number;
  disabled?: boolean;
  onChange?: (_: number | undefined) => void;
}

const CurrencyField: React.FC<CurrencyFieldProps> = ({
  currency,
  limit,
  placeholder,
  value,
  disabled,
  onChange,
}) => {
  if (currency === PYG) {
    return (
      <PYGField
        limit={limit}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      ></PYGField>
    );
  }
  return (
    <USDField
      limit={limit}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={onChange}
    ></USDField>
  );
};

export default CurrencyField;
