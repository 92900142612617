import "./App.css";
import { useSelector } from "react-redux";

import AppRouter from "./AppRouter";
import "./scss/style.scss";

import Loading from "./pages/Loading";
import { RootState } from "./store";
import { toast, ToastContainer } from "react-toastify";

const TOAST_CLOSE_DELAY_MS = 5000;

function LoadedApp() {
  return (
    <>
      <AppRouter />
      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={TOAST_CLOSE_DELAY_MS}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
}

function App() {
  const dashboardInitialized = useSelector(
    (state: RootState) => state.dashboard.initialized
  );
  const loginInitialized = useSelector(
    (state: RootState) => state.user.loginInitialized
  );

  if (!dashboardInitialized) {
    return <Loading />;
  }

  if (!loginInitialized) {
    return <Loading />;
  }

  return <LoadedApp />;
}

export default App;
