import React, { useEffect } from "react";

import { useDispatch } from "react-redux";
import { initializeDashbboard } from "../redux/actions/dashboard";

interface DashboardProps {
  children: React.ReactNode;
}

const DashboardProvider: React.FunctionComponent<DashboardProps> = (
  props: DashboardProps
) => {
  const dispatch = useDispatch();

  const initDashboard = async () => {
    let sidebarShow: boolean | "responsive" = true;
    let sidebarMinimize = false;

    const sidebarShowFromStorage = window.localStorage.getItem("sidebarShow");

    if (sidebarShowFromStorage !== null) {
      if (sidebarShowFromStorage === "true") {
        sidebarShow = true;
      } else if (sidebarShowFromStorage === "false") {
        sidebarShow = false;
      } else if (sidebarShowFromStorage === "responsive") {
        sidebarShow = "responsive";
      }
    } else {
      sidebarShow = false;
    }

    const sidebarMinimizeFromStorage =
      window.localStorage.getItem("sidebarMinimize");

    if (sidebarMinimizeFromStorage !== null) {
      if (sidebarMinimizeFromStorage === "true") {
        sidebarMinimize = true;
      } else if (sidebarMinimizeFromStorage === "false") {
        sidebarMinimize = false;
      }
    }

    dispatch(initializeDashbboard(sidebarShow, sidebarMinimize));
  };

  // Initialize login
  useEffect(() => {
    initDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{props.children}</>;
};

export default DashboardProvider;
