import DashboardPage from "./dashboard-pages/DashboardPage";
import EconomicActivitesPage from "./dashboard-pages/EconomicActivitiesPage";
import RegimeTypesPage from "./dashboard-pages/RegimeTypesPage";
import TaxPayerPage from "./dashboard-pages/TaxPayerPage";
import StampsPage from "./dashboard-pages/StampPage";
import EstablishmentPage from "./dashboard-pages/EstablishmentPage";
import InvoicesPage from "./dashboard-pages/InvoicesPage";
import InvoicePage from "./dashboard-pages/InvoicePage";
import ClientsPage from "./dashboard-pages/ClientsPage";
import DispatchPointPage from "./dashboard-pages/DispatchPointPage";
import CreditNotesPage from "./dashboard-pages/CreditNotesPage";
import CreditNotePage from "./dashboard-pages/CreditNotePage";
import DisablementPage from "./dashboard-pages/DisablementPage";
import DebitNotesPage from "./dashboard-pages/DebitNotesPage";
import DebitNotePage from "./dashboard-pages/DebitNotePage";
import RemissionNotesPage from "./dashboard-pages/RemissionNotesPage";
import RemissionNotePage from "./dashboard-pages/RemissionNotePage";
import ReceiptsPage from "./dashboard-pages/ReceiptsPage";
import ReceiptPage from "./dashboard-pages/ReceiptPage";
import AutoInvoicesPage from "./dashboard-pages/AutoInvoicesPage";
import AutoInvoicePage from "./dashboard-pages/AutoInvoicePage";
import {
  AUTO_INVOICES_CREATE,
  AUTO_INVOICES_UPDATE,
  CREDIT_NOTES_CREATE,
  CREDIT_NOTES_RETRIEVE,
  CREDIT_NOTES_UPDATE,
  DEBIT_NOTES_CREATE,
  DEBIT_NOTES_RETRIEVE,
  DEBIT_NOTES_UPDATE,
  DISABLEMENTS_RETRIEVE,
  INVOICES_CREATE,
  INVOICES_RETRIEVE,
  INVOICES_UPDATE,
  RECEIPTS_CREATE,
  RECEIPTS_RETRIEVE,
  RECEIPTS_UPDATE,
  REMISSION_NOTES_CREATE,
  REMISSION_NOTES_RETRIEVE,
  REMISSION_NOTES_UPDATE,
  TAX_PAYER_SETTINGS_UPDATE,
} from "./auth/permissions";

interface Route {
  path: string;
  exact?: boolean | undefined;
  name: string;
  component?: any;
  permissionName?: string | string[];
}

interface RouteMap {
  [index: string]: Route;
}

const routes: RouteMap = {
  dashboard: {
    path: "/dashboard/",
    name: "Inicio",
    exact: true,
    component: DashboardPage,
  },
  taxPayer: {
    path: "/tax_payer/",
    name: "Contribuyente",
    exact: true,
    component: TaxPayerPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  regimeTypes: {
    path: "/regime_types/",
    name: "Régimenes",
    exact: true,
    component: RegimeTypesPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  economicActivities: {
    path: "/economic_activities/",
    name: "Actividades Económicas",
    exact: true,
    component: EconomicActivitesPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  stamps: {
    path: "/stamps/",
    name: "Timbrados",
    exact: true,
    component: StampsPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  dispatchPoints: {
    path: "/dispatch_points/",
    name: "Puntos de expedición",
    exact: true,
    component: DispatchPointPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  establishments: {
    path: "/establishments/",
    name: "Establecimientos",
    exact: true,
    component: EstablishmentPage,
    permissionName: TAX_PAYER_SETTINGS_UPDATE,
  },
  clients: {
    path: "/clients/",
    name: "Clientes",
    exact: true,
    component: ClientsPage,
    permissionName: [
      INVOICES_RETRIEVE,
      CREDIT_NOTES_RETRIEVE,
      DEBIT_NOTES_RETRIEVE,
      REMISSION_NOTES_RETRIEVE,
      RECEIPTS_RETRIEVE,
    ],
  },
  invoices: {
    path: "/invoices/",
    name: "Facturas",
    exact: true,
    component: InvoicesPage,
    permissionName: INVOICES_RETRIEVE,
  },
  addInvoice: {
    path: "/invoice/",
    name: "Agregar Factura",
    exact: true,
    component: InvoicePage,
    permissionName: [INVOICES_CREATE, INVOICES_UPDATE],
  },
  autoInvoices: {
    path: "/auto_invoices/",
    name: "Autofacturas",
    exact: true,
    component: AutoInvoicesPage,
    permissionName: AUTO_INVOICES_CREATE,
  },
  addAutoInvoice: {
    path: "/auto_invoice/",
    name: "Agregar Autofactura",
    exact: true,
    component: AutoInvoicePage,
    permissionName: [AUTO_INVOICES_CREATE, AUTO_INVOICES_UPDATE],
  },
  creditNotes: {
    path: "/credit_notes/",
    name: "Notas de Crédito",
    exact: true,
    component: CreditNotesPage,
    permissionName: CREDIT_NOTES_RETRIEVE,
  },
  addCreditNote: {
    path: "/credit_note/",
    name: "Agregar Nota de Crédito",
    exact: true,
    component: CreditNotePage,
    permissionName: [CREDIT_NOTES_CREATE, CREDIT_NOTES_UPDATE],
  },
  debitNotes: {
    path: "/debit_notes/",
    name: "Notas de Débito",
    exact: true,
    component: DebitNotesPage,
    permissionName: DEBIT_NOTES_RETRIEVE,
  },
  addDebitNote: {
    path: "/debit_note/",
    name: "Agregar Nota de Débito",
    exact: true,
    component: DebitNotePage,
    permissionName: [DEBIT_NOTES_CREATE, DEBIT_NOTES_UPDATE],
  },
  remissionNotes: {
    path: "/remission_notes/",
    name: "Notas de Remisión",
    exact: true,
    component: RemissionNotesPage,
    permissionName: REMISSION_NOTES_RETRIEVE,
  },
  addRemissionNote: {
    path: "/remission_note/",
    name: "Agregar Nota de Remisión",
    exact: true,
    component: RemissionNotePage,
    permissionName: [REMISSION_NOTES_CREATE, REMISSION_NOTES_UPDATE],
  },
  receipts: {
    path: "/receipts/",
    name: "Recibos",
    exact: true,
    component: ReceiptsPage,
    permissionName: RECEIPTS_RETRIEVE,
  },
  addReceipt: {
    path: "/receipt/",
    name: "Agregar Recibo",
    exact: true,
    component: ReceiptPage,
    permissionName: [RECEIPTS_CREATE, RECEIPTS_UPDATE],
  },
  disablements: {
    path: "/disablements/",
    name: "Inutilizaciones",
    exact: true,
    component: DisablementPage,
    permissionName: DISABLEMENTS_RETRIEVE,
  },
};

export const getRoutePath = (key: string) => {
  return routes[key].path;
};
export const getRouteName = (key: string) => {
  return routes[key].name;
};

export default routes;
