import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import EconomicActivity from "../../models/economic-activity";
import { getList } from "../../api/generics";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type EconomicActivitySelectProps = {
  value: EconomicActivity | null;
  isClearable?: boolean;
  onChange?: (_: EconomicActivity | null) => void;
};

const EconomicActivitySelect: React.FC<EconomicActivitySelectProps> = ({
  value,
  isClearable,
  onChange,
}) => {
  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );
  const onEconomicActivityChange = async (
    newEconomicActivity: EconomicActivity | null
  ) => {
    if (onChange !== undefined) {
      onChange(newEconomicActivity);
    }
  };

  const loadEconomicActivityOptions = async (
    s: string,
    currentOpts: readonly (
      | EconomicActivity
      | { options: EconomicActivity[]; label?: string }
    )[]
  ): Promise<
    Response<
      EconomicActivity,
      { options: EconomicActivity[]; label?: string },
      any
    >
  > => {
    let additionalParams: Map<string, string> = new Map();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const economicActivitiesPaginated = await getList<EconomicActivity>(
      "/economic_activities/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: EconomicActivity[] = [];
    let count = 0;

    if (economicActivitiesPaginated.data !== undefined) {
      newOpts = economicActivitiesPaginated.data.items;
      count = economicActivitiesPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione una Actividad Económica"}
      onChange={onEconomicActivityChange}
      isMulti={false}
      isClearable={isClearable === true}
      debounceTimeout={300}
      loadOptions={loadEconomicActivityOptions}
      getOptionLabel={(option: EconomicActivity) =>
        `${option.code!} - ${option.description!}`
      }
      getOptionValue={(option: EconomicActivity) => `${option.id!}`}
      value={value}
    />
  );
};

export default EconomicActivitySelect;
