import { FunctionComponent } from "react";

type FieldErrorProps = {
  errors: string[];
};

export const FieldErrors: FunctionComponent<FieldErrorProps> = ({ errors }) => {
  if (errors.length > 0) {
    return (
      <>
        {errors.map((error, idx) => (
          <small style={{ color: "red" }} key={idx}>
            {error}
          </small>
        ))}
      </>
    );
  } else {
    return <></>;
  }
};
