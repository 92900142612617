import { IVA_TYPE_10_PERCENT } from "./iva";

export interface CreditNoteItem {
  id?: number;
  creditNoteId?: number;
  description?: string;
  obs?: string;
  code?: string;
  measureUnit?: number;
  unitAmount?: number;
  quantity?: number;
  totalAmount?: number;
  ivaType?: string;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  taxed10Percent?: number;
  taxed5Percent?: number;
  ivaBase?: number;
  iva10Percent?: number;
  iva5Percent?: number;
}

export interface CreditNote {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  uniqueCode?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  clientId?: number;
  clientCode?: string;
  clientIsTaxPayer?: boolean;
  clientRuc?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  clientType?: string;
  clientCountryCode?: string;
  clientHasAddress?: boolean;
  clientAddress?: string;
  clientHouseNumber?: number;
  clientDepartment?: number;
  clientDepartmentDescription?: string;
  clientDistrict?: number;
  clientDistrictDescription?: string;
  clientCity?: number;
  clientCityDescription?: string;
  clientPhoneNumber?: string;
  clientEmail?: string;
  creditNoteDate?: string;
  creditNoteNumber?: string;
  currency?: string;
  pygExchangeRate?: number;
  description?: string;
  obs?: string;
  amount?: number;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  taxed10Percent?: number;
  taxed5Percent?: number;
  iva10Percent?: number;
  iva5Percent?: number;

  associatedDocumentType?: string;
  invoiceId?: number;
  associatedDocumentCdc?: string;
  associatedDocumentPrintedType?: string;
  associatedDocumentStampIdentifier?: string;
  associatedDocumentEstablishmentCode?: string;
  associatedDocumentDispatchPointCode?: string;
  associatedDocumentNumber?: string;
  associatedDocumentDate?: string;

  cdc?: string;

  xmlPath?: string;
  pdfPath?: string;

  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;

  items: CreditNoteItem[];

  emailSent?: boolean;

  createdAt?: string;
  updatedAt?: string;
}

export const CREDIT_NOTE_STATUS_PENDING = "pending";
export const CREDIT_NOTE_STATUS_PENDING_LABEL = "Pendiente";
export const CREDIT_NOTE_STATUS_ERROR = "error";
export const CREDIT_NOTE_STATUS_ERROR_LABEL = "Error";
export const CREDIT_NOTE_STATUS_GENERATED = "generated";
export const CREDIT_NOTE_STATUS_GENERATED_LABEL = "Generado";
export const CREDIT_NOTE_STATUS_UPLOADED_TO_SET = "uploaded_to_set";
export const CREDIT_NOTE_STATUS_UPLOADED_TO_SET_LABEL = "Subido";
export const CREDIT_NOTE_STATUS_APPROVED_BY_SET = "approved_by_set";
export const CREDIT_NOTE_STATUS_APPROVED_BY_SET_LABEL = "Aprobado";
export const CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR = "set_unknown_error";
export const CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL = "Desconocido";
export const CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED =
  "cancellation_requested";
export const CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const CREDIT_NOTE_STATUS_CANCELLED = "cancelled";
export const CREDIT_NOTE_STATUS_CANCELLED_LABEL = "Anulado";
export const CREDIT_NOTE_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const CREDIT_NOTE_STATUS_CANCELLATION_FAILED_LABEL = "Anulación Fallida";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_REQUESTED =
  "disagreement_requested";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL =
  "Disconformidad Solicitada";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED = "disagreement_accepted";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL =
  "Disconformidad Aceptada";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_FAILED = "disagreement_failed";
export const CREDIT_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL =
  "Disconformidad Fallida";
export const CREDIT_NOTE_STATUS_RESEND_REQUESTED = "resend_requested";
export const CREDIT_NOTE_STATUS_RESEND_REQUESTED_LABEL = "Reenviar";

export const CREDIT_NOTE_STATUS_CHOICES: Map<string, string> = new Map([
  [CREDIT_NOTE_STATUS_PENDING, CREDIT_NOTE_STATUS_PENDING_LABEL],
  [CREDIT_NOTE_STATUS_ERROR, CREDIT_NOTE_STATUS_ERROR_LABEL],
  [CREDIT_NOTE_STATUS_GENERATED, CREDIT_NOTE_STATUS_GENERATED_LABEL],
  [
    CREDIT_NOTE_STATUS_UPLOADED_TO_SET,
    CREDIT_NOTE_STATUS_UPLOADED_TO_SET_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_APPROVED_BY_SET,
    CREDIT_NOTE_STATUS_APPROVED_BY_SET_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR,
    CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED,
    CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [CREDIT_NOTE_STATUS_CANCELLED, CREDIT_NOTE_STATUS_CANCELLED_LABEL],
  [
    CREDIT_NOTE_STATUS_CANCELLATION_FAILED,
    CREDIT_NOTE_STATUS_CANCELLATION_FAILED_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_DISAGREEMENT_REQUESTED,
    CREDIT_NOTE_STATUS_DISAGREEMENT_REQUESTED_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED,
    CREDIT_NOTE_STATUS_DISAGREEMENT_ACCEPTED_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_DISAGREEMENT_FAILED,
    CREDIT_NOTE_STATUS_DISAGREEMENT_FAILED_LABEL,
  ],
  [
    CREDIT_NOTE_STATUS_RESEND_REQUESTED,
    CREDIT_NOTE_STATUS_RESEND_REQUESTED_LABEL,
  ],
]);

export const newCreditNote = (taxPayerId?: number): CreditNote => {
  return {
    taxPayerId: taxPayerId,
    items: [],
  };
};

export const newCreditNoteItem = (): CreditNoteItem => {
  return {
    ivaType: IVA_TYPE_10_PERCENT,
    ivaBase: 100,
  };
};

export const formatCreditNoteNumber = (creditNote: CreditNote): string => {
  return `${creditNote.establishmentCode}-${creditNote.dispatchPointIdentifier}-${creditNote.creditNoteNumber}`;
};
