import { IVA_TYPE_10_PERCENT } from "./iva";

export interface InvoiceItem {
  id?: number;
  invoiceId?: number;
  description?: string;
  obs?: string;
  measureUnit?: number;
  code?: string;
  unitAmount?: number;
  quantity?: number;
  totalAmount?: number;
  ivaType?: string;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  taxed10Percent?: number;
  taxed5Percent?: number;
  ivaBase?: number;
  iva10Percent?: number;
  iva5Percent?: number;
  discount?: number;
}

export interface Invoice {
  id?: number;
  taxPayerId?: number;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  status?: string;
  uniqueCode?: string;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  clientId?: number;
  clientCode?: string;
  clientIsTaxPayer?: boolean;
  clientRuc?: string;
  clientSocialName?: string;
  clientFantasyName?: string;
  clientType?: string;
  clientCountryCode?: string;
  clientHasAddress?: boolean;
  clientAddress?: string;
  clientHouseNumber?: number;
  clientDepartment?: number;
  clientDepartmentDescription?: string;
  clientDistrict?: number;
  clientDistrictDescription?: string;
  clientCity?: number;
  clientCityDescription?: string;
  clientPhoneNumber?: string;
  clientEmail?: string;
  invoiceDate?: string;
  saleType?: string;
  transactionTypeCode?: number;
  currency?: string;
  pygExchangeRate?: number;
  description?: string;
  obs?: string;
  amount?: number;
  amount10Percent?: number;
  amount5Percent?: number;
  amountExempt?: number;
  taxed10Percent?: number;
  taxed5Percent?: number;
  iva10Percent?: number;
  iva5Percent?: number;
  totalDiscount?: number;
  amountAfterDiscount?: number;
  term?: string;
  globalDiscount?: number;

  invoiceNumber?: string;
  cdc?: string;

  xmlPath?: string;
  pdfPath?: string;

  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;

  items: InvoiceItem[];

  emailSent?: boolean;

  createdAt?: string;
  updatedAt?: string;
}

export const INVOICE_SALE_TYPE_CASH = "cash";
export const INVOICE_SALE_TYPE_CASH_LABEL = "Contado";
export const INVOICE_SALE_TYPE_CREDIT = "credit";
export const INVOICE_SALE_TYPE_CREDIT_LABEL = "Crédito";

export const INVOICE_STATUS_PENDING = "pending";
export const INVOICE_STATUS_PENDING_LABEL = "Pendiente";
export const INVOICE_STATUS_ERROR = "error";
export const INVOICE_STATUS_ERROR_LABEL = "Error";
export const INVOICE_STATUS_GENERATED = "generated";
export const INVOICE_STATUS_GENERATED_LABEL = "Generado";
export const INVOICE_STATUS_UPLOADED_TO_SET = "uploaded_to_set";
export const INVOICE_STATUS_UPLOADED_TO_SET_LABEL = "Subido";
export const INVOICE_STATUS_APPROVED_BY_SET = "approved_by_set";
export const INVOICE_STATUS_APPROVED_BY_SET_LABEL = "Aprobado";
export const INVOICE_STATUS_SET_UNKNOWN_ERROR = "set_unknown_error";
export const INVOICE_STATUS_SET_UNKNOWN_ERROR_LABEL = "Desconocido";
export const INVOICE_STATUS_CANCELLATION_REQUESTED = "cancellation_requested";
export const INVOICE_STATUS_CANCELLATION_REQUESTED_LABEL =
  "Anulación Pendiente";
export const INVOICE_STATUS_CANCELLED = "cancelled";
export const INVOICE_STATUS_CANCELLED_LABEL = "Anulado";
export const INVOICE_STATUS_CANCELLATION_FAILED = "cancellation_failed";
export const INVOICE_STATUS_CANCELLATION_FAILED_LABEL = "Anulación Fallida";
export const INVOICE_STATUS_DISAGREEMENT_REQUESTED = "disagreement_requested";
export const INVOICE_STATUS_DISAGREEMENT_REQUESTED_LABEL =
  "Disconformidad Solicitada";
export const INVOICE_STATUS_DISAGREEMENT_ACCEPTED = "disagreement_accepted";
export const INVOICE_STATUS_DISAGREEMENT_ACCEPTED_LABEL =
  "Disconformidad Aceptada";
export const INVOICE_STATUS_DISAGREEMENT_FAILED = "disagreement_failed";
export const INVOICE_STATUS_DISAGREEMENT_FAILED_LABEL =
  "Disconformidad Fallida";
export const INVOICE_STATUS_RESEND_REQUESTED = "resend_requested";
export const INVOICE_STATUS_RESEND_REQUESTED_LABEL = "Reenviar";

export const INVOICE_STATUS_CHOICES: Map<string, string> = new Map([
  [INVOICE_STATUS_PENDING, INVOICE_STATUS_PENDING_LABEL],
  [INVOICE_STATUS_ERROR, INVOICE_STATUS_ERROR_LABEL],
  [INVOICE_STATUS_GENERATED, INVOICE_STATUS_GENERATED_LABEL],
  [INVOICE_STATUS_UPLOADED_TO_SET, INVOICE_STATUS_UPLOADED_TO_SET_LABEL],
  [INVOICE_STATUS_APPROVED_BY_SET, INVOICE_STATUS_APPROVED_BY_SET_LABEL],
  [INVOICE_STATUS_SET_UNKNOWN_ERROR, INVOICE_STATUS_SET_UNKNOWN_ERROR_LABEL],
  [
    INVOICE_STATUS_CANCELLATION_REQUESTED,
    INVOICE_STATUS_CANCELLATION_REQUESTED_LABEL,
  ],
  [INVOICE_STATUS_CANCELLED, INVOICE_STATUS_CANCELLED_LABEL],
  [
    INVOICE_STATUS_CANCELLATION_FAILED,
    INVOICE_STATUS_CANCELLATION_FAILED_LABEL,
  ],
  [
    INVOICE_STATUS_DISAGREEMENT_REQUESTED,
    INVOICE_STATUS_DISAGREEMENT_REQUESTED_LABEL,
  ],
  [
    INVOICE_STATUS_DISAGREEMENT_ACCEPTED,
    INVOICE_STATUS_DISAGREEMENT_ACCEPTED_LABEL,
  ],
  [
    INVOICE_STATUS_DISAGREEMENT_FAILED,
    INVOICE_STATUS_DISAGREEMENT_FAILED_LABEL,
  ],
  [INVOICE_STATUS_RESEND_REQUESTED, INVOICE_STATUS_RESEND_REQUESTED_LABEL],
]);

export const newInvoice = (taxPayerId?: number): Invoice => {
  return {
    taxPayerId: taxPayerId,
    items: [],
  };
};

export const newInvoiceItem = (): InvoiceItem => {
  return {
    ivaType: IVA_TYPE_10_PERCENT,
    ivaBase: 100,
  };
};

export const formatInvoiceNumber = (invoice: Invoice): string => {
  return `${invoice.establishmentCode}-${invoice.dispatchPointIdentifier}-${invoice.invoiceNumber}`;
};
