import RemissionNoteForm from "../components/remission-note/RemissionNoteForm";

const RemissionNotePage = () => {
  return (
    <>
      <RemissionNoteForm />
    </>
  );
};

export default RemissionNotePage;
