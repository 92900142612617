import City from "../models/city";
import Department from "../models/department";
import District from "../models/district";
import { SUCCESS } from "../utils/constants/tags";
import { getList } from "./generics";

const getDepartmentByCode = async (
  code?: number
): Promise<Department | null> => {
  if (code === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  params.set("code", code.toString());

  const requestStatus = await getList<Department>(
    "/locations/departments/",
    1,
    0,
    params
  );

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

const getDistrictByCode = async (code?: number): Promise<District | null> => {
  if (code === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  params.set("code", code.toString());

  const requestStatus = await getList<District>(
    "/locations/districts/",
    1,
    0,
    params
  );

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

const getCityByCode = async (code?: number): Promise<City | null> => {
  if (code === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  params.set("code", code.toString());

  const requestStatus = await getList<District>(
    "/locations/cities/",
    1,
    0,
    params
  );

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

export { getDepartmentByCode, getDistrictByCode, getCityByCode };
