import AutoInvoiceForm from "../components/auto-invoice/AutoInvoiceForm";

const AutoInvoicePage = () => {
  return (
    <>
      <AutoInvoiceForm />
    </>
  );
};

export default AutoInvoicePage;
