import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface HasAnyPermissionComponentProps {
  permissionNames: string[];
  children: React.ReactNode;
}

const HasAnyPermissionComponent: React.FC<HasAnyPermissionComponentProps> = ({
  permissionNames,
  children,
}) => {
  const permissions = useSelector(
    (state: RootState) => state.taxPayerPermissions.permissions
  );

  for (const permissionName of permissionNames) {
    if (permissions !== undefined && permissions.indexOf(permissionName) >= 0) {
      return <>{children}</>;
    }
  }
  return <></>;
};

export default HasAnyPermissionComponent;
