import RegimeTypeCrud from "../components/regime-type/RegimeTypeCrud";

const RegimeTypesPage = () => {
  return (
    <>
      <RegimeTypeCrud />
    </>
  );
};

export default RegimeTypesPage;
