import { Invoice } from "../models/invoice";
import { SUCCESS } from "../utils/constants/tags";
import { getList } from "./generics";

const getInvoiceById = async (
  id?: number,
  taxPayerId?: number
): Promise<Invoice | null> => {
  if (id === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  if (id !== undefined) {
    params.set("id", id.toString());
  }

  if (taxPayerId !== undefined) {
    params.set("tax_payer_id", taxPayerId.toString());
  }

  const requestStatus = await getList<Invoice>("/invoices/", 1, 0, params);

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

export { getInvoiceById };
