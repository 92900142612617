import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../store";
import { refreshToken, logOut, initializeLogin } from "../redux/actions/user";

interface AuthProps {
  children: React.ReactNode;
}

const AuthProvider: React.FunctionComponent<AuthProps> = (props: AuthProps) => {
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const dispatch = useDispatch();

  const initLogin = async () => {
    dispatch(initializeLogin());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // Initialize login
  useEffect(() => {
    initLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Refresh token if user is logged in
  useEffect(() => {
    let refreshInterval: number | null = null;
    if (isLoggedIn) {
      refreshInterval = window.setInterval(async () => {
        const success = await dispatch(refreshToken());

        if (!success) {
          dispatch(logOut());
        }
      }, 300000);
    }

    // Will be called at the re render or unmount of the component
    return () => {
      if (refreshInterval != null) {
        clearInterval(refreshInterval);
      }
    };
  });

  return <>{props.children}</>;
};

export default AuthProvider;
