interface EconomicActivity {
  id?: number;
  taxPayerId?: number;
  code?: string;
  description?: string;
  canBeModified?: boolean;
  canBeDeleted?: boolean;
}

export const newEconomicActivity = (): EconomicActivity => {
  return {};
};

export default EconomicActivity;
