import React from "react";
import "./style.css";

import Select from "react-select";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";

const SelectBootstrap: StateManagedSelect = (props) => {
  return (
    <div className="react-select form-control p-0">
      <Select {...props}></Select>
    </div>
  );
};

export default SelectBootstrap;
