import CreditNoteForm from "../components/credit-note/CreditNoteForm";

const CreditNotePage = () => {
  return (
    <>
      <CreditNoteForm />
    </>
  );
};

export default CreditNotePage;
