import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import DispatchPoint from "../../models/dispatch-point";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type DispatchPointSelectProps = {
  value: DispatchPoint | null;
  onChange: (_: DispatchPoint | null) => void;
};

const DispatchPointSelect: React.FC<DispatchPointSelectProps> = ({
  value,
  onChange,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadDispatchPointOptions = async (
    s: string,
    currentOpts: readonly (
      | DispatchPoint
      | { options: DispatchPoint[]; label?: string }
    )[]
  ): Promise<
    Response<DispatchPoint, { options: DispatchPoint[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dispatchPointsPaginated = await getList<DispatchPoint>(
      "/dispatch_points/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: DispatchPoint[] = [];
    let count = 0;

    if (dispatchPointsPaginated.data !== undefined) {
      newOpts = dispatchPointsPaginated.data.items;
      count = dispatchPointsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Punto de expedición"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadDispatchPointOptions}
      getOptionLabel={(option: DispatchPoint) => `${option.identifier}`}
      getOptionValue={(option: DispatchPoint) => `${option.id}`}
      value={value}
    />
  );
};

export default DispatchPointSelect;
