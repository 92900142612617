import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import District from "../../models/district";
import Department from "../../models/department";
import { useCallback } from "react";
const RECORS_PER_SEARCH = 20;

type DistrictSelectProps = {
  departmentValue?: Department;
  placeholder?: React.ReactNode;
  value: District | null;
  onChange: (_: District | null) => void;
};

const DistrictSelect: React.FC<DistrictSelectProps> = ({
  placeholder,
  value,
  onChange,
  departmentValue,
}) => {
  const loadDistrictOptions = useCallback(
    async (
      s: string,
      currentOpts: readonly (
        | District
        | { options: District[]; label?: string }
      )[]
    ): Promise<
      Response<District, { options: District[]; label?: string }, any>
    > => {
      let additionalParams: Map<string, string> | undefined = undefined;
      if (s !== "") {
        additionalParams = new Map();
        additionalParams.set("search", s);
      }

      if (departmentValue !== undefined) {
        if (additionalParams === undefined) {
          additionalParams = new Map();
        }
        additionalParams.set("department_id", departmentValue.code!.toString());
      }

      const districtsPaginated = await getList<District>(
        "/locations/districts/",
        RECORS_PER_SEARCH,
        currentOpts.length,
        additionalParams
      );

      let newOpts: District[] = [];
      let count = 0;

      if (districtsPaginated.data !== undefined) {
        newOpts = districtsPaginated.data.items;
        count = districtsPaginated.data.count;
      }

      const hasMore = currentOpts.length + newOpts.length < count;

      return {
        options: newOpts,
        hasMore: hasMore,
      };
    },
    [departmentValue]
  );
  return (
    <AsyncPaginateBootstrap
      key={departmentValue ? departmentValue.code : undefined}
      placeholder={placeholder ? placeholder : "Seleccione un Distrito"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadDistrictOptions}
      getOptionLabel={(option: District) => `${option.description}`}
      getOptionValue={(option: District) => `${option.code}`}
      value={value}
      loadOptionsOnMenuOpen={true}
    />
  );
};

export default DistrictSelect;
