import React from "react";

import { Route, useLocation, Redirect } from "react-router-dom";

import { useSelector } from "react-redux";

import LocationState from "./LocationState";

import { RootState } from "../store";

const LoginRequiredRoute = ({ ...props }) => {
  const location = useLocation<LocationState>();
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{ pathname: "/login", state: { from: location.pathname } }}
      />
    );
  }
  return <Route {...props}></Route>;
};

export default LoginRequiredRoute;
