import DebitNoteForm from "../components/debit-note/DebitNoteForm";

const DebitNotePage = () => {
  return (
    <>
      <DebitNoteForm />
    </>
  );
};

export default DebitNotePage;
