import { useEffect, useState } from "react";
import "./style.css";

interface AnimatedCheckboxProps {
  value?: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
  onChange?: (_: boolean) => void;
}

const AnimatedCheckbox: React.FC<AnimatedCheckboxProps> = ({
  value,
  defaultValue,
  disabled,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(
    value !== undefined ? value : defaultValue
  );
  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentIsChecked = isChecked;
    setIsChecked(!currentIsChecked);

    if (onChange !== undefined) {
      onChange(!currentIsChecked);
    }
  };
  useEffect(() => {
    setIsChecked(value);
  }, [value]);
  return (
    <div className="center-checkbox">
      <label className="label-checkbox">
        <input
          type="checkbox"
          className="label__checkbox"
          checked={isChecked}
          onChange={onCheckboxChange}
          disabled={disabled}
        />
        <span className="label__text">
          <span className="label__check">
            <i className="fa fa-check icon"></i>
          </span>
        </span>
      </label>
    </div>
  );
};

export default AnimatedCheckbox;
