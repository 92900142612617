import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { Invoice } from "../../models/invoice";
import { formatUTCDateTime } from "../../utils/dates";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type InvoiceSelectProps = {
  value: Invoice | null;
  status?: string;
  onChange: (_: Invoice | null) => void;
};

const InvoiceSelect: React.FC<InvoiceSelectProps> = ({
  value,
  status,
  onChange,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadInvoiceOptions = async (
    s: string,
    currentOpts: readonly (Invoice | { options: Invoice[]; label?: string })[]
  ): Promise<
    Response<Invoice, { options: Invoice[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    // TODO: Implement search for invoices
    if (s !== "") {
      additionalParams!.set("search", s);
    }

    if (status !== undefined) {
      additionalParams!.set("status", status);
    }

    const invoicesPaginated = await getList<Invoice>(
      "/invoices/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Invoice[] = [];
    let count = 0;

    if (invoicesPaginated.data !== undefined) {
      newOpts = invoicesPaginated.data.items;
      count = invoicesPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione una Factura"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadInvoiceOptions}
      getOptionLabel={(option: Invoice) => {
        return `${formatUTCDateTime(new Date(option.invoiceDate!))} - ${
          option.clientSocialName
        } - ${option.establishmentCode}-${option.dispatchPointIdentifier}-${
          option.invoiceNumber
        }`;
      }}
      getOptionValue={(option: Invoice) => `${option.id}`}
      value={value}
    />
  );
};

export default InvoiceSelect;
