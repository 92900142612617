import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Client from "../../models/client";
import ClientForm from "./ClientForm";

interface ClientUpdateModalProps {
  show: boolean;
  client?: Client;
  onCancel: () => void | Promise<void>;
  onSuccess: (_?: Client) => void | Promise<void>;
}

const ClientUpdateModal: React.FC<ClientUpdateModalProps> = ({
  show,
  client,
  onCancel,
  onSuccess,
}) => {
  const modalTitle = client
    ? `Editar cliente: ${client.socialName}`
    : `Crear Cliente`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <ClientForm
              client={client}
              onCancel={onCancel}
              onSuccess={onSuccess}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default ClientUpdateModal;
