import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import EconomicActivity from "../../models/economic-activity";
import EconomicActivityForm from "./EconomicActivityForm";

interface EconomicActivityUpdateModalProps {
  show: boolean;
  economicActivity?: EconomicActivity;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const EconomicActivityUpdateModal: React.FC<
  EconomicActivityUpdateModalProps
> = ({ show, economicActivity, onCancel, onSuccess }) => {
  const modalTitle = economicActivity
    ? `Editar actividad económica: ${economicActivity.description}`
    : `Crear actividad económica`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <EconomicActivityForm
              economicActivity={economicActivity}
              onCancel={onCancel}
              onSuccess={onSuccess}
              clearOnSuccess={false}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default EconomicActivityUpdateModal;
