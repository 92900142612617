import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import Stamp from "../../models/stamp";
import StampForm from "./StampForm";

interface StampUpdateModalProps {
  show: boolean;
  stamp?: Stamp;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const StampUpdateModal: React.FC<StampUpdateModalProps> = ({
  show,
  stamp,
  onCancel,
  onSuccess,
}) => {
  const modalTitle = stamp
    ? `Editar timbrado Nª: ${stamp.identifier}`
    : `Agregar Nª de timbrado`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <StampForm
              stamp={stamp}
              onCancel={onCancel}
              onSuccess={onSuccess}
              clearOnSuccess={false}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default StampUpdateModal;
