import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import DispatchPoint from "../../models/dispatch-point";

interface DispatchPointDeleteModalProps {
  show: boolean;
  dispatchPoint?: DispatchPoint;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DispatchPointDeleteModal: React.FC<DispatchPointDeleteModalProps> = ({
  show,
  dispatchPoint,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = dispatchPoint
    ? `Está seguro de que quiere eliminar el Punto de expedición Nº ${dispatchPoint.identifier}`
    : "";

  const onDeleteClick = async () => {
    if (dispatchPoint !== undefined) {
      setSubmitting(true);
      const dispatchPointStatus = await deleteItem(
        `/dispatch_points/${dispatchPoint.id}/`,
        dispatchPoint
      );

      if (dispatchPointStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (dispatchPointStatus.detail !== undefined) {
          message = dispatchPointStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DispatchPointDeleteModal;
