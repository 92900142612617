import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import { Invoice } from "../../models/invoice";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";

interface InvoiceCancellationModalProps {
  show: boolean;
  invoice?: Invoice;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const InvoiceCancellationModal: React.FC<InvoiceCancellationModalProps> = ({
  show,
  invoice,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState<Errors>({});

  const message = invoice
    ? `Está seguro de que quiere anular la factura nº ${invoice.establishmentCode}-${invoice.dispatchPointIdentifier}-${invoice.invoiceNumber}?`
    : "";

  const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const onCancellationClick = async () => {
    if (invoice !== undefined) {
      setSubmitting(true);
      const status = await createItem(`/cancellations/create/`, {
        taxPayerId: invoice.taxPayerId,
        taxPayerRuc: invoice.taxPayerRuc,
        taxPayerSocialName: invoice.taxPayerSocialName,
        taxPayerFantasyName: invoice.taxPayerFantasyName,
        stampId: invoice.stampId,
        stampIdentifier: invoice.stampIdentifier,
        stampBeginDate: invoice.stampBeginDate,
        invoiceId: invoice.id,
        cdc: invoice.cdc,
        reason: reason,
      });

      if (status.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (status.detail !== undefined) {
          message = status.detail;
        }
        if (status.errors !== undefined) {
          setErrors(status.errors);
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Anular</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CTextarea
                placeholder="Motivo"
                value={reason}
                onChange={onReasonChange}
              ></CTextarea>
              <FieldErrors
                errors={getFieldErrors("reason", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting}
          onClick={onCancellationClick}
          color="danger"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Anulando..." : "Anular"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default InvoiceCancellationModal;
