import Client from "../models/client";
import { SUCCESS } from "../utils/constants/tags";
import { getList } from "./generics";

const getClientById = async (
  id?: number,
  taxPayerId?: number
): Promise<Client | null> => {
  if (id === undefined) {
    return null;
  }

  let params: Map<string, string> = new Map();
  console.log(id);
  if (id !== undefined) {
    params.set("id", id.toString());
  }

  if (taxPayerId !== undefined) {
    params.set("tax_payer_id", taxPayerId.toString());
  }

  const requestStatus = await getList<Client>("/clients/", 1, 0, params);

  if (
    requestStatus.status === SUCCESS &&
    requestStatus.data !== undefined &&
    requestStatus.data.items.length > 0
  ) {
    return requestStatus.data!.items[0];
  }

  return null;
};

export { getClientById };
