export const TRANSPORT_TYPE_CHOICES: Map<number, string> = new Map([
  [1, "Propio"],
]);

export const TRANSPORT_RESPONSIBLE_CHOICES: Map<number, string> = new Map([
  [5, "Transporte propio"],
]);

export const TRANSPORT_MODE_CHOICES: Map<number, string> = new Map([
  [1, "Terrestre"],
]);

export const TRANSPORT_VEHICLE_TYPE_CHOICES: Map<number, string> = new Map([
  [1, "Terrestre"],
]);

export const TRANSPORT_VEHICLE_DOCUMENT_TYPE_CHOICES: Map<number, string> =
  new Map([
    [1, "Número de identificación del vehículo"],
    [2, "Número de matrícula del vehículo"],
  ]);
