import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import TaxPayer from "../../models/tax-payer";

export interface TaxPayerState {
  loaded: boolean;
  loading: Boolean;
  error: boolean;
  errorDetails: string[];
  data: {
    taxPayer: TaxPayer;
    userTaxPayers: TaxPayer[];
  };
}

const initialState: TaxPayerState = {
  loaded: false,
  loading: false,
  error: false,
  errorDetails: [],
  data: {
    taxPayer: {},
    userTaxPayers: [],
  },
};

export const taxPayerSlice = createSlice({
  name: "tax-payer",
  initialState,
  reducers: {
    setTaxPayerLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setTaxPayerLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaxPayer: (state, action: PayloadAction<TaxPayer>) => {
      state.loaded = true;
      state.loading = false;
      state.data.taxPayer = action.payload;
    },
    setUserTaxPayers: (state, action: PayloadAction<TaxPayer[]>) => {
      state.data.userTaxPayers = action.payload;
    },
    setTaxPayerErrorLoading: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setTaxPayerLoaded,
  setTaxPayerLoading,
  setTaxPayer,
  setUserTaxPayers,
  setTaxPayerErrorLoading,
} = taxPayerSlice.actions;

export default taxPayerSlice.reducer;
