import { DashboardActionTypes } from "../types/dashboard";
import {
  SET_SHOW_DASHBOARD,
  SET_MINIMIZE_DASHBOARD,
  INITIALIZE_DASHBOARD,
} from "../reducers/dashboard";

export function initializeDashbboard(
  sidebarShow: boolean | "responsive",
  sidebarMinimize: boolean
) {
  return {
    type: INITIALIZE_DASHBOARD,
    payload: {
      sidebarShow: sidebarShow,
      sidebarMinimize: sidebarMinimize,
      initialized: true,
    },
  };
}

export function showDashboard(
  sidebarShow: boolean | "responsive"
): DashboardActionTypes {
  return {
    type: SET_SHOW_DASHBOARD,
    payload: sidebarShow,
  };
}

export function minimizeDashboard(sidebarShow: boolean): DashboardActionTypes {
  return {
    type: SET_MINIMIZE_DASHBOARD,
    payload: sidebarShow,
  };
}
