export const ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE = "electronic_invoice";
export const ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE_LABEL =
  "Factura Electrónica";
export const ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE = "printed_invoice";
export const ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE_LABEL = "Factura Impresa";
export const ASSOCIATED_DOCUMENT_TYPE_NONE = "none";
export const ASSOCIATED_DOCUMENT_TYPE_NONE_LABEL = "Ninguno";

export const ASSOCIATED_DOCUMENT_TYPE_CHOICES: Map<string, string> = new Map([
  [
    ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE,
    ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE_LABEL,
  ],
  [
    ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE,
    ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE_LABEL,
  ],
  [ASSOCIATED_DOCUMENT_TYPE_NONE, ASSOCIATED_DOCUMENT_TYPE_NONE_LABEL],
]);
