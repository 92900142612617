import { CPopover } from "@coreui/react";
import {
  AUTO_INVOICE_STATUS_APPROVED_BY_SET,
  AUTO_INVOICE_STATUS_CANCELLATION_FAILED,
  AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED,
  AUTO_INVOICE_STATUS_CANCELLED,
  AUTO_INVOICE_STATUS_CHOICES,
  AUTO_INVOICE_STATUS_ERROR,
  AUTO_INVOICE_STATUS_RESEND_REQUESTED,
  AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR,
  AUTO_INVOICE_STATUS_UPLOADED_TO_SET,
  AutoInvoice,
} from "../../models/auto-invoice";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";

interface AutoInvoiceCrudStatusProps {
  autoInvoice: AutoInvoice;
}

const AutoInvoiceCrudStatus: React.FC<AutoInvoiceCrudStatusProps> = ({
  autoInvoice,
}) => {
  const [currentAutoInvoice, setCurrentAutoInvoice] = useState(autoInvoice);

  useSub(`auto_invoice_${autoInvoice.id}`, (autoInvoice: AutoInvoice) => {
    setCurrentAutoInvoice(autoInvoice);
  });

  useEffect(() => {
    setCurrentAutoInvoice(autoInvoice);
  }, [autoInvoice]);

  let badgeClassName = "badge-info";

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_UPLOADED_TO_SET) {
    badgeClassName = "badge-warning";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_APPROVED_BY_SET) {
    badgeClassName = "badge-success";
  }

  if (
    currentAutoInvoice.status === AUTO_INVOICE_STATUS_CANCELLATION_REQUESTED
  ) {
    badgeClassName = "badge-light";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_CANCELLED) {
    badgeClassName = "badge-info";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_CANCELLATION_FAILED) {
    badgeClassName = "badge-danger";
  }

  if (currentAutoInvoice.status === AUTO_INVOICE_STATUS_RESEND_REQUESTED) {
    badgeClassName = "badge-warning";
  }

  return (
    <td className="text-center">
      <span
        className={`badge badge-pill ${badgeClassName}`}
        style={{ fontSize: "14px" }}
      >
        {AUTO_INVOICE_STATUS_CHOICES.get(currentAutoInvoice.status!)}{" "}
        {currentAutoInvoice.status === AUTO_INVOICE_STATUS_ERROR ||
        currentAutoInvoice.status ===
          AUTO_INVOICE_STATUS_CANCELLATION_FAILED ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Código: {currentAutoInvoice.setResponseCode}</li>
                <li>
                  Descripción: {currentAutoInvoice.setResponseDescription}
                </li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
        {currentAutoInvoice.status === AUTO_INVOICE_STATUS_SET_UNKNOWN_ERROR ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Error: {currentAutoInvoice.setError}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
      </span>
    </td>
  );
};

export default AutoInvoiceCrudStatus;
