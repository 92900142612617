import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import {
  createItem,
  getItem,
  ItemRequestStatus,
  updateItem,
} from "../../api/generics";
import DispatchPoint, { newDispatchPoint } from "../../models/dispatch-point";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import Establishment from "../../models/establishment";
import Loading from "../indicators/Loading";
import EconomicActivity from "../../models/economic-activity";
import EconomicActivitySelect from "../economic-activity/EconomicActivitySelect";

interface DispatchPointFormProps {
  dispatchPoint?: DispatchPoint;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const DispatchPointForm: React.FC<DispatchPointFormProps> = ({
  dispatchPoint,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);
  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );
  const [defaultEconomicActivity, setDefaultEconomicActivity] =
    useState<EconomicActivity | null>(null);
  const dispatchPointRef = useRef<DispatchPoint | null>(
    dispatchPoint ? dispatchPoint : null
  );

  const updateDispatchPointRef = (dispatchPoint: DispatchPoint) => {
    dispatchPointRef.current = dispatchPoint;
  };

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dispatchPointRef.current === null) {
      return;
    }
    dispatchPointRef.current.identifier = e.target.value;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    setEstablishment(newEstablishment);
  };

  const onDefaultEconomicActivityChange = (
    newDefaultEconomicActivity: EconomicActivity | null
  ) => {
    if (dispatchPointRef.current === null) {
      return;
    }
    if (newDefaultEconomicActivity !== null) {
      dispatchPointRef.current.defaultEconomicActivityId =
        newDefaultEconomicActivity.id;
      dispatchPointRef.current.defaultEconomicActivityCode =
        newDefaultEconomicActivity.code;
      dispatchPointRef.current.defaultEconomicActivityDescription =
        newDefaultEconomicActivity.description;
    } else {
      dispatchPointRef.current.defaultEconomicActivityId = undefined;
      dispatchPointRef.current.defaultEconomicActivityCode = undefined;
      dispatchPointRef.current.defaultEconomicActivityDescription = undefined;
    }

    setDefaultEconomicActivity(newDefaultEconomicActivity);
  };

  const onSave = useCallback(async () => {
    const dispatchPoint = dispatchPointRef.current;

    if (dispatchPoint === null) {
      return;
    }

    if (taxPayerId === undefined) {
      return;
    }

    setSubmitting(true);

    dispatchPoint.taxPayerId = taxPayerId;
    dispatchPoint.establishmentId = establishment
      ? establishment.id
      : undefined;
    let requestPromise: Promise<ItemRequestStatus<DispatchPoint>>;
    if (dispatchPoint.id === 0 || dispatchPoint.id === undefined) {
      requestPromise = createItem("/dispatch_points/create/", dispatchPoint);
    } else {
      requestPromise = updateItem(
        `/dispatch_points/${dispatchPoint.id}/`,
        dispatchPoint
      );
    }

    const dispatchPointStatus = await requestPromise;

    if (dispatchPointStatus.status !== SUCCESS) {
      if (dispatchPointStatus.errors !== undefined) {
        setErrors(dispatchPointStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (dispatchPointStatus.detail !== undefined) {
        message = dispatchPointStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [establishment, defaultEconomicActivity]);

  const loadInitialData = async (dispatchPoint: DispatchPoint) => {
    if (dispatchPoint.id === undefined) {
      return;
    }

    setLoading(true);

    const initialEstablishmentStatusPromise = getItem<Establishment>(
      `/establishments/${dispatchPoint.establishmentId}/`
    );

    let defaultEconomicActivityPromise: Promise<
      ItemRequestStatus<EconomicActivity>
    > | null = null;

    if (dispatchPoint.defaultEconomicActivityId !== undefined) {
      defaultEconomicActivityPromise = getItem<EconomicActivity>(
        `/economic_activities/${dispatchPoint.defaultEconomicActivityId}/`
      );
    }

    const initialEstablishmentStatus = await initialEstablishmentStatusPromise;

    if (
      initialEstablishmentStatus.status === SUCCESS &&
      initialEstablishmentStatus.data !== undefined
    ) {
      setEstablishment(initialEstablishmentStatus.data);
    }

    if (defaultEconomicActivityPromise !== null) {
      const defaultEconomicActivityStatus =
        await defaultEconomicActivityPromise;

      if (
        defaultEconomicActivityStatus.status === SUCCESS &&
        defaultEconomicActivityStatus.data !== undefined
      ) {
        setDefaultEconomicActivity(defaultEconomicActivityStatus.data);
      }
    }

    setLoading(false);
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateDispatchPointRef(newDispatchPoint());
  };

  useEffect(() => {
    updateDispatchPointRef(dispatchPoint ? dispatchPoint : newDispatchPoint());
    if (dispatchPoint !== undefined) {
      setLoading(true);
      loadInitialData(dispatchPoint);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchPoint]);

  if (loading) {
    return <Loading height="30vh" />;
  }

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Establecimiento:
              </CLabel>
            </CCol>
            <CCol>
              <EstablishmentSelect
                value={establishment}
                onChange={onEstablishmentChange}
              ></EstablishmentSelect>

              <FieldErrors
                errors={getFieldErrors("establishmentId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Punto de expedición
              </CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                defaultValue={
                  dispatchPointRef.current
                    ? dispatchPointRef.current.identifier
                    : ""
                }
                onChange={onIdentifierChange}
                placeholder="N° de Punto de expedición"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Actividad Económica:</CLabel>
            </CCol>
            <CCol>
              <EconomicActivitySelect
                value={defaultEconomicActivity}
                isClearable={true}
                onChange={onDefaultEconomicActivityChange}
              ></EconomicActivitySelect>

              <FieldErrors
                errors={
                  getFieldErrors(
                    "defaultEconomicActivityId",
                    errors
                  ) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default DispatchPointForm;
