import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import RegimeType from "../../models/regime-type";
import { getList } from "../../api/generics";
import React from "react";

const RECORS_PER_SEARCH = 20;

type RegimeTypeSelectProps = {
  defaultValue?: RegimeType | null;
  onChange?: (_: RegimeType | null) => void;
};

const RegimeTypeSelect: React.FC<RegimeTypeSelectProps> = ({
  defaultValue,
  onChange,
}) => {
  const onRegimeTypeChange = async (newRegimeType: RegimeType | null) => {
    if (onChange !== undefined) {
      onChange(newRegimeType);
    }
  };

  const loadRegimeTypeOptions = async (
    s: string,
    currentOpts: readonly (
      | RegimeType
      | { options: RegimeType[]; label?: string }
    )[]
  ): Promise<
    Response<RegimeType, { options: RegimeType[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = undefined;

    if (s !== "") {
      additionalParams = new Map();
      additionalParams.set("search", s);
    }

    const regimeTypesPaginated = await getList<RegimeType>(
      "/regime_types/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: RegimeType[] = [];
    let count = 0;

    if (regimeTypesPaginated.data !== undefined) {
      newOpts = regimeTypesPaginated.data.items;
      count = regimeTypesPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Régimen"}
      onChange={onRegimeTypeChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadRegimeTypeOptions}
      getOptionLabel={(option: RegimeType) =>
        `${option.code!} - ${option.description!}`
      }
      getOptionValue={(option: RegimeType) => `${option.id!}`}
      defaultValue={defaultValue}
    />
  );
};

export default RegimeTypeSelect;
