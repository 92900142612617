const Error = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h2>Ha ocurrido un error</h2>
    </div>
  );
};

export default Error;
