import store, { RootState } from "../store";

export const fetchAuthenticated = async (
  input: RequestInfo,
  init: RequestInit
): Promise<Response> => {
  const state: RootState = store.getState();
  const accessToken = state.user.accessToken;
  if (init.headers === undefined) {
    init.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    let headers = init.headers as Record<string, string>;
    headers.Authorization = `Bearer ${accessToken}`;
    init.headers = headers;
  }
  return fetch(input, init);
};
