import DisablementCrud from "../components/disablement/DisablementCrud";

const DisablementPage = () => {
  return (
    <>
      <DisablementCrud />
    </>
  );
};

export default DisablementPage;
