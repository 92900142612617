export const PYG = "PYG";
export const PYG_LABEL = "Guaraníes";
export const USD = "USD";
export const USD_LABEL = "Dólares Americanos";
export const PAB = "PAB";
export const PAB_LABEL = "Balboas";

export const CURRENCY_CHOICES: Map<string, string> = new Map([
  [PYG, PYG_LABEL],
  [USD, USD_LABEL],
  [PAB, PAB_LABEL],
]);
