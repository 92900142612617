import EconomicActivityCrud from "../components/economic-activity/EconomicActivityCrud";

const EconomicActivitesPage = () => {
  return (
    <>
      <EconomicActivityCrud />
    </>
  );
};

export default EconomicActivitesPage;
