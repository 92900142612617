import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Client, {
  CLIENT_TYPE_CHOICES,
  CLIENT_TYPE_ENTITY,
  CLIENT_TYPE_FOREIGN_ENTITY,
  CLIENT_TYPE_NOT_NOMINATED,
  CLIENT_TYPE_PERSON,
  newClient,
} from "../../models/client";
import Errors, { getFieldErrors } from "../../models/errors";
import { RootState } from "../../store";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { ItemRequestStatus, createItem, updateItem } from "../../api/generics";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import DepartmentSelect from "../location/DepartmentSelect";
import Department from "../../models/department";
import District from "../../models/district";
import City from "../../models/city";
import DistrictSelect from "../location/DistrictSelect";
import CitySelect from "../location/CitySelect";
import {
  getCityByCode,
  getDepartmentByCode,
  getDistrictByCode,
} from "../../api/locations";
import Loading from "../indicators/Loading";
import SelectBootstrap from "../react-select-bootstrap/SelectBootstrap";
import { COUNTRIES, Country } from "../../models/countries";

interface ClientFormProps {
  client?: Client;
  onCancel: () => void | Promise<void>;
  onSuccess: (_?: Client) => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const ClientForm: React.FC<ClientFormProps> = ({
  client,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(false);

  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);

  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);
  const [clientType, setClientType] = useState(
    client ? client.type : CLIENT_TYPE_PERSON
  );
  const [department, setDepartment] = useState<Department | null>(null);
  const [district, setDistrict] = useState<District | null>(null);
  const [city, setCity] = useState<City | null>(null);

  const [socialName, setSocialName] = useState("");
  const [isNotNominated, setIsNotNominated] = useState(false);

  const clientRef = useRef<Client | null>(client ? client : null);

  const [hasAddress, setHasAddress] = useState(
    clientRef !== null && clientRef.current?.hasAddress ? true : false
  );

  const [isTaxPayer, setIsTaxPayer] = useState(
    clientRef !== null && clientRef.current?.isTaxPayer
      ? clientRef.current?.isTaxPayer
      : false
  );

  const initialCountry = COUNTRIES.find((el) => {
    let code = "PRY";
    if (
      clientRef !== null &&
      clientRef.current !== null &&
      clientRef.current.countryCode !== undefined
    ) {
      code = clientRef.current.countryCode;
    }

    return code === el.code;
  });

  const [country, setCountry] = useState<Country | null>(
    initialCountry !== undefined
      ? initialCountry
      : { code: "PRY", description: "Paraguay" }
  );

  const updateClientRef = async (client: Client) => {
    clientRef.current = client;
  };

  const onSocialNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }
    setSocialName(e.target.value);
    clientRef.current.socialName = e.target.value;
  };

  const onFantasyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.fantasyName = e.target.value;
  };

  const onRucChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.ruc = e.target.value;
  };

  const onIsTaxPayerChange = (isTaxPayer: boolean) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.isTaxPayer = isTaxPayer;
    setIsTaxPayer(isTaxPayer);
  };

  const onHasAddressChange = (hasAddress: boolean) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.hasAddress = hasAddress;
    if (!hasAddress) {
      setDepartment(null);
      setDistrict(null);
      setCity(null);
      clientRef.current.address = undefined;
      clientRef.current.houseNumber = undefined;
    }
    setHasAddress(hasAddress);
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }
    clientRef.current.address = e.target.value;
  };

  const onCountryChange = (
    newCountry: {
      code: string;
      description: string;
    } | null
  ) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.countryCode =
      newCountry !== null ? newCountry.code : undefined;
    setCountry(newCountry);
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.email = e.target.value;
  };

  const onPhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.phoneNumber = e.target.value;
  };

  const onHouseNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }
    if (e.target.value === undefined) {
      clientRef.current.houseNumber = undefined;
    } else {
      clientRef.current.houseNumber = Number(e.target.value);
    }
  };

  const onClientTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (clientRef.current === null) {
      return;
    }

    const newClientType = e.target.value;

    setClientType(newClientType);

    clientRef.current.type = e.target.value;

    if (newClientType === CLIENT_TYPE_FOREIGN_ENTITY) {
      setHasAddress(true);
      setIsTaxPayer(false);
      setDepartment(null);
      setDistrict(null);
      setCity(null);
      setCountry(null);
      clientRef.current.countryCode = undefined;
      clientRef.current.hasAddress = true;
      clientRef.current.isTaxPayer = false;
      clientRef.current.city = undefined;
      clientRef.current.cityDescription = undefined;
      clientRef.current.district = undefined;
      clientRef.current.districtDescription = undefined;
      clientRef.current.department = undefined;
      clientRef.current.departmentDescription = undefined;
      clientRef.current.houseNumber = undefined;
    } else {
      setCountry({ code: "PRY", description: "Paraguay" });
      clientRef.current.countryCode = "PRY";
    }

    if (newClientType === CLIENT_TYPE_NOT_NOMINATED) {
      clientRef.current.socialName = "Sin Nombre";
      clientRef.current.isTaxPayer = false;
      setIsTaxPayer(false);
      setIsNotNominated(true);
      setSocialName("Sin Nombre");
    } else {
      clientRef.current.socialName = "";
      setIsNotNominated(false);
      setSocialName("");
    }
  };

  const onDepartmentChange = (newDepartment: Department | null) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.department = newDepartment?.code;
    clientRef.current.departmentDescription = newDepartment?.description;
    clientRef.current.district = undefined;
    clientRef.current.districtDescription = undefined;

    setDepartment(newDepartment);
    setDistrict(null);
    setCity(null);
  };

  const onDistrictChange = (newDistrict: District | null) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.district = newDistrict?.code;
    clientRef.current.districtDescription = newDistrict?.description;
    clientRef.current.city = undefined;
    clientRef.current.cityDescription = undefined;

    setDistrict(newDistrict);
    setCity(null);
  };

  const onCityChange = (newCity: City | null) => {
    if (clientRef.current === null) {
      return;
    }

    clientRef.current.city = newCity?.code;
    clientRef.current.cityDescription = newCity?.description;

    setCity(newCity);
  };

  const onSave = async () => {
    const client = clientRef.current;

    if (client === null) {
      return;
    }

    setSubmitting(true);

    client.taxPayerId = taxPayer.id;

    if (client.isTaxPayer === undefined) {
      client.isTaxPayer = false;
    }
    if (client.type === CLIENT_TYPE_PERSON) {
      client.fantasyName = undefined;
    }
    if (!hasAddress) {
      client.city = undefined;
      client.cityDescription = undefined;
      client.district = undefined;
      client.districtDescription = undefined;
      client.department = undefined;
      client.departmentDescription = undefined;
      client.address = undefined;
      client.houseNumber = undefined;
    }

    if (client.type === CLIENT_TYPE_FOREIGN_ENTITY) {
      client.city = undefined;
      client.cityDescription = undefined;
      client.district = undefined;
      client.districtDescription = undefined;
      client.department = undefined;
      client.departmentDescription = undefined;
      client.houseNumber = undefined;
    } else {
      if (client.countryCode === undefined) {
        client.countryCode = "PRY";
      }
    }

    let requestPromise: Promise<ItemRequestStatus<Client>>;
    if (client.id === undefined) {
      requestPromise = createItem<Client>("/clients/create/", client);
    } else {
      requestPromise = updateItem(`/clients/${client.id}/`, client);
    }

    const clientStatus = await requestPromise;

    if (clientStatus.status !== SUCCESS) {
      if (clientStatus.errors !== undefined) {
        setErrors(clientStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (clientStatus.detail !== undefined) {
        message = clientStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }
      onSuccess(clientStatus.data);
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateClientRef(newClient(taxPayer.id));
  };

  const loadClient = async (client?: Client) => {
    setLoading(true);
    if (client !== undefined) {
      if (client.department !== undefined) {
        const updatingDeparment = await getDepartmentByCode(client.department);
        setDepartment(updatingDeparment);
      }

      if (client.district !== undefined) {
        const updatingDistrict = await getDistrictByCode(client.district);
        setDistrict(updatingDistrict);
      }

      if (client.city !== undefined) {
        const updatingCity = await getCityByCode(client.city);
        setCity(updatingCity);
      }

      setSocialName(client.socialName !== undefined ? client.socialName : "");

      if (client.type === CLIENT_TYPE_NOT_NOMINATED) {
        setIsNotNominated(true);
      }
    } else {
      setSocialName("");
    }

    updateClientRef(client ? client : newClient(taxPayer.id));
    setLoading(false);
  };

  useEffect(() => {
    loadClient(client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  let socialNamePlaceholder = "Nombre de la persona o empresa";
  let rucLabel = "RUC o CI";

  if (clientType === CLIENT_TYPE_PERSON) {
    socialNamePlaceholder = "Nombre de la persona";
  } else if (clientType === CLIENT_TYPE_ENTITY) {
    socialNamePlaceholder = "Nombre de la Empresa";
    rucLabel = "RUC";
  }
  const showFantasyName = clientType === CLIENT_TYPE_ENTITY;

  if (loading) {
    return <Loading height="30vh" />;
  }

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Tipo:</CLabel>
            </CCol>
            <CCol>
              <CSelect
                type="text"
                defaultValue={clientRef.current ? clientRef.current.type : ""}
                onChange={onClientTypeChange}
              >
                <option value={""} disabled>
                  -----
                </option>
                {Array.from(CLIENT_TYPE_CHOICES.entries()).map((entry) => {
                  return (
                    <option key={entry[0]} value={entry[0]}>
                      {entry[1]}
                    </option>
                  );
                })}
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("type", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Nombre:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                value={socialName}
                onChange={onSocialNameChange}
                disabled={isNotNominated}
                placeholder={socialNamePlaceholder}
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("socialName", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {showFantasyName ? (
          <CFormGroup>
            <CRow>
              <CCol md={2}>
                <CLabel>Nombre de Fantasía:</CLabel>
              </CCol>
              <CCol>
                <CInput
                  type="text"
                  defaultValue={
                    clientRef.current ? clientRef.current.fantasyName : ""
                  }
                  onChange={onFantasyNameChange}
                  placeholder="Nombre de Fantasia"
                ></CInput>
                <FieldErrors
                  errors={getFieldErrors("fantasyName", errors) as string[]}
                ></FieldErrors>
              </CCol>
            </CRow>
          </CFormGroup>
        ) : (
          <></>
        )}

        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>{rucLabel}</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={clientRef.current ? clientRef.current.ruc : ""}
                onChange={onRucChange}
                placeholder="RUC con dígito verificador"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("ruc", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Es contribuyente</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  value={isTaxPayer}
                  onChange={onIsTaxPayerChange}
                  disabled={
                    clientType === CLIENT_TYPE_FOREIGN_ENTITY ||
                    clientType === CLIENT_TYPE_NOT_NOMINATED
                  }
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("isTaxPayer", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Agregar datos de dirección</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  value={hasAddress}
                  disabled={clientType === CLIENT_TYPE_FOREIGN_ENTITY}
                  onChange={onHasAddressChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("isTaxPayer", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        {hasAddress ? (
          <>
            {clientType === CLIENT_TYPE_FOREIGN_ENTITY ? (
              <>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>País</CLabel>
                    </CCol>
                    <CCol>
                      <SelectBootstrap
                        placeholder="Seleccione un País"
                        options={COUNTRIES}
                        value={country}
                        getOptionValue={(country) => country.code}
                        getOptionLabel={(country) => country.description}
                        onChange={onCountryChange}
                      ></SelectBootstrap>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </>
            ) : (
              <></>
            )}
            <CFormGroup></CFormGroup>
            {clientType !== CLIENT_TYPE_FOREIGN_ENTITY ? (
              <>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>Departamento</CLabel>
                    </CCol>
                    <CCol>
                      <DepartmentSelect
                        onChange={onDepartmentChange}
                        value={department}
                      />
                      <FieldErrors
                        errors={
                          getFieldErrors("department", errors) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>Distrito</CLabel>
                    </CCol>
                    <CCol>
                      <DistrictSelect
                        onChange={onDistrictChange}
                        value={district}
                        departmentValue={
                          department !== null ? department : undefined
                        }
                      />
                      <FieldErrors
                        errors={getFieldErrors("district", errors) as string[]}
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>Ciudad</CLabel>
                    </CCol>
                    <CCol>
                      <CitySelect
                        onChange={onCityChange}
                        value={city}
                        district={district !== null ? district : undefined}
                        department={
                          department !== null ? department : undefined
                        }
                      />
                      <FieldErrors
                        errors={getFieldErrors("city", errors) as string[]}
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </>
            ) : (
              <></>
            )}

            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Dirección</CLabel>
                </CCol>
                <CCol>
                  <CInput
                    type="text"
                    defaultValue={
                      clientRef.current ? clientRef.current.address : ""
                    }
                    onChange={onAddressChange}
                    placeholder="Dirección"
                  ></CInput>
                  <FieldErrors
                    errors={getFieldErrors("address", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            {clientType !== CLIENT_TYPE_FOREIGN_ENTITY ? (
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>Número de casa:</CLabel>
                  </CCol>
                  <CCol>
                    <CInput
                      type="number"
                      defaultValue={
                        clientRef.current
                          ? clientRef.current.houseNumber
                          : undefined
                      }
                      onChange={onHouseNumberChange}
                      placeholder="Número de casa"
                    ></CInput>
                    <FieldErrors
                      errors={getFieldErrors("houseNumber", errors) as string[]}
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Teléfono</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  clientRef.current ? clientRef.current.phoneNumber : ""
                }
                onChange={onPhoneNumberChange}
                placeholder="Número de teléfono"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("phoneNumber", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Email</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={clientRef.current ? clientRef.current.email : ""}
                onChange={onEmailChange}
                placeholder="Dirección de email"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("email", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default ClientForm;
