export interface ElectronicDocumentDisablement {
  id?: number;
  taxPayerId?: number;
  taxPayerRuc?: string;
  taxPayerSocialName?: string;
  taxPayerFantasyName?: string;
  stampId?: number;
  stampIdentifier?: string;
  stampBeginDate?: string;
  establishmentId?: number;
  establishmentCode?: string;
  dispatchPointId?: number;
  dispatchPointIdentifier?: string;
  status?: string;
  documentType?: string;
  from?: number;
  to?: number;
  reason?: string;
  setResponseCode?: string;
  setResponseDescription?: string;
  setError?: string;
}

export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_PENDING = "pending";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_PENDING_LABEL = "Pendiente";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR = "error";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR_LABEL = "Error";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_GENERATED = "generated";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_GENERATED_LABEL =
  "Generado";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET =
  "uploaded_to_set";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET_LABEL =
  "Subido";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET =
  "approved_by_set";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET_LABEL =
  "Aprobado";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR =
  "set_unknown_error";
export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR_LABEL =
  "Desconocido";

export const ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_CHOICES: Map<
  string,
  string
> = new Map([
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_PENDING,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_PENDING_LABEL,
  ],
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_ERROR_LABEL,
  ],
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_GENERATED,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_GENERATED_LABEL,
  ],
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_UPLOADED_TO_SET_LABEL,
  ],
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_APPROVED_BY_SET_LABEL,
  ],
  [
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR,
    ELECTRONIC_DOCUMENT_DISABLEMENT_STATUS_SET_UNKNOWN_ERROR_LABEL,
  ],
]);

export const newElectronicDocumentDisablement =
  (): ElectronicDocumentDisablement => {
    return {};
  };
