import { CPopover } from "@coreui/react";

import {
  CREDIT_NOTE_STATUS_APPROVED_BY_SET,
  CREDIT_NOTE_STATUS_CANCELLATION_FAILED,
  CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED,
  CREDIT_NOTE_STATUS_CANCELLED,
  CREDIT_NOTE_STATUS_CHOICES,
  CREDIT_NOTE_STATUS_ERROR,
  CREDIT_NOTE_STATUS_RESEND_REQUESTED,
  CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR,
  CREDIT_NOTE_STATUS_UPLOADED_TO_SET,
  CreditNote,
} from "../../models/credit-note";
import { useEffect, useState } from "react";
import { useSub } from "../../hooks/pub-sub";

interface CreditNoteCrudStatusProps {
  creditNote: CreditNote;
}

const CreditNoteCrudStatus: React.FC<CreditNoteCrudStatusProps> = ({
  creditNote,
}) => {
  const [currentCreditNote, setCurrentCreditNote] = useState(creditNote);

  useEffect(() => {
    setCurrentCreditNote(creditNote);
  }, [creditNote]);

  useSub(`credit_note_${creditNote.id}`, (creditNote: CreditNote) => {
    setCurrentCreditNote(creditNote);
  });
  let badgeClassName = "badge-info";

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_UPLOADED_TO_SET) {
    badgeClassName = "badge-warning";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR) {
    badgeClassName = "badge-danger";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_APPROVED_BY_SET) {
    badgeClassName = "badge-success";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_CANCELLATION_REQUESTED) {
    badgeClassName = "badge-light";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_CANCELLED) {
    badgeClassName = "badge-info";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_CANCELLATION_FAILED) {
    badgeClassName = "badge-danger";
  }

  if (currentCreditNote.status === CREDIT_NOTE_STATUS_RESEND_REQUESTED) {
    badgeClassName = "badge-warning";
  }

  return (
    <td className="text-center">
      <span
        className={`badge badge-pill ${badgeClassName}`}
        style={{ fontSize: "14px" }}
      >
        {CREDIT_NOTE_STATUS_CHOICES.get(currentCreditNote.status!)}{" "}
        {currentCreditNote.status === CREDIT_NOTE_STATUS_ERROR ||
        currentCreditNote.status === CREDIT_NOTE_STATUS_CANCELLATION_FAILED ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Código: {currentCreditNote.setResponseCode}</li>
                <li>Descripción: {currentCreditNote.setResponseDescription}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
        {currentCreditNote.status === CREDIT_NOTE_STATUS_SET_UNKNOWN_ERROR ? (
          <CPopover
            content={
              <ul
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  paddingBottom: "0px",
                  marginBottom: "0px",
                }}
              >
                <li>Error: {currentCreditNote.setError}</li>
              </ul>
            }
            placement="top"
            trigger="click"
          >
            <span
              className="fa fa-info-circle"
              style={{ cursor: "pointer" }}
            ></span>
          </CPopover>
        ) : (
          <></>
        )}
      </span>
    </td>
  );
};

export default CreditNoteCrudStatus;
