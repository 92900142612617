import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { postItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  AutoInvoice,
  formatAutoInvoiceNumber,
} from "../../models/auto-invoice";

interface InvoiceConsultByCdcProps {
  show: boolean;
  autoInvoice?: AutoInvoice;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const AutoInvoiceResendModal: React.FC<InvoiceConsultByCdcProps> = ({
  show,
  autoInvoice,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const message = autoInvoice
    ? `Está seguro de que quiere reenviar la autofactura ${formatAutoInvoiceNumber(
        autoInvoice
      )}?`
    : "";

  const onResendClick = async () => {
    if (autoInvoice !== undefined) {
      setSubmitting(true);
      const status = await postItem(`/auto_invoices/resend/`, {
        taxPayerId: taxPayer.id,
        autoInvoiceIds: [autoInvoice.id],
      });

      if (status.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (status.detail !== undefined) {
          message = status.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-success" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Reenviar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onResendClick} color="success">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Reenviando..." : "Reenviar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AutoInvoiceResendModal;
