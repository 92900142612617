import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

import { ElectronicDocumentDisablement } from "../../models/electronic-document-disablement";
import DisablementForm from "./DisablementForm";

interface DisablementUpdateModalProps {
  show: boolean;
  disablement?: ElectronicDocumentDisablement;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DisablementUpdateModal: React.FC<DisablementUpdateModalProps> = ({
  show,
  disablement,
  onCancel,
  onSuccess,
}) => {
  const modalTitle = disablement
    ? `Editar Inutilización: ${disablement.id}`
    : `Agregar Inutilización`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <DisablementForm
              disablement={disablement}
              onCancel={onCancel}
              onSuccess={onSuccess}
              clearOnSuccess={false}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default DisablementUpdateModal;
