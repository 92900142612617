interface TaxPayer {
  id?: number;
  ruc?: string;
  socialName?: string;
  fantasyName?: string;
  type?: string;
  regimeTypeId?: number;
}

export const TAX_PAYER_TYPE_PERSON = "person";
export const TAX_PAYER_TYPE_ENTITY = "entity";
export const TAX_PAYER_TYPE_PERSON_LABEL = "Persona";
export const TAX_PAYER_TYPE_ENTITY_LABEL = "Entidad";

export const TAX_PAYER_TYPE_CHOICES: Map<string, string> = new Map([
  [TAX_PAYER_TYPE_PERSON, TAX_PAYER_TYPE_PERSON_LABEL],
  [TAX_PAYER_TYPE_ENTITY, TAX_PAYER_TYPE_ENTITY_LABEL],
]);

export const newTaxPayer = (): TaxPayer => {
  return {};
};

export default TaxPayer;
