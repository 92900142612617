interface Stamp {
  id?: number;
  identifier?: string;
  beginDate?: string;
  taxPayerId?: number;
  setEnvironment?: string;
  useLotsByDefault?: boolean;
  uploadDocuments?: boolean;
  idCsc?: string;
  csc?: string;
  signaturePath?: string;
  signaturePassword?: string;
  logoPath?: string;
  emailEnableSend?: boolean;
  emailAdditionalReceptors?: string;
  emailInvoiceTemplatePath?: string;
  emailCreditNoteTemplatePath?: string;
  emailDebitNoteTemplatePath?: string;
  emailRemissionnoteTemplatePath?: string;
  enabled?: boolean;
}

export const newStamp = (): Stamp => {
  return {};
};

export default Stamp;
