import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { warningAlert } from "../utils/messages";
import RegimeType from "../../models/regime-type";

const ITEMS_PER_PAGE = 10;

const RegimeTypeCrud = () => {
  const fields = [
    { key: "code", _classes: ["text-center"], label: "Código" },
    { key: "description", _classes: ["text-center"], label: "Descripción" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const [loading, setLoading] = useState(true);
  const [regimeTypes, setRegimeTypes] = useState<RegimeType[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchRegimeTypes = async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const regimeTypesStatus = await getList<RegimeType>(
      "/regime_types/",
      limit,
      offset
    );

    if (regimeTypesStatus.status === SUCCESS) {
      if (regimeTypesStatus.data !== undefined) {
        const count = regimeTypesStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setRegimeTypes(regimeTypesStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = regimeTypesStatus.detail
        ? regimeTypesStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  /*const reset = () => {
    history.push(history.location.pathname);
  };*/

  useEffect(() => {
    fetchRegimeTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("regimeTypes")) {
        return;
      }
      fetchRegimeTypes();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Régimenes</h3>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={regimeTypes}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    actions: (item: RegimeType) => {
                      return <td className="text-center"></td>;
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default RegimeTypeCrud;
