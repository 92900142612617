import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import DispatchPoint from "../../models/dispatch-point";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type DispatchPointMultiSelectProps = {
  value: DispatchPoint[];
  onChange: (_: DispatchPoint[]) => void;
};

const DispatchPointMultiSelect: React.FC<DispatchPointMultiSelectProps> = ({
  value,
  onChange,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadDispatchPointOptions = async (
    s: string,
    currentOpts: readonly (
      | DispatchPoint
      | { options: DispatchPoint[]; label?: string }
    )[]
  ): Promise<
    Response<DispatchPoint, { options: DispatchPoint[]; label?: string }, any>
  > => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const dispatchPointsPaginated = await getList<DispatchPoint>(
      "/dispatch_points/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: DispatchPoint[] = [];
    let count = 0;

    if (dispatchPointsPaginated.data !== undefined) {
      newOpts = dispatchPointsPaginated.data.items;
      count = dispatchPointsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };

  const onDispatchPointsChange = (dispatchPoints: readonly DispatchPoint[]) => {
    const newDispatchPoints: DispatchPoint[] = [];

    for (const dispatchPoint of dispatchPoints) {
      newDispatchPoints.push(dispatchPoint);
    }

    onChange(newDispatchPoints);
  };

  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Punto de expedición"}
      onChange={onDispatchPointsChange}
      isMulti={true}
      debounceTimeout={300}
      loadOptions={loadDispatchPointOptions}
      getOptionLabel={(option: DispatchPoint) => {
        if (option.establishment !== undefined) {
          return `${option.establishment.code}-${option.identifier}`;
        } else if (option["establishment.code"] !== undefined) {
          const establishmentCode = option["establishment.code"];
          return `${establishmentCode}-${option.identifier}`;
        } else {
          return `${option.identifier}`;
        }
      }}
      getOptionValue={(option: DispatchPoint) => `${option.id}`}
      value={value}
    />
  );
};

export default DispatchPointMultiSelect;
