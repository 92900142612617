import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
} from "@coreui/react";

import { showDashboard } from "../../redux/actions/dashboard";
import { RootState } from "../../store";

import { HeaderDropdown } from "./index";
import Select from "react-select";
import { setTaxPayer } from "../../redux/tax-payer/tax-payer-slice";
import TaxPayer from "../../models/tax-payer";
import { createItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";

const Header = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector(
    (state: RootState) => state.dashboard.sidebarShow
  );

  const userTaxPayers = useSelector(
    (state: RootState) => state.taxPayer.data.userTaxPayers
  );

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch(showDashboard(val));
    if (val !== "responsive") {
      window.localStorage.setItem("sidebarShow", val ? "true" : "false");
    } else {
      window.localStorage.setItem("sidebarShow", "responsive");
    }
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch(showDashboard(val));
    if (val !== "responsive") {
      window.localStorage.setItem("sidebarShow", val ? "true" : "false");
    } else {
      window.localStorage.setItem("sidebarShow", "responsive");
    }
  };

  const onTaxPayerChange = async (taxPayer: TaxPayer) => {
    const taxPayerRequestStatus = await createItem<TaxPayer>(
      "/tax_payers/default_for_user/",
      taxPayer
    );

    if (taxPayerRequestStatus.status === SUCCESS) {
      dispatch(setTaxPayer(taxPayer));
    } else {
      errorAlert("Error eligiendo el contribuyente");
    }
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        {/* <CIcon name="logo" height="48" alt="Logo"/> */}
      </CHeaderBrand>

      {userTaxPayers.length > 1 ? (
        <CHeaderNav className="d-md-down-none mr-auto">
          <CHeaderNavItem className="px-1">Contribuyente: </CHeaderNavItem>
          <CHeaderNavItem className="px-1">
            <div style={{ width: "300px" }}>
              <Select
                placeholder="Seleccione un contribuyente"
                noOptionsMessage={() => "No se encontraron"}
                options={userTaxPayers}
                getOptionLabel={(option) => {
                  return `${option.ruc} - ${option.socialName}`;
                }}
                getOptionValue={(option) => {
                  return `${option.id}`;
                }}
                onChange={(option) => {
                  if (option !== null) {
                    onTaxPayerChange(option);
                  }
                }}
                value={taxPayer}
              ></Select>
            </div>
          </CHeaderNavItem>
        </CHeaderNav>
      ) : (
        <CHeaderNav className="d-md-down-none mr-auto"></CHeaderNav>
      )}

      <CHeaderNav className="px-3">
        {/* <HeaderDropdownNotif/>
        <HeaderDropdownTasks/>
        <HeaderDropdownMssg/> */}
        <HeaderDropdown />
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between"> */}
      {/* <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} /> */}
      {/*  <div className="d-md-down-none mfe-2 c-subheader-nav">
          <CLink className="c-subheader-nav-link" href="#">
            <CIcon name="cil-speech" alt="Settings" />
          </CLink>
          <CLink className="c-subheader-nav-link" aria-current="page" to="/dashboard">
            <CIcon name="cil-graph" alt="Dashboard" />
            &nbsp;Dashboard
          </CLink>
          <CLink className="c-subheader-nav-link" href="#">
            <CIcon name="cil-settings" alt="Settings" />
            &nbsp;Settings
          </CLink>
        </div> */}
      {/* </CSubheader> */}
    </CHeader>
  );
};

export default Header;
