import DispatchPointCrud from "../components/dispatch-point/DispatchPointCrud";

const DispatchPointPage = () => {
  return (
    <>
      <DispatchPointCrud />
    </>
  );
};

export default DispatchPointPage;
