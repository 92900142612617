import StampCrud from "../components/stamps/StampCrud";

const StampsPage = () => {
  return (
    <>
      <StampCrud />
    </>
  );
};

export default StampsPage;
