import {
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointForm from "./DispatchPointForm";

interface DispatchPointUpdateModalProps {
  show: boolean;
  dispatchPoint?: DispatchPoint;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const DispatchPointUpdateModal: React.FC<DispatchPointUpdateModalProps> = ({
  show,
  dispatchPoint,
  onCancel,
  onSuccess,
}) => {
  const modalTitle = dispatchPoint
    ? `Editar Punto de expedición: ${dispatchPoint.identifier}`
    : `Agregar Punto de expedición`;

  const onClose = () => {
    onCancel();
  };

  return (
    <CModal show={show} onClose={onClose} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>{modalTitle}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          {show ? (
            <DispatchPointForm
              dispatchPoint={dispatchPoint}
              onCancel={onCancel}
              onSuccess={onSuccess}
              clearOnSuccess={false}
            />
          ) : (
            <></>
          )}
        </CContainer>
      </CModalBody>
    </CModal>
  );
};

export default DispatchPointUpdateModal;
