import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { createItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Errors, { getFieldErrors } from "../../models/errors";
import { FieldErrors } from "../form/FieldErrors";
import { Receipt } from "../../models/receipt";

interface ReceiptCancellationModalProps {
  show: boolean;
  receipt?: Receipt;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ReceiptCancellationModal: React.FC<ReceiptCancellationModalProps> = ({
  show,
  receipt,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState<Errors>({});

  const message = receipt
    ? `Está seguro de que quiere anular el recibo ${receipt.id}`
    : "";

  const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const onCancellationClick = async () => {
    if (receipt !== undefined) {
      setSubmitting(true);
      const status = await createItem(`/receipts/${receipt.id}/cancel/`, {
        id: receipt.id,
        reason: reason,
      });

      if (status.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (status.detail !== undefined) {
          message = status.detail;
        }
        if (status.errors !== undefined) {
          setErrors(status.errors);
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Anular</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <CTextarea
                placeholder="Motivo"
                value={reason}
                onChange={onReasonChange}
              ></CTextarea>
              <FieldErrors
                errors={getFieldErrors("reason", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={submitting}
          onClick={onCancellationClick}
          color="danger"
        >
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Anulando..." : "Anular"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ReceiptCancellationModal;
