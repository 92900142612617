import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React, { useEffect, useRef, useState } from "react";

import Datetime from "react-datetime";

import Errors, {
  extractArrayErrors,
  getFieldErrors,
} from "../../models/errors";
import Client from "../../models/client";
import { useHistory } from "react-router-dom";
import { errorAlert, successAlert } from "../utils/messages";
import Establishment from "../../models/establishment";
import {
  ItemRequestStatus,
  createItem,
  getItem,
  updateItem,
} from "../../api/generics";
import { NOT_FOUND, SUCCESS } from "../../utils/constants/tags";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CLabel,
  CRow,
  CSelect,
} from "@coreui/react";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import { FieldErrors } from "../form/FieldErrors";
import ClientSelect from "../client/ClientSelect";
import Loading from "../indicators/Loading";
import ClientUpdateModal from "../client/ClientUpdateModal";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import { CURRENCY_CHOICES, PYG } from "../../currency/available-currencies";
import CurrencyField from "../currencies/CurrencyField";
import DebitNoteItemForm from "./DebitNoteItemForm";
import { Spinner } from "react-bootstrap";
import { getRoutePath } from "../../routes";
import DispatchPoint from "../../models/dispatch-point";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import {
  ASSOCIATED_DOCUMENT_TYPE_CHOICES,
  ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE,
  ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE,
} from "../../models/associated-document";
import { INVOICE_STATUS_APPROVED_BY_SET, Invoice } from "../../models/invoice";
import InvoiceSelect from "../invoice/InvoiceSelect";
import {
  DEBIT_NOTE_STATUS_APPROVED_BY_SET,
  DEBIT_NOTE_STATUS_UPLOADED_TO_SET,
  DebitNote,
  DebitNoteItem,
  newDebitNote,
  newDebitNoteItem,
} from "../../models/debit-note";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";
import { getClientById } from "../../api/client";
import { getInvoiceById } from "../../api/invoice";
import NotFound from "../indicators/NotFound";
import ServerError from "../indicators/ServerError";
import { changeTZToUtc } from "../../utils/dates";
import moment from "moment";
import PYGField from "../currencies/PYGField";

const DebitNoteForm = () => {
  const name = "Agregar Nota de Débito";

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadError, setLoadError] = useState(false);
  const [loadNotFound, setLoadNotFound] = useState(false);

  const debitNoteRef = useRef<DebitNote | null>(newDebitNote(taxPayer.id));
  const [debitNoteDate, setDebitNoteDate] = useState<Date | undefined>(
    undefined
  );

  const [associatedDocumentType, setAssociatedDocumentType] = useState<
    string | undefined
  >(ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE);

  const [electronicInvoice, setElectronicInvoice] = useState<Invoice | null>(
    null
  );

  const [currency, setCurrency] = useState(PYG);
  const [pygExchangeRate, setPygExchangeRate] = useState<number | undefined>(
    undefined
  );

  const [totalAmount, setTotalAmount] = useState<number | undefined>(undefined);
  const [amount10Percent, setAmount10Percent] = useState<number | undefined>(
    undefined
  );
  const [amount5Percent, setAmount5Percent] = useState<number | undefined>(
    undefined
  );
  const [amountExempt, setAmountExempt] = useState<number | undefined>(
    undefined
  );
  const [iva10Percent, setIva10Percent] = useState<number | undefined>(
    undefined
  );
  const [iva5Percent, setIva5Percent] = useState<number | undefined>(undefined);

  /*const updateDebitNoteRef = (debitNote: DebitNote) => {
    debitNoteRef.current = debitNote;
  };*/

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [client, setClient] = useState<Client | null>(null);
  const [showAddClient, setShowAddClient] = useState(false);

  const [items, setItems] = useState<DebitNoteItem[]>([]);

  const history = useHistory();

  const getEditingDebitNoteId = () => {
    const urlParams = new URLSearchParams(history.location.search);
    const editingDebitNoteId = urlParams.get("id");

    if (editingDebitNoteId !== null) {
      if (!isNaN(parseInt(editingDebitNoteId))) {
        return parseInt(editingDebitNoteId);
      }
    }

    return undefined;
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.establishmentId = newEstablishment?.id;
    debitNoteRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.dispatchPointId = newDispatchPoint?.id;
    debitNoteRef.current.dispatchPointIdentifier = newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.stampId = newStamp?.id;
    debitNoteRef.current.stampIdentifier = newStamp?.identifier;
    debitNoteRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onClientChange = (newClient: Client | null) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.clientId = newClient?.id;
    debitNoteRef.current.clientCode = newClient?.code;
    debitNoteRef.current.clientIsTaxPayer = newClient?.isTaxPayer;
    debitNoteRef.current.clientRuc = newClient?.ruc;
    debitNoteRef.current.clientSocialName = newClient?.socialName;
    debitNoteRef.current.clientFantasyName = newClient?.fantasyName;
    debitNoteRef.current.clientType = newClient?.type;
    debitNoteRef.current.clientCountryCode = newClient?.countryCode;
    debitNoteRef.current.clientHasAddress = newClient?.hasAddress;
    debitNoteRef.current.clientAddress = newClient?.address;
    debitNoteRef.current.clientHouseNumber = newClient?.houseNumber;
    debitNoteRef.current.clientDepartment = newClient?.department;
    debitNoteRef.current.clientDepartmentDescription =
      newClient?.departmentDescription;
    debitNoteRef.current.clientDistrict = newClient?.district;
    debitNoteRef.current.clientDistrictDescription =
      newClient?.districtDescription;
    debitNoteRef.current.clientCity = newClient?.city;
    debitNoteRef.current.clientCityDescription = newClient?.cityDescription;
    debitNoteRef.current.clientPhoneNumber = newClient?.phoneNumber;
    debitNoteRef.current.clientEmail = newClient?.email;

    setClient(newClient);
  };

  const onDebitNoteDateChange = (value: string | moment.Moment) => {
    if (debitNoteRef.current === null) {
      return;
    }

    const newDate = value as moment.Moment;

    if (newDate === undefined || !moment(value, true).isValid()) {
      return;
    }

    const debitNoteDate = new Date((value as moment.Moment).toISOString());
    setDebitNoteDate(debitNoteDate);

    debitNoteRef.current.debitNoteDate = debitNoteDate.toISOString();
  };

  const onAssociatedDocumentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    const newAssociatedDocumentType = e.target.value;

    debitNoteRef.current.associatedDocumentType = newAssociatedDocumentType;

    if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE
    ) {
      debitNoteRef.current.associatedDocumentPrintedType = undefined;
      debitNoteRef.current.associatedDocumentStampIdentifier = undefined;
      debitNoteRef.current.associatedDocumentEstablishmentCode = undefined;
      debitNoteRef.current.associatedDocumentDispatchPointCode = undefined;
      debitNoteRef.current.associatedDocumentNumber = undefined;
      debitNoteRef.current.associatedDocumentDate = undefined;
    } else if (
      newAssociatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE
    ) {
      debitNoteRef.current.associatedDocumentCdc = undefined;
      debitNoteRef.current.invoiceId = undefined;
      debitNoteRef.current.associatedDocumentPrintedType =
        ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;
      setElectronicInvoice(null);
    }

    setAssociatedDocumentType(e.target.value);
  };

  const onElectronicInvoiceChange = (newElectronicInvoice: Invoice | null) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.invoiceId = newElectronicInvoice?.id;
    debitNoteRef.current.associatedDocumentCdc = newElectronicInvoice?.cdc;

    setElectronicInvoice(newElectronicInvoice);
  };

  const onAssociatedDocumentStampIdentifierChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.associatedDocumentStampIdentifier = e.target.value;
  };

  const onAssociatedDocumentEstablishmentCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.associatedDocumentEstablishmentCode = e.target.value;
  };

  const onAssociatedDocumentDispatchPointCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.associatedDocumentDispatchPointCode = e.target.value;
  };

  const onAssociatedDocumentNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.associatedDocumentNumber = e.target.value;
  };

  const onAssociatedDocumentDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.associatedDocumentDate = e.target.value;
  };

  const onCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.currency = e.target.value;
    setCurrency(e.target.value);
  };

  const onPygExchangeRateChange = (newExchangeRate?: number) => {
    if (debitNoteRef.current === null) {
      return;
    }

    debitNoteRef.current.pygExchangeRate = newExchangeRate;
    setPygExchangeRate(newExchangeRate);
  };

  const onAddClientClick = () => {
    setShowAddClient(true);
  };

  const onAddClientSuccess = (newClient?: Client) => {
    let message = "Cliente agregado correctamente";
    onClientChange(newClient ? newClient : null);
    if (newClient) {
      message = `Cliente ${newClient.socialName} agregado correctamente`;
    }
    successAlert(message);
    setShowAddClient(false);
  };

  const onAddClientCancel = () => {
    setShowAddClient(false);
  };

  const onSave = async () => {
    if (debitNoteRef.current === null) {
      return;
    }

    setSubmitting(true);

    let requestPromise: Promise<ItemRequestStatus<DebitNote>>;
    let successMessage = "Nota de Crédito agregada exitósamente";

    const debitNote = debitNoteRef.current;
    debitNote.taxPayerRuc = taxPayer.ruc;
    debitNote.taxPayerSocialName = taxPayer.socialName;
    debitNote.taxPayerFantasyName = taxPayer.fantasyName;

    if (debitNote.associatedDocumentType === undefined) {
      debitNote.associatedDocumentType =
        ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
    }

    if (debitNote.currency === undefined) {
      debitNote.currency = PYG;
    }

    if (debitNote.id === undefined) {
      requestPromise = createItem<DebitNote>("/debit_notes/create/", debitNote);
    } else {
      requestPromise = updateItem<DebitNote>(
        `/debit_notes/${debitNote.id}/`,
        debitNote
      );
    }

    const requestStatus = await requestPromise;

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.errors !== undefined) {
        setErrors(requestStatus.errors);
      }

      let message = "Ha ocurrido un error!!";

      if (requestStatus.detail !== undefined) {
        message = requestStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      successAlert(successMessage);
      history.push(getRoutePath("debitNotes"));
    }
  };

  const loadInitialDebitNoteById = async (): Promise<DebitNote | null> => {
    const editingId = getEditingDebitNoteId();

    if (editingId === undefined) {
      return null;
    }

    let requestStatus = await getItem<DebitNote>(`/debit_notes/${editingId}/`);

    if (requestStatus.status !== SUCCESS) {
      if (requestStatus.status === NOT_FOUND) {
        setLoadNotFound(true);
      } else {
        setLoadError(true);
      }

      return null;
    }

    if (requestStatus.data === undefined || requestStatus.data === null) {
      setLoadError(true);
      return null;
    }

    return requestStatus.data!;
  };

  const loadInitialData = async () => {
    setLoading(true);
    const debitNote = await loadInitialDebitNoteById();

    if (debitNote === null) {
      setLoading(false);
      return;
    }

    if (
      debitNote.status === DEBIT_NOTE_STATUS_UPLOADED_TO_SET ||
      debitNote.status === DEBIT_NOTE_STATUS_APPROVED_BY_SET
    ) {
      setLoading(false);
      setLoadError(true);
    }

    const stampPromise = getStampById(debitNote.stampId, debitNote.taxPayerId);
    const establishmentPromise = getEstablishmentById(
      debitNote.establishmentId,
      debitNote.taxPayerId
    );
    const dispatchPointPromise = getDispatchPointById(
      debitNote.dispatchPointId
    );
    const clientPromise = getClientById(
      debitNote.clientId,
      debitNote.taxPayerId
    );

    let invoicePromise: Promise<Invoice | null> | null = null;

    if (debitNote.invoiceId !== undefined) {
      invoicePromise = getInvoiceById(
        debitNote.invoiceId,
        debitNote.taxPayerId
      );
    }

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;
    const client = await clientPromise;

    debitNoteRef.current = debitNote;

    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);
    setClient(client);

    if (invoicePromise !== null) {
      const previousInvoice = await invoicePromise;
      setElectronicInvoice(previousInvoice);
    }

    setAssociatedDocumentType(debitNote.associatedDocumentType);

    setCurrency(debitNote.currency ? debitNote.currency : PYG);
    setPygExchangeRate(debitNote.pygExchangeRate);

    onItemsChange(debitNote.items);

    setLoading(false);
  };

  useEffect(() => {
    const newDebitNoteDate = changeTZToUtc(new Date());
    if (debitNoteRef.current !== null) {
      debitNoteRef.current.debitNoteDate = newDebitNoteDate.toISOString();
      setDebitNoteDate(newDebitNoteDate);
    }
    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddItemClick = () => {
    if (debitNoteRef.current === null) {
      return;
    }

    const newItems = [...debitNoteRef.current.items, newDebitNoteItem()];
    onItemsChange(newItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onItemDelete = (index: number) => {
    if (debitNoteRef.current === null) {
      return;
    }

    const newItems = [...debitNoteRef.current.items];
    newItems.splice(index, 1);

    onItemsChange(newItems);
  };

  const onItemChange = (index: number, item: DebitNoteItem) => {
    if (debitNoteRef.current === null) {
      return;
    }

    const newItems = [...debitNoteRef.current.items];
    newItems[index] = item;
    onItemsChange(newItems);
  };

  const onItemsChange = (newItems: DebitNoteItem[]) => {
    if (debitNoteRef.current === null) {
      return;
    }

    let newTotalAmount: number | undefined = undefined;
    let newAmount10Percent: number | undefined = undefined;
    let newAmount5Percent: number | undefined = undefined;
    let newAmountExempt: number | undefined = undefined;
    let newIva10Percent: number | undefined = undefined;
    let newIva5Percent: number | undefined = undefined;

    for (let item of newItems) {
      if (item.unitAmount !== undefined && item.quantity !== undefined) {
        newTotalAmount = newTotalAmount
          ? newTotalAmount + item.unitAmount * item.quantity
          : item.unitAmount * item.quantity;
      }

      if (item.amount10Percent !== undefined) {
        newAmount10Percent = newAmount10Percent
          ? newAmount10Percent + item.amount10Percent
          : item.amount10Percent;
      }

      if (item.amount5Percent !== undefined) {
        newAmount5Percent = newAmount5Percent
          ? newAmount5Percent + item.amount5Percent
          : item.amount5Percent;
      }

      if (item.amountExempt !== undefined) {
        newAmountExempt = newAmountExempt
          ? newAmountExempt + item.amountExempt
          : item.amountExempt;
      }

      if (item.iva10Percent !== undefined) {
        newIva10Percent = newIva10Percent
          ? newIva10Percent + item.iva10Percent
          : item.iva10Percent;
      }

      if (item.iva5Percent !== undefined) {
        newIva5Percent = newIva5Percent
          ? newIva5Percent + item.iva5Percent
          : item.iva5Percent;
      }
    }

    setTotalAmount(newTotalAmount);
    setAmount10Percent(newAmount10Percent);
    setAmount5Percent(newAmount5Percent);
    setAmountExempt(newAmountExempt);
    setIva10Percent(newIva10Percent);
    setIva5Percent(newIva5Percent);

    debitNoteRef.current = {
      ...debitNoteRef.current,
      items: newItems,
      amount: newTotalAmount,
      amount10Percent: newAmount10Percent,
      amount5Percent: newAmount5Percent,
      amountExempt: newAmountExempt,
      iva10Percent: newIva10Percent,
      iva5Percent: newIva5Percent,
    };

    setItems(newItems);
  };

  const showElectronicInvoiceSelect =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE;
  const showPrintedInvoiceFields =
    associatedDocumentType === ASSOCIATED_DOCUMENT_TYPE_PRINTED_INVOICE;

  const itemErrors = extractArrayErrors("items", errors);

  if (loading) {
    return <Loading />;
  }

  if (loadNotFound) {
    return <NotFound />;
  }

  if (loadError) {
    return <ServerError />;
  }

  return (
    <>
      {!loading ? (
        <CCard>
          <CCardHeader>{name}</CCardHeader>
          <CCardBody>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Establecimiento:
                  </CLabel>
                </CCol>
                <CCol>
                  <EstablishmentSelect
                    onChange={onEstablishmentChange}
                    value={establishment}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("establishmentId", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Punto de Expedición:
                  </CLabel>
                </CCol>
                <CCol>
                  <DispatchPointSelect
                    onChange={onDispatchPointChange}
                    value={dispatchPoint}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("dispatchPointId", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Timbrado:
                  </CLabel>
                </CCol>
                <CCol>
                  <StampSelect onChange={onStampChange} value={stamp} />
                  <FieldErrors
                    errors={getFieldErrors("stampId", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Cliente:
                  </CLabel>
                </CCol>
                <CCol>
                  <CInputGroup>
                    <ClientSelect
                      value={client}
                      onChange={onClientChange}
                    ></ClientSelect>
                    <CInputGroupAppend>
                      <CButton
                        className="btn btn-primary"
                        // Prevent button on top
                        style={{ zIndex: 0 }}
                        onClick={onAddClientClick}
                      >
                        <i className="fa fa-plus"></i>
                      </CButton>
                    </CInputGroupAppend>
                  </CInputGroup>
                  <FieldErrors
                    errors={getFieldErrors("clientId", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Fecha de emisión:
                  </CLabel>
                </CCol>
                <CCol>
                  <Datetime
                    className="pl-0"
                    onChange={onDebitNoteDateChange}
                    value={debitNoteDate}
                    locale="es/PY"
                    utc
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                  ></Datetime>
                  <FieldErrors
                    errors={getFieldErrors("debitNoteDate", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Tipo Documento
                    Asociado:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      debitNoteRef.current
                        ? debitNoteRef.current.associatedDocumentType
                        : ASSOCIATED_DOCUMENT_TYPE_ELECTRONIC_INVOICE
                    }
                    onChange={onAssociatedDocumentTypeChange}
                  >
                    <option value={""} disabled>
                      -----
                    </option>
                    {Array.from(ASSOCIATED_DOCUMENT_TYPE_CHOICES.entries()).map(
                      (entry) => {
                        return (
                          <option key={entry[0]} value={entry[0]}>
                            {entry[1]}
                          </option>
                        );
                      }
                    )}
                  </CSelect>
                  <FieldErrors
                    errors={
                      getFieldErrors(
                        "associatedDocumentType",
                        errors
                      ) as string[]
                    }
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            {showElectronicInvoiceSelect ? (
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Factura
                      Electrónica:
                    </CLabel>
                  </CCol>
                  <CCol>
                    <InvoiceSelect
                      onChange={onElectronicInvoiceChange}
                      value={electronicInvoice}
                      status={INVOICE_STATUS_APPROVED_BY_SET}
                    />
                    <FieldErrors
                      errors={getFieldErrors("invoiceId", errors) as string[]}
                    ></FieldErrors>
                    <FieldErrors
                      errors={
                        getFieldErrors(
                          "associatedDocumentCdc",
                          errors
                        ) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            ) : (
              <></>
            )}
            {showPrintedInvoiceFields ? (
              <>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Timbrado:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          debitNoteRef.current
                            ? debitNoteRef.current
                                .associatedDocumentStampIdentifier
                            : undefined
                        }
                        onChange={onAssociatedDocumentStampIdentifierChange}
                        placeholder="Timbrado"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentStampIdentifier",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Establecimiento:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          debitNoteRef.current
                            ? debitNoteRef.current
                                .associatedDocumentEstablishmentCode
                            : undefined
                        }
                        onChange={onAssociatedDocumentEstablishmentCodeChange}
                        placeholder="Cod"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentEstablishmentCode",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Punto Expedición:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          debitNoteRef.current
                            ? debitNoteRef.current
                                .associatedDocumentDispatchPointCode
                            : undefined
                        }
                        onChange={onAssociatedDocumentDispatchPointCodeChange}
                        placeholder="Cod"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentDispatchPointCode",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
                <CFormGroup>
                  <CRow>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Nro. Factura:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="text"
                        defaultValue={
                          debitNoteRef.current
                            ? debitNoteRef.current.associatedDocumentNumber
                            : undefined
                        }
                        onChange={onAssociatedDocumentNumberChange}
                        placeholder="Nro"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentNumber",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                    <CCol md={2}>
                      <CLabel>
                        <span className="text-danger">*</span> Fecha Factura:
                      </CLabel>
                    </CCol>
                    <CCol md={2}>
                      <CInput
                        type="date"
                        defaultValue={
                          debitNoteRef.current
                            ? debitNoteRef.current.associatedDocumentDate
                            : undefined
                        }
                        onChange={onAssociatedDocumentDateChange}
                        placeholder="Fecha"
                      ></CInput>
                      <FieldErrors
                        errors={
                          getFieldErrors(
                            "associatedDocumentDate",
                            errors
                          ) as string[]
                        }
                      ></FieldErrors>
                    </CCol>
                  </CRow>
                </CFormGroup>
              </>
            ) : (
              <></>
            )}
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Moneda:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CSelect
                    type="text"
                    defaultValue={
                      debitNoteRef.current !== null
                        ? debitNoteRef.current.currency
                        : PYG
                    }
                    onChange={onCurrencyChange}
                  >
                    <option value={""} disabled>
                      -----
                    </option>
                    {Array.from(CURRENCY_CHOICES.entries()).map((entry) => {
                      return (
                        <option key={entry[0]} value={entry[0]}>
                          {entry[1]}
                        </option>
                      );
                    })}
                  </CSelect>
                  <FieldErrors
                    errors={getFieldErrors("currency", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            {currency !== PYG ? (
              <CFormGroup>
                <CRow>
                  <CCol md={2}>
                    <CLabel>
                      <span className="text-danger">*</span> Cambio:
                    </CLabel>
                  </CCol>
                  <CCol md={2}>
                    <PYGField
                      placeholder="Total"
                      limit={1000000000}
                      onChange={onPygExchangeRateChange}
                      value={pygExchangeRate}
                    />
                    <FieldErrors
                      errors={
                        getFieldErrors("pygExchangeRate", errors) as string[]
                      }
                    ></FieldErrors>
                  </CCol>
                </CRow>
              </CFormGroup>
            ) : (
              <></>
            )}
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>
                    <span className="text-danger">*</span> Total:
                  </CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="Total"
                    limit={1000000000}
                    disabled={true}
                    value={totalAmount}
                  />
                  <FieldErrors
                    errors={getFieldErrors("amount", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>Total 10%:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="Total 10%"
                    limit={1000000000}
                    disabled={true}
                    value={amount10Percent}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("amount10Percent", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>Total 5%:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="Total 5%"
                    limit={1000000000}
                    disabled={true}
                    value={amount5Percent}
                  />
                  <FieldErrors
                    errors={
                      getFieldErrors("amount5Percent", errors) as string[]
                    }
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>Total Exento:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="Total Exento"
                    limit={1000000000}
                    disabled={true}
                    value={amountExempt}
                  />
                  <FieldErrors
                    errors={getFieldErrors("amountExempt", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CFormGroup>
              <CRow>
                <CCol md={2}>
                  <CLabel>IVA 10%:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="IVA 10%"
                    limit={1000000000}
                    disabled={true}
                    value={iva10Percent}
                  />
                  <FieldErrors
                    errors={getFieldErrors("iva10Percent", errors) as string[]}
                  ></FieldErrors>
                </CCol>
                <CCol md={2}>
                  <CLabel>IVA 5%:</CLabel>
                </CCol>
                <CCol md={2}>
                  <CurrencyField
                    currency={currency}
                    placeholder="IVA 5%"
                    limit={1000000000}
                    disabled={true}
                    value={iva5Percent}
                  />
                  <FieldErrors
                    errors={getFieldErrors("iva5Percent", errors) as string[]}
                  ></FieldErrors>
                </CCol>
              </CRow>
            </CFormGroup>
            <CRow>
              <CCol>
                <h4>Ítems</h4>
              </CCol>
            </CRow>
            {items.map((item, ix) => (
              <CRow key={ix}>
                <CCol>
                  <DebitNoteItemForm
                    currency={currency}
                    item={item}
                    onDelete={() => onItemDelete(ix)}
                    onChange={(item) => onItemChange(ix, item)}
                    errors={ix < itemErrors.length ? itemErrors[ix] : {}}
                  />
                </CCol>
              </CRow>
            ))}
            <CRow>
              <CCol>
                <CButton
                  className="btn btn-primary float-right"
                  onClick={onAddItemClick}
                >
                  Agregar Ítem
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CCardFooter>
        </CCard>
      ) : (
        <Loading />
      )}
      <ClientUpdateModal
        show={showAddClient}
        onSuccess={onAddClientSuccess}
        onCancel={onAddClientCancel}
      />
    </>
  );
};

export default DebitNoteForm;
