import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarMinimizer,
  //CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavDropdown,
} from "@coreui/react";

//import CIcon from '@coreui/icons-react';

import {
  minimizeDashboard,
  showDashboard,
} from "../../redux/actions/dashboard";
import { RootState } from "../../store";

// sidebar nav config
import { getRouteName, getRoutePath } from "../../routes";
import PermissionRequiredComponent from "../permissions/PermissionRequiredComponent";
import {
  AUTO_INVOICES_RETRIEVE,
  CREDIT_NOTES_CREATE,
  CREDIT_NOTES_RETRIEVE,
  CREDIT_NOTES_UPDATE,
  DEBIT_NOTES_CREATE,
  DEBIT_NOTES_RETRIEVE,
  DEBIT_NOTES_UPDATE,
  DISABLEMENTS_RETRIEVE,
  INVOICES_CREATE,
  INVOICES_RETRIEVE,
  INVOICES_UPDATE,
  RECEIPTS_CREATE,
  RECEIPTS_RETRIEVE,
  RECEIPTS_UPDATE,
  REMISSION_NOTES_CREATE,
  REMISSION_NOTES_RETRIEVE,
  REMISSION_NOTES_UPDATE,
  TAX_PAYER_SETTINGS_UPDATE,
} from "../../auth/permissions";
import HasAnyPermissionComponent from "../permissions/HasAnyPermissionComponent";

const Sidebar = () => {
  const dispatch = useDispatch();
  const show = useSelector((state: RootState) => state.dashboard.sidebarShow);
  const minimize = useSelector(
    (state: RootState) => state.dashboard.sidebarMinimize
  );

  const isConfigurationNavPage = () => {
    return (
      window.location.pathname === getRoutePath("regimeTypes") ||
      window.location.pathname === getRoutePath("economicActivities") ||
      window.location.pathname === getRoutePath("taxPayer") ||
      window.location.pathname === getRoutePath("stamps") ||
      window.location.pathname === getRoutePath("establishments") ||
      window.location.pathname === getRoutePath("dispatchPoints")
    );
  };

  const [showConfigurationNav, setShowConfigurationNav] = useState(
    isConfigurationNavPage()
  );

  const history = useHistory();

  useEffect(() => {
    return history.listen((_) => {
      setShowConfigurationNav(isConfigurationNavPage());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <CSidebar
      show={show}
      onShowChange={(val: boolean) => {
        dispatch(showDashboard(val));
        window.localStorage.setItem("sidebarShow", val ? "true" : "false");
      }}
      minimize={minimize}
      onMinimizeChange={(val: boolean) => {
        dispatch(minimizeDashboard(!val));
        window.localStorage.setItem("sidebarMinimize", !val ? "true" : "false");
      }}
      dropdownMode={"noAction"}
    >
      <CSidebarBrand className="d-md-down-none" to="/"></CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem
          name={getRouteName("dashboard")}
          to={getRoutePath("dashboard")}
          icon={"cil-home"}
        ></CSidebarNavItem>
        {/*<CSidebarNavItem
          name={getRouteName("users")}
          to={getRoutePath("users")}
          icon={"cil-people"}
        ></CSidebarNavItem>*/}
        {/*<CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />*/}
        <PermissionRequiredComponent permissionName={INVOICES_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("invoices")}
            to={getRoutePath("invoices")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={CREDIT_NOTES_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("creditNotes")}
            to={getRoutePath("creditNotes")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={DEBIT_NOTES_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("debitNotes")}
            to={getRoutePath("debitNotes")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={REMISSION_NOTES_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("remissionNotes")}
            to={getRoutePath("remissionNotes")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={AUTO_INVOICES_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("autoInvoices")}
            to={getRoutePath("autoInvoices")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={RECEIPTS_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("receipts")}
            to={getRoutePath("receipts")}
            icon={<i className="c-sidebar-nav-icon fa fa-file-text"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <PermissionRequiredComponent permissionName={DISABLEMENTS_RETRIEVE}>
          <CSidebarNavItem
            name={getRouteName("disablements")}
            to={getRoutePath("disablements")}
            icon={<i className="c-sidebar-nav-icon fa fa-hashtag"></i>}
          ></CSidebarNavItem>
        </PermissionRequiredComponent>
        <HasAnyPermissionComponent
          permissionNames={[
            INVOICES_RETRIEVE,
            INVOICES_CREATE,
            INVOICES_UPDATE,
            CREDIT_NOTES_RETRIEVE,
            CREDIT_NOTES_CREATE,
            CREDIT_NOTES_UPDATE,
            DEBIT_NOTES_RETRIEVE,
            DEBIT_NOTES_CREATE,
            DEBIT_NOTES_UPDATE,
            REMISSION_NOTES_RETRIEVE,
            REMISSION_NOTES_CREATE,
            REMISSION_NOTES_UPDATE,
            RECEIPTS_RETRIEVE,
            RECEIPTS_CREATE,
            RECEIPTS_UPDATE,
          ]}
        >
          <CSidebarNavItem
            name={getRouteName("clients")}
            to={getRoutePath("clients")}
            icon={"cil-people"}
          ></CSidebarNavItem>
        </HasAnyPermissionComponent>
        <PermissionRequiredComponent permissionName={TAX_PAYER_SETTINGS_UPDATE}>
          <CSidebarNavDropdown
            name="Configuración"
            icon={<i className="c-sidebar-nav-icon fa fa-gear"></i>}
            show={showConfigurationNav}
          >
            <CSidebarNavItem
              name={getRouteName("regimeTypes")}
              to={getRoutePath("regimeTypes")}
              icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("economicActivities")}
              to={getRoutePath("economicActivities")}
              icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("stamps")}
              to={getRoutePath("stamps")}
              icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("establishments")}
              to={getRoutePath("establishments")}
              icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("dispatchPoints")}
              to={getRoutePath("dispatchPoints")}
              icon={<i className="c-sidebar-nav-icon fa fa-list"></i>}
            ></CSidebarNavItem>
            <CSidebarNavItem
              name={getRouteName("taxPayer")}
              to={getRoutePath("taxPayer")}
              icon={"company-icon"}
            ></CSidebarNavItem>
          </CSidebarNavDropdown>
        </PermissionRequiredComponent>
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default Sidebar;
