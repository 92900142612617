import ReceiptForm from "../components/receipt/ReceiptForm";

const ReceiptPage = () => {
  return (
    <>
      <ReceiptForm />
    </>
  );
};

export default ReceiptPage;
