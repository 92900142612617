import Establishment from "./establishment";

interface DispatchPoint {
  [key: string]: any;
  id?: number;
  identifier?: string;
  establishmentId?: number;
  taxPayerId?: number;
  establishment?: Establishment;
  defaultEconomicActivityId?: number;
  defaultEconomicActivityCode?: string;
  defaultEconomicActivityDescription?: string;
}

export const newDispatchPoint = (): DispatchPoint => {
  return {};
};

export default DispatchPoint;
