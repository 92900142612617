import { useEffect, useState } from "react";

import CurrencyInput from "react-currency-input-field";

interface PYGFieldProps {
  limit?: number;
  placeholder?: string;
  value?: number;
  disabled?: boolean;
  onChange?: (_: number | undefined) => void;
}

const PYGField: React.FC<PYGFieldProps> = ({
  limit,
  placeholder,
  value,
  disabled,
  onChange,
}) => {
  const prefix = "₲";

  const [className, setClassName] = useState("");
  const [editingValue, setEditingValue] = useState<string | number>(
    value ? Number(value) : ""
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnValueChange = (newValue?: string, _?: string): void => {
    if (!newValue) {
      setClassName("");
      setEditingValue("");

      if (onChange) {
        onChange(undefined);
      }

      return;
    }

    if (Number.isNaN(Number(newValue))) {
      setErrorMessage("Introduzca un monto válido");
      setClassName("is-invalid");
      return;
    }

    if (limit && Number(newValue) > limit) {
      let formatter = new Intl.NumberFormat(undefined, {});
      setErrorMessage(`Máximo: ${prefix}${formatter.format(limit)}`);
      setClassName("is-invalid");
      setEditingValue(newValue);
      return;
    }

    setClassName("is-valid");
    setEditingValue(newValue);

    if (onChange) {
      onChange(Number(newValue));
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      setEditingValue(value);
    } else {
      setEditingValue("");
    }
  }, [value]);

  return (
    <>
      <CurrencyInput
        className={`form-control ${className}`}
        placeholder={placeholder}
        decimalsLimit={0}
        prefix={prefix}
        onValueChange={disabled ? undefined : handleOnValueChange}
        value={editingValue ? editingValue : ""}
        disabled={disabled}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
};

export default PYGField;
