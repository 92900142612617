// Iva types
export const IVA_TYPE_10_PERCENT = "10_percent";
export const IVA_TYPE_10_PERCENT_LABEL = "10%";
export const IVA_TYPE_5_PERCENT = "5_percent";
export const IVA_TYPE_5_PERCENT_LABEL = "5%";
export const IVA_TYPE_EXEMPT = "exempt";
export const IVA_TYPE_EXEMPT_LABEL = "Exento";
export const IVA_TYPE_MIXED_10_PERCENT = "mixed_10_percent";
export const IVA_TYPE_MIXED_10_PERCENT_LABEL = "Mixto 10%";
export const IVA_TYPE_MIXED_5_PERCENT = "mixed_5_percent";
export const IVA_TYPE_MIXED_5_PERCENT_LABEL = "Mixto 5%";

export const IVA_TYPE_CHOICES: Map<string, string> = new Map([
  [IVA_TYPE_10_PERCENT, IVA_TYPE_10_PERCENT_LABEL],
  [IVA_TYPE_5_PERCENT, IVA_TYPE_5_PERCENT_LABEL],
  [IVA_TYPE_EXEMPT, IVA_TYPE_EXEMPT_LABEL],
  [IVA_TYPE_MIXED_10_PERCENT, IVA_TYPE_MIXED_10_PERCENT_LABEL],
  [IVA_TYPE_MIXED_5_PERCENT, IVA_TYPE_MIXED_5_PERCENT_LABEL],
]);
