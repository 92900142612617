import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import City from "../../models/city";
import District from "../../models/district";
import Department from "../../models/department";
const RECORS_PER_SEARCH = 20;

type CitySelectProps = {
  district?: District;
  department?: Department;
  placeholder?: React.ReactNode;
  value: City | null;
  onChange: (_: City | null) => void;
};

const CitySelect: React.FC<CitySelectProps> = ({
  placeholder,
  value,
  onChange,
  district,
  department,
}) => {
  const loadCityOptions = async (
    s: string,
    currentOpts: readonly (City | { options: City[]; label?: string })[]
  ): Promise<Response<City, { options: City[]; label?: string }, any>> => {
    let additionalParams: Map<string, string> | undefined = undefined;

    if (s !== "") {
      additionalParams = new Map();
      additionalParams.set("search", s);
    }
    if (district !== undefined) {
      if (additionalParams === undefined) {
        additionalParams = new Map();
      }
      additionalParams.set("district_id", district.code!.toString());
    }
    if (department !== undefined) {
      if (additionalParams === undefined) {
        additionalParams = new Map();
      }
      additionalParams.set("department_id", department.code!.toString());
    }

    const citiesPaginated = await getList<City>(
      "/locations/cities/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: City[] = [];
    let count = 0;

    if (citiesPaginated.data !== undefined) {
      newOpts = citiesPaginated.data.items;
      count = citiesPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  let key: string | undefined = undefined;
  if (department !== undefined) {
    key = key ? `${key}-${department.code}` : `${department.code}`;
  }
  if (district !== undefined) {
    key = key ? `${key}-${district.code}` : `${district.code}`;
  }
  return (
    <AsyncPaginateBootstrap
      key={key ? key : undefined}
      placeholder={placeholder ? placeholder : "Seleccione una Ciudad"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadCityOptions}
      getOptionLabel={(option: City) => `${option.description}`}
      getOptionValue={(option: City) => `${option.code}`}
      value={value}
    />
  );
};

export default CitySelect;
