export interface Country {
  code: string;
  description: string;
}

const COUNTRIES: Country[] = [
  { code: "MKD", description: "Macedonia del Norte" },
  { code: "TWN", description: "Taiwán (Provincia de China)" },
  { code: "DZA", description: "Argelia" },
  { code: "EGY", description: "Egipto" },
  { code: "LBY", description: "Libia" },
  { code: "MAR", description: "Marruecos" },
  { code: "SDN", description: "Sudán" },
  { code: "TUN", description: "Túnez" },
  { code: "ESH", description: "Sáhara Occidental" },
  { code: "IOT", description: "Territorio Británico del Océano Índico" },
  { code: "BDI", description: "Burundi" },
  { code: "COM", description: "Comoras" },
  { code: "DJI", description: "Djibouti" },
  { code: "ERI", description: "Eritrea" },
  { code: "ETH", description: "Etiopía" },
  {
    code: "ATF",
    description: "Territorio de las Tierras Australes Francesas",
  },
  { code: "KEN", description: "Kenya" },
  { code: "MDG", description: "Madagascar" },
  { code: "MWI", description: "Malawi" },
  { code: "MUS", description: "Mauricio" },
  { code: "MYT", description: "Mayotte" },
  { code: "MOZ", description: "Mozambique" },
  { code: "REU", description: "Reunión" },
  { code: "RWA", description: "Rwanda" },
  { code: "SYC", description: "Seychelles" },
  { code: "SOM", description: "Somalia" },
  { code: "SSD", description: "Sudán del Sur" },
  { code: "UGA", description: "Uganda" },
  { code: "TZA", description: "República Unida de Tanzanía" },
  { code: "ZMB", description: "Zambia" },
  { code: "ZWE", description: "Zimbabwe" },
  { code: "AGO", description: "Angola" },
  { code: "CMR", description: "Camerún" },
  { code: "CAF", description: "República Centroafricana" },
  { code: "TCD", description: "Chad" },
  { code: "COG", description: "Congo" },
  { code: "COD", description: "República Democrática del Congo" },
  { code: "GNQ", description: "Guinea Ecuatorial" },
  { code: "GAB", description: "Gabón" },
  { code: "STP", description: "Santo Tomé y Príncipe" },
  { code: "BWA", description: "Botswana" },
  { code: "LSO", description: "Lesotho" },
  { code: "NAM", description: "Namibia" },
  { code: "ZAF", description: "Sudáfrica" },
  { code: "SWZ", description: "Swazilandia" },
  { code: "BEN", description: "Benin" },
  { code: "BFA", description: "Burkina Faso" },
  { code: "CPV", description: "Cabo Verde" },
  { code: "CIV", description: "Côte d'Ivoire" },
  { code: "GMB", description: "Gambia" },
  { code: "GHA", description: "Ghana" },
  { code: "GIN", description: "Guinea" },
  { code: "GNB", description: "Guinea-Bissau" },
  { code: "LBR", description: "Liberia" },
  { code: "MLI", description: "Malí" },
  { code: "MRT", description: "Mauritania" },
  { code: "NER", description: "Níger" },
  { code: "NGA", description: "Nigeria" },
  { code: "SHN", description: "Santa Elena" },
  { code: "SEN", description: "Senegal" },
  { code: "SLE", description: "Sierra Leona" },
  { code: "TGO", description: "Togo" },
  { code: "AIA", description: "Anguila" },
  { code: "ATG", description: "Antigua y Barbuda" },
  { code: "ABW", description: "Aruba" },
  { code: "BHS", description: "Bahamas" },
  { code: "BRB", description: "Barbados" },
  { code: "BES", description: "Bonaire, San Eustaquio y Saba" },
  { code: "VGB", description: "Islas Vírgenes Británicas" },
  { code: "CYM", description: "Islas Caimán" },
  { code: "CUB", description: "CUBA" },
  { code: "CUW", description: "Curaçao" },
  { code: "DMA", description: "Dominica" },
  { code: "DOM", description: "República Dominicana" },
  { code: "GRD", description: "Granada" },
  { code: "GLP", description: "Guadalupe" },
  { code: "HTI", description: "Haití" },
  { code: "JAM", description: "Jamaica" },
  { code: "MTQ", description: "Martinica" },
  { code: "MSR", description: "Montserrat" },
  { code: "PRI", description: "Puerto Rico" },
  { code: "BLM", description: "San Bartolomé" },
  { code: "KNA", description: "Saint Kitts y Nevis" },
  { code: "LCA", description: "Santa Lucía" },
  { code: "MAF", description: "San Martín (parte francesa)" },
  { code: "VCT", description: "San Vicente y las Granadinas" },
  { code: "SXM", description: "San Martín (parte holandés)" },
  { code: "TTO", description: "Trinidad y Tabago" },
  { code: "TCA", description: "Islas Turcas y Caicos" },
  { code: "VIR", description: "Islas Vírgenes de los Estados Unidos" },
  { code: "BLZ", description: "Belice" },
  { code: "CRI", description: "Costa Rica" },
  { code: "SLV", description: "El Salvador" },
  { code: "GTM", description: "Guatemala" },
  { code: "HND", description: "Honduras" },
  { code: "MEX", description: "México" },
  { code: "NIC", description: "Nicaragua" },
  { code: "PAN", description: "Panamá" },
  { code: "ARG", description: "Argentina" },
  { code: "BOL", description: "Bolivia (Estado Plurinacional de)" },
  { code: "BRA", description: "Brasil" },
  { code: "CHL", description: "Chile" },
  { code: "COL", description: "Colombia" },
  { code: "ECU", description: "Ecuador" },
  { code: "FLK", description: "Islas Malvinas (Falkland)" },
  { code: "GUF", description: "Guayana Francesa" },
  { code: "GUY", description: "Guyana" },
  { code: "PRY", description: "Paraguay" },
  { code: "PER", description: "Perú" },
  {
    code: "SGS",
    description: "Georgia del Sur y las Islas Sandwich del Sur",
  },
  { code: "SUR", description: "Suriname" },
  { code: "URY", description: "Uruguay" },
  { code: "VEN", description: "Venezuela (República Bolivariana de)" },
  { code: "BMU", description: "Bermuda" },
  { code: "CAN", description: "Canadá" },
  { code: "GRL", description: "Groenlandia" },
  { code: "SPM", description: "Saint Pierre y Miquelon" },
  { code: "USA", description: "Estados Unidos de América" },
  { code: "ATA", description: "Antártida" },
  { code: "KAZ", description: "Kazajstán" },
  { code: "KGZ", description: "Kirguistán" },
  { code: "TJK", description: "Tayikistán" },
  { code: "TKM", description: "Turkmenistán" },
  { code: "UZB", description: "Uzbekistán" },
  { code: "CHN", description: "China" },
  {
    code: "HKG",
    description: "China, región administrativa especial de Hong Kong",
  },
  {
    code: "MAC",
    description: "China, región administrativa especial de Macao",
  },
  { code: "PRK", description: "República Popular Democrática de Corea" },
  { code: "JPN", description: "Japón" },
  { code: "MNG", description: "Mongolia" },
  { code: "KOR", description: "República de Corea" },
  { code: "BRN", description: "Brunei Darussalam" },
  { code: "KHM", description: "Camboya" },
  { code: "IDN", description: "Indonesia" },
  { code: "LAO", description: "República Democrática Popular Lao" },
  { code: "MYS", description: "Malasia" },
  { code: "MMR", description: "Myanmar" },
  { code: "PHL", description: "Filipinas" },
  { code: "SGP", description: "Singapur" },
  { code: "THA", description: "Tailandia" },
  { code: "TLS", description: "Timor-Leste" },
  { code: "VNM", description: "Viet Nam" },
  { code: "AFG", description: "Afganistán" },
  { code: "BGD", description: "Bangladesh" },
  { code: "BTN", description: "Bhután" },
  { code: "IND", description: "India" },
  { code: "IRN", description: "Irán (República Islámica del)" },
  { code: "MDV", description: "Maldivas" },
  { code: "NPL", description: "Nepal" },
  { code: "PAK", description: "Pakistán" },
  { code: "LKA", description: "Sri Lanka" },
  { code: "ARM", description: "Armenia" },
  { code: "AZE", description: "Azerbaiyán" },
  { code: "BHR", description: "Bahrein" },
  { code: "CYP", description: "Chipre" },
  { code: "GEO", description: "Georgia" },
  { code: "IRQ", description: "Iraq" },
  { code: "ISR", description: "Israel" },
  { code: "JOR", description: "Jordania" },
  { code: "KWT", description: "Kuwait" },
  { code: "LBN", description: "Líbano" },
  { code: "OMN", description: "Omán" },
  { code: "QAT", description: "Qatar" },
  { code: "SAU", description: "Arabia Saudita" },
  { code: "PSE", description: "Estado de Palestina" },
  { code: "SYR", description: "República Árabe Siria" },
  { code: "TUR", description: "Turquía" },
  { code: "ARE", description: "Emiratos Árabes Unidos" },
  { code: "YEM", description: "Yemen" },
  { code: "BLR", description: "Belarús" },
  { code: "BGR", description: "Bulgaria" },
  { code: "CZE", description: "Chequia" },
  { code: "HUN", description: "Hungría" },
  { code: "POL", description: "Polonia" },
  { code: "MDA", description: "República de Moldova" },
  { code: "ROU", description: "Rumania" },
  { code: "RUS", description: "Federación de Rusia" },
  { code: "SVK", description: "Eslovaquia" },
  { code: "UKR", description: "Ucrania" },
  { code: "ALA", description: "Islas Åland" },
  { code: "GGY", description: "Guernsey" },
  { code: "JEY", description: "Jersey" },
  { code: "DNK", description: "Dinamarca" },
  { code: "EST", description: "Estonia" },
  { code: "FRO", description: "Islas Feroe" },
  { code: "FIN", description: "Finlandia" },
  { code: "ISL", description: "Islandia" },
  { code: "IRL", description: "Irlanda" },
  { code: "IMN", description: "Isla de Man" },
  { code: "LVA", description: "Letonia" },
  { code: "LTU", description: "Lituania" },
  { code: "NOR", description: "Noruega" },
  { code: "SJM", description: "Islas Svalbard y Jan Mayen" },
  { code: "SWE", description: "Suecia" },
  {
    code: "GBR",
    description: "Reino Unido de Gran Bretaña e Irlanda del Norte",
  },
  { code: "ALB", description: "Albania" },
  { code: "AND", description: "Andorra" },
  { code: "BIH", description: "Bosnia y Herzegovina" },
  { code: "HRV", description: "Croacia" },
  { code: "GIB", description: "Gibraltar" },
  { code: "GRC", description: "Grecia" },
  { code: "VAT", description: "Santa Sede" },
  { code: "ITA", description: "Italia" },
  { code: "MLT", description: "Malta" },
  { code: "MNE", description: "Montenegro" },
  { code: "PRT", description: "Portugal" },
  { code: "SMR", description: "San Marino" },
  { code: "SRB", description: "Serbia" },
  { code: "SVN", description: "Eslovenia" },
  { code: "ESP", description: "España" },
  { code: "MKD", description: "ex República Yugoslava de Macedonia" },
  { code: "AUT", description: "Austria" },
  { code: "BEL", description: "Bélgica" },
  { code: "FRA", description: "Francia" },
  { code: "DEU", description: "Alemania" },
  { code: "LIE", description: "Liechtenstein" },
  { code: "LUX", description: "Luxemburgo" },
  { code: "MCO", description: "Mónaco" },
  { code: "NLD", description: "Países Bajos" },
  { code: "CHE", description: "Suiza" },
  { code: "AUS", description: "Australia" },
  { code: "CXR", description: "Isla de Navidad" },
  { code: "CCK", description: "Islas Cocos (Keeling)" },
  { code: "HMD", description: "Islas Heard y McDonald" },
  { code: "NZL", description: "Nueva Zelandia" },
  { code: "NFK", description: "Islas Norfolk" },
  { code: "FJI", description: "Fiji" },
  { code: "NCL", description: "Nueva Caledonia" },
  { code: "PNG", description: "Papua Nueva Guinea" },
  { code: "SLB", description: "Islas Salomón" },
  { code: "VUT", description: "Vanuatu" },
  { code: "GUM", description: "Guam" },
  { code: "KIR", description: "Kiribati" },
  { code: "MHL", description: "Islas Marshall" },
  { code: "FSM", description: "Micronesia (Estados Federados de)" },
  { code: "NRU", description: "Nauru" },
  { code: "MNP", description: "Islas Marianas Septentrionales" },
  { code: "PLW", description: "Palau" },
  { code: "UMI", description: "Islas menores alejadas de Estados Unidos" },
  { code: "ASM", description: "Samoa Americana" },
  { code: "COK", description: "Islas Cook" },
  { code: "PYF", description: "Polinesia Francesa" },
  { code: "NIU", description: "Niue" },
  { code: "PCN", description: "Pitcairn" },
  { code: "WSM", description: "Samoa" },
  { code: "TKL", description: "Tokelau" },
  { code: "TON", description: "Tonga" },
  { code: "TUV", description: "Tuvalu" },
  { code: "WLF", description: "Islas Wallis y Futuna" },
  { code: "NN", description: "NO EXISTE" },
];

export { COUNTRIES };
