//200-299
export const HTTP_OK: number = 200;
export const HTTP_CREATED: number = 201;
export const HTTP_ACCEPTED: number = 202;
export const HTTP_NO_CONTENT: number = 204;
//400-499
export const HTTP_BAD_REQUEST: number = 400;
export const HTTP_UNAUTHORIZED: number = 401;
export const HTTP_FORBIDDEN: number = 403;
export const HTTP_NOT_FOUND: number = 404;
