import { useDispatch, useSelector } from "react-redux";
import TaxPayer from "../models/tax-payer";
import {
  setTaxPayerPermissions,
  setTaxPayerPermissionsLoading,
} from "../redux/tax-payer-permissions/tax-payer-permissions-slice";
import { getList } from "../api/generics";
import { SUCCESS } from "../utils/constants/tags";
import { useEffect } from "react";
import { RootState } from "../store";

interface PermissionsProviderProps {
  children: React.ReactNode;
}

const PermissionsProvider: React.FunctionComponent<PermissionsProviderProps> = (
  props
) => {
  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const dispatch = useDispatch();
  const loadTaxPayerPermissions = async (taxPayer: TaxPayer) => {
    dispatch(setTaxPayerPermissionsLoading(true));

    const res = await getList<string>(
      `/tax_payer_permissions/${taxPayer.id}/`,
      0,
      0
    );

    if (res.status !== SUCCESS || res.data === undefined) {
      dispatch(setTaxPayerPermissions([]));
      dispatch(setTaxPayerPermissionsLoading(false));
      return;
    }

    dispatch(setTaxPayerPermissions(res.data.items));
    dispatch(setTaxPayerPermissionsLoading(false));
  };

  useEffect(
    () => {
      loadTaxPayerPermissions(taxPayer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [taxPayer]
  );

  return <>{props.children}</>;
};

export default PermissionsProvider;
