import React from "react";

import { Route } from "react-router-dom";

import { useSelector } from "react-redux";

import { RootState } from "../store";
import PermissionRequired from "../components/permissions/PermissionRequired";

interface PermissionRequiredProps {
  permissionNames: string[];
  children: React.ReactNode;
}

const PermissionRequiredRoute = (props: PermissionRequiredProps) => {
  const permissions = useSelector(
    (state: RootState) => state.taxPayerPermissions.permissions
  );

  let found = false;

  for (const permissionName of props.permissionNames) {
    if (permissions === undefined || permissions.indexOf(permissionName) >= 0) {
      found = true;
      break;
    }
  }

  if (!found) {
    return <PermissionRequired />;
  }

  return <Route {...props}></Route>;
};

export default PermissionRequiredRoute;
