import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PermissionsState {
  loaded: boolean;
  loading: boolean;
  permissions: string[];
}

const initialState: PermissionsState = {
  loaded: false,
  loading: true,
  permissions: [],
};

export const taxPayerPerissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setTaxPayerPermissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTaxPayerPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
      state.loading = false;
    },
  },
});

export const { setTaxPayerPermissionsLoading, setTaxPayerPermissions } =
  taxPayerPerissionsSlice.actions;

export default taxPayerPerissionsSlice.reducer;
