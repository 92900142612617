import { io } from "socket.io-client";
import { API_ENDPOINT } from "../config/api";
import { useCallback, useEffect, useState } from "react";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { usePub } from "../hooks/pub-sub";
import { DEEvent } from "../models/events";
import {
  DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE,
  DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE,
  DOCUMENT_TYPE_ELECTRONIC_INVOICE,
} from "../models/documents";
import { Invoice } from "../models/invoice";
import { CreditNote } from "../models/credit-note";
import { AutoInvoice } from "../models/auto-invoice";

interface EventProps {
  children: React.ReactNode;
}

let socket = io(API_ENDPOINT, {
  autoConnect: false,
});

const EventsProvider: React.FunctionComponent<EventProps> = (
  props: EventProps
) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const isLoggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);

  const pub = usePub();

  const connectSocket = useCallback(async () => {
    if (!isConnected) {
      socket = io(API_ENDPOINT, {
        autoConnect: false,
        extraHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      socket.connect();
      socket.on("einvoice_events", (message) => {
        try {
          const event: DEEvent = JSON.parse(message);

          if (event.documentType === DOCUMENT_TYPE_ELECTRONIC_INVOICE) {
            const invoiceData = event.data as Invoice;
            pub(`invoice_${event.data.id}`, invoiceData);
          }

          if (event.documentType === DOCUMENT_TYPE_ELECTRONIC_CREDIT_NOTE) {
            const creditNoteData = event.data as CreditNote;
            pub(`credit_note_${event.data.id}`, creditNoteData);
          }

          if (event.documentType === DOCUMENT_TYPE_ELECTRONIC_AUTO_INVOICE) {
            const autoInvoiceData = event.data as AutoInvoice;
            pub(`auto_invoice_${event.data.id}`, autoInvoiceData);
          }
        } catch (e: any) {
          console.log(e);
        }
      });

      socket.on("close", () => {
        setIsConnected(false);
      });

      setIsConnected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, isLoggedIn, accessToken]);

  useEffect(() => {
    if (isLoggedIn) {
      connectSocket();
    } else {
      socket.disconnect();
      setIsConnected(false);
    }

    return () => {
      if (socket.connected) {
        socket.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  return <>{props.children}</>;
};

export default EventsProvider;
