import EstablishmentCrud from "../components/establishments/EstablishmentCrud";

const EstablishmentsPage = () => {
  return (
    <>
      <EstablishmentCrud />
    </>
  );
};

export default EstablishmentsPage;
