import ClientCrud from "../components/client/ClientCrud";

const ClientsPage = () => {
  return (
    <>
      <ClientCrud />
    </>
  );
};

export default ClientsPage;
