import {
  CButton,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { deleteItem } from "../../api/generics";
import { SUCCESS } from "../../utils/constants/tags";
import { errorAlert } from "../utils/messages";
import Client from "../../models/client";

interface ClientDeleteModalProps {
  show: boolean;
  client?: Client;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}

const ClientDeleteModal: React.FC<ClientDeleteModalProps> = ({
  show,
  client,
  onCancel,
  onSuccess,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const message = client
    ? `Está seguro de que quiere eliminar el client Nº ${client.socialName}`
    : "";

  const onDeleteClick = async () => {
    if (client !== undefined) {
      setSubmitting(true);
      const stampStatus = await deleteItem(`/clients/${client.id}/`, client);

      if (stampStatus.status !== SUCCESS) {
        let message = "Ha ocurrido un error!!";
        if (stampStatus.detail !== undefined) {
          message = stampStatus.detail;
        }
        errorAlert(message);
      } else {
        onSuccess();
      }

      setSubmitting(false);
    }
  };

  return (
    <CModal show={show} className="modal-danger" onClosed={onCancel} size="xl">
      <CModalHeader closeButton>
        <CModalTitle>Eliminar</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol sm="12">
              <h2>{message}</h2>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton disabled={submitting} onClick={onDeleteClick} color="danger">
          {submitting ? (
            <Spinner
              animation="grow"
              style={{
                height: "17px",
                width: "17px",
                marginTop: "auto",
                marginBottom: "auto",
                marginRight: "10px",
              }}
            />
          ) : (
            <></>
          )}
          {submitting ? "Eliminando..." : "Eliminar"}
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default ClientDeleteModal;
