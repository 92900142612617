import { Response } from "react-select-async-paginate";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import Stamp from "../../models/stamp";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type StampSelectProps = {
  value: Stamp | null;
  onChange: (_: Stamp | null) => void;
};

const StampSelect: React.FC<StampSelectProps> = ({ value, onChange }) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadStampOptions = async (
    s: string,
    currentOpts: readonly (Stamp | { options: Stamp[]; label?: string })[]
  ): Promise<Response<Stamp, { options: Stamp[]; label?: string }, any>> => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const stampsPaginated = await getList<Stamp>(
      "/stamps/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Stamp[] = [];
    let count = 0;

    if (stampsPaginated.data !== undefined) {
      newOpts = stampsPaginated.data.items;
      count = stampsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Timbrado"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadStampOptions}
      getOptionLabel={(option: Stamp) =>
        `${option.identifier} (${
          option.setEnvironment === "production" ? "Producción" : "Desarrollo"
        })`
      }
      getOptionValue={(option: Stamp) => `${option.id}`}
      value={value}
    />
  );
};

export default StampSelect;
