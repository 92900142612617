declare const ENV_MINIO_URL_SAME_ADDR: string | undefined;
declare const ENV_MINIO_URL_SAME_ADDR_PORT: string | undefined;

export const MINIO_URL_SAME_ADDR = ENV_MINIO_URL_SAME_ADDR
  ? ENV_MINIO_URL_SAME_ADDR === "true"
  : false;
export const MINIO_URL_SAME_ADDR_PORT =
  ENV_MINIO_URL_SAME_ADDR_PORT !== undefined
    ? parseInt(ENV_MINIO_URL_SAME_ADDR_PORT)
    : 8000;
