import { Response } from "react-select-async-paginate";
import Client from "../../models/client";
import AsyncPaginateBootstrap from "../react-select-bootstrap/AsyncPaginateBootstrap";
import { getList } from "../../api/generics";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const RECORS_PER_SEARCH = 20;

type ClientSelectProps = {
  value: Client | null;
  onChange: (_: Client | null) => void;
};

const ClientSelect: React.FC<ClientSelectProps> = ({ value, onChange }) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  const loadClientOptions = async (
    s: string,
    currentOpts: readonly (Client | { options: Client[]; label?: string })[]
  ): Promise<Response<Client, { options: Client[]; label?: string }, any>> => {
    let additionalParams: Map<string, string> | undefined = new Map<
      string,
      string
    >();

    additionalParams.set("tax_payer_id", taxPayerId!.toString());

    if (s !== "") {
      additionalParams.set("search", s);
    }

    const clientsPaginated = await getList<Client>(
      "/clients/",
      RECORS_PER_SEARCH,
      currentOpts.length,
      additionalParams
    );

    let newOpts: Client[] = [];
    let count = 0;

    if (clientsPaginated.data !== undefined) {
      newOpts = clientsPaginated.data.items;
      count = clientsPaginated.data.count;
    }

    const hasMore = currentOpts.length + newOpts.length < count;

    return {
      options: newOpts,
      hasMore: hasMore,
    };
  };
  return (
    <AsyncPaginateBootstrap
      placeholder={"Seleccione un Cliente"}
      onChange={onChange}
      isMulti={false}
      debounceTimeout={300}
      loadOptions={loadClientOptions}
      getOptionLabel={(option: Client) =>
        `${option.ruc} - ${option.socialName}`
      }
      getOptionValue={(option: Client) => `${option.id}`}
      value={value}
    />
  );
};

export default ClientSelect;
