import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import EconomicActivity, {
  newEconomicActivity,
} from "../../models/economic-activity";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface EconomicActivityFormProps {
  economicActivity?: EconomicActivity;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const EconomicActivityForm: React.FC<EconomicActivityFormProps> = ({
  economicActivity,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);

  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const economicActivityRef = useRef<EconomicActivity | null>(
    economicActivity ? economicActivity : null
  );

  const updateeconomicActivityRef = (economicActivity: EconomicActivity) => {
    economicActivityRef.current = economicActivity;
  };

  const onCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (economicActivityRef.current === null) {
      return;
    }

    economicActivityRef.current.code = e.target.value;
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (economicActivityRef.current === null) {
      return;
    }

    economicActivityRef.current.description = e.target.value;
  };

  const onSave = async () => {
    setSubmitting(true);
    const economicActivity = economicActivityRef.current;

    if (economicActivity === null) {
      return;
    }

    economicActivity.taxPayerId = taxPayerId;

    let requestPromise: Promise<ItemRequestStatus<EconomicActivity>>;
    if (economicActivity.id === 0 || economicActivity.id === undefined) {
      requestPromise = createItem(
        "/economic_activities/create/",
        economicActivity
      );
    } else {
      economicActivity.canBeModified = undefined;
      economicActivity.canBeDeleted = undefined;
      requestPromise = updateItem(
        `/economic_activities/${economicActivity.id}/`,
        economicActivity
      );
    }

    const economicActivityStatus = await requestPromise;

    if (economicActivityStatus.status !== SUCCESS) {
      if (economicActivityStatus.errors !== undefined) {
        setErrors(economicActivityStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (economicActivityStatus.detail !== undefined) {
        message = economicActivityStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateeconomicActivityRef(newEconomicActivity());
  };

  useEffect(() => {
    updateeconomicActivityRef(
      economicActivity ? economicActivity : newEconomicActivity()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [economicActivity]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Código</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  economicActivityRef.current
                    ? economicActivityRef.current.code
                    : ""
                }
                onChange={onCodeChange}
                placeholder="Ej: Código"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("code", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Descripción</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  economicActivityRef.current
                    ? economicActivityRef.current.description
                    : ""
                }
                onChange={onDescriptionChange}
                placeholder="Ej: Descripción"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("name", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default EconomicActivityForm;
