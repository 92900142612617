import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CTooltip,
} from "@coreui/react";
import { useCallback, useEffect, useState } from "react";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getList } from "../../api/generics";
import { getRoutePath } from "../../routes";
import { SUCCESS } from "../../utils/constants/tags";
import { successAlert, warningAlert } from "../utils/messages";
import DispatchPointDeleteModal from "./DispatchPointDeleteModal";
import DispatchPointUpdateModal from "./DispatchPointUpdateModal";
import DispatchPoint from "../../models/dispatch-point";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const ITEMS_PER_PAGE = 10;

const DispatchPointCrud = () => {
  const fields = [
    {
      key: "establishment.code",
      _classes: ["text-center"],
      label: "Establecimiento",
    },
    { key: "identifier", _classes: ["text-center"], label: "Código" },
    {
      key: "actions",
      _classes: ["text-center"],
      label: "Acciones",
      filter: false,
    },
  ];

  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  const [showDispatchPointEditModal, setShowDispatchPointEditModal] =
    useState(false);
  const [showDispatchPointDeleteModal, setShowDispatchPointDeleteModal] =
    useState(false);
  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | undefined>(
    undefined
  );

  const [loading, setLoading] = useState(true);
  const [dispatchPoints, setDispatchPoints] = useState<DispatchPoint[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();
  const getURLParams = () => {
    let urlParams = new URLSearchParams(history.location.search);
    let page = urlParams.get("page")
      ? parseInt(urlParams.get("page") as string)
      : 1;

    if (page <= 0) {
      page = 1;
    }

    return { page: page };
  };

  const fetchDispatchPoints = useCallback(async () => {
    const urlParams = getURLParams();
    const limit = ITEMS_PER_PAGE;
    const offset = ITEMS_PER_PAGE * Number(urlParams.page - 1);
    const additionalParams = new Map<string, string>();
    additionalParams.set("tax_payer_id", taxPayer.id!.toString());
    const itemsRequestStatus = await getList<DispatchPoint>(
      "/dispatch_points/",
      limit,
      offset,
      additionalParams
    );

    if (itemsRequestStatus.status === SUCCESS) {
      if (itemsRequestStatus.data !== undefined) {
        const count = itemsRequestStatus.data.count;
        const pages = Math.ceil((count ? count : 0) / ITEMS_PER_PAGE);
        setTotalPages(pages);
        setDispatchPoints(itemsRequestStatus.data.items);
        setCurrentPage(urlParams.page);
      }
    } else {
      const message = itemsRequestStatus.detail
        ? itemsRequestStatus.detail
        : "Error desconocido";
      warningAlert(message);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  const onPageChange = (page: number) => {
    setLoading(true);
    let urlParams = new URLSearchParams(history.location.search);
    urlParams.set("page", page.toString());
    let url = `?${urlParams.toString()}`;
    history.push(url);
  };

  /*const reset = () => {
    history.push(history.location.pathname);
  };*/

  const onAddClick = () => {
    setDispatchPoint(undefined);
    setShowDispatchPointEditModal(true);
  };

  const onUpdateClick = (dispatchPoint: DispatchPoint) => {
    setDispatchPoint(dispatchPoint);
    setShowDispatchPointEditModal(true);
  };

  const onDeleteClick = (dispatchPoint: DispatchPoint) => {
    setDispatchPoint(dispatchPoint);
    setShowDispatchPointDeleteModal(true);
  };

  const onDispatchPointEditCancel = () => {
    setShowDispatchPointEditModal(false);
  };

  const onDispatchPointEditSuccess = () => {
    successAlert("Datos guardados con éxito!");
    setShowDispatchPointEditModal(false);
    setDispatchPoints([]);
    setLoading(true);
    //setTotalPages(1);
    //setCurrentPage(1);
    //reset();
    fetchDispatchPoints();
  };

  const onDispatchPointDeleteCancel = () => {
    setShowDispatchPointDeleteModal(false);
  };

  const onDispatchPointDeleteSuccess = () => {
    successAlert("Registro eliminado con éxito!");
    setShowDispatchPointDeleteModal(false);
    setDispatchPoints([]);
    setLoading(true);
    fetchDispatchPoints();
  };

  useEffect(() => {
    fetchDispatchPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname !== getRoutePath("economicActivities")) {
        return;
      }
      fetchDispatchPoints();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    setLoading(true);
    fetchDispatchPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxPayer]);

  return (
    <>
      <CRow>
        <CCol lg="12">
          <CCard>
            <CCardHeader className="d-flex flex-row mb-3">
              <div className="p-2 ">
                <h3>Puntos de expedición</h3>
              </div>
              <div className="p-2 ">
                <CButton
                  color="primary"
                  className="float-right"
                  onClick={onAddClick}
                >
                  <BsFillPlusCircleFill />
                  &nbsp; Agregar Nuevo
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <div>
                <CDataTable
                  noItemsView={<h2 className="text-center">Sin Resultados</h2>}
                  addTableClasses={"table-fixed"}
                  fields={fields}
                  items={dispatchPoints}
                  striped
                  border
                  loading={loading}
                  responsive
                  scopedSlots={{
                    actions: (item: DispatchPoint) => {
                      const editButton = (
                        <CTooltip content="Editar">
                          <CButton
                            className="text-white"
                            color="warning"
                            onClick={() => {
                              onUpdateClick(item);
                            }}
                          >
                            <i className="fa fa-pencil"></i>
                          </CButton>
                        </CTooltip>
                      );
                      const deleteButton = (
                        <CTooltip content="Eliminar">
                          <CButton
                            className="text-white"
                            color="danger"
                            onClick={() => {
                              onDeleteClick(item);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </CButton>
                        </CTooltip>
                      );

                      return (
                        <td className="text-center">
                          <CButtonGroup>
                            {editButton}
                            {deleteButton}
                          </CButtonGroup>
                        </td>
                      );
                    },
                  }}
                />
                <div className="d-flex justify-content-center">
                  <CPagination
                    pages={totalPages}
                    activePage={currentPage}
                    onActivePageChange={(i: number) => onPageChange(i)}
                    className={totalPages < 2 ? "d-none" : ""}
                  />
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <DispatchPointUpdateModal
        show={showDispatchPointEditModal}
        onCancel={onDispatchPointEditCancel}
        onSuccess={onDispatchPointEditSuccess}
        dispatchPoint={dispatchPoint}
      />
      <DispatchPointDeleteModal
        show={showDispatchPointDeleteModal}
        onCancel={onDispatchPointDeleteCancel}
        onSuccess={onDispatchPointDeleteSuccess}
        dispatchPoint={dispatchPoint}
      />
    </>
  );
};

export default DispatchPointCrud;
