import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSelect,
  CTextarea,
} from "@coreui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import {
  ElectronicDocumentDisablement,
  newElectronicDocumentDisablement,
} from "../../models/electronic-document-disablement";
import Stamp from "../../models/stamp";
import StampSelect from "../stamps/StampSelect";
import Establishment from "../../models/establishment";
import DispatchPoint from "../../models/dispatch-point";
import EstablishmentSelect from "../establishments/EstablishmentSelect";
import DispatchPointSelect from "../dispatch-point/DispatchPointSelect";
import {
  DOCUMENT_TYPE_CHOICES,
  DOCUMENT_TYPE_ELECTRONIC_INVOICE,
} from "../../models/documents";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getStampById } from "../../api/stamp";
import { getEstablishmentById } from "../../api/establishment";
import { getDispatchPointById } from "../../api/dispatch-point";

interface DisablementFormProps {
  disablement?: ElectronicDocumentDisablement;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const DisablementForm: React.FC<DisablementFormProps> = ({
  disablement,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayer = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer
  );

  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);
  const [, setLoading] = useState(false);

  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [stamp, setStamp] = useState<Stamp | null>(null);

  const [establishment, setEstablishment] = useState<Establishment | null>(
    null
  );

  const [dispatchPoint, setDispatchPoint] = useState<DispatchPoint | null>(
    null
  );

  const disablementRef = useRef<ElectronicDocumentDisablement | null>(
    disablement ? disablement : null
  );

  const updateDisablementRef = (disablement: ElectronicDocumentDisablement) => {
    disablementRef.current = disablement;
  };

  const onStampChange = (newStamp: Stamp | null) => {
    if (disablementRef.current === null) {
      return;
    }

    disablementRef.current.stampId = newStamp?.id;
    disablementRef.current.stampIdentifier = newStamp?.identifier;
    disablementRef.current.stampBeginDate = newStamp?.beginDate;

    setStamp(newStamp);
  };

  const onEstablishmentChange = (newEstablishment: Establishment | null) => {
    if (disablementRef.current === null) {
      return;
    }

    disablementRef.current.establishmentId = newEstablishment?.id;
    disablementRef.current.establishmentCode = newEstablishment?.code;

    setEstablishment(newEstablishment);
  };

  const onDispatchPointChange = (newDispatchPoint: DispatchPoint | null) => {
    if (disablementRef.current === null) {
      return;
    }

    disablementRef.current.dispatchPointId = newDispatchPoint?.id;
    disablementRef.current.dispatchPointIdentifier =
      newDispatchPoint?.identifier;

    setDispatchPoint(newDispatchPoint);
  };

  const onDocumentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disablementRef.current === null) {
      return;
    }

    disablementRef.current.documentType = e.target.value;
  };

  const onFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disablementRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      disablementRef.current.from = parseInt(e.target.value);
    }
  };

  const onToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disablementRef.current === null) {
      return;
    }

    if (!isNaN(parseInt(e.target.value))) {
      disablementRef.current.to = parseInt(e.target.value);
    }
  };

  const onReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disablementRef.current === null) {
      return;
    }

    disablementRef.current.reason = e.target.value;
  };

  const onSave = async () => {
    setSubmitting(true);
    const disablement = disablementRef.current;

    if (disablement === null) {
      return;
    }

    if (disablement.documentType === undefined) {
      disablement.documentType = DOCUMENT_TYPE_ELECTRONIC_INVOICE;
    }

    disablement.taxPayerId = taxPayer.id;
    disablement.taxPayerRuc = taxPayer.ruc;
    disablement.taxPayerSocialName = taxPayer.socialName;
    disablement.taxPayerFantasyName = taxPayer.fantasyName;

    let requestPromise: Promise<
      ItemRequestStatus<ElectronicDocumentDisablement>
    >;
    if (disablement.id === 0 || disablement.id === undefined) {
      requestPromise = createItem("/disablements/create/", disablement);
    } else {
      requestPromise = updateItem(
        `/disablements/${disablement.id}/`,
        disablement
      );
    }

    const disablementStatus = await requestPromise;

    if (disablementStatus.status !== SUCCESS) {
      if (disablementStatus.errors !== undefined) {
        setErrors(disablementStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (disablementStatus.detail !== undefined) {
        message = disablementStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateDisablementRef(newElectronicDocumentDisablement());
  };

  const loadDisablement = useCallback(async () => {
    updateDisablementRef(
      disablement ? disablement : newElectronicDocumentDisablement()
    );
    if (disablement === undefined) {
      return;
    }
    setLoading(true);
    const stampPromise = getStampById(disablement.stampId);
    const establishmentPromise = getEstablishmentById(
      disablement.establishmentId
    );
    const dispatchPointPromise = getDispatchPointById(
      disablement.dispatchPointId
    );

    const stamp = await stampPromise;
    const establishment = await establishmentPromise;
    const dispatchPoint = await dispatchPointPromise;

    setStamp(stamp);
    setEstablishment(establishment);
    setDispatchPoint(dispatchPoint);

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disablement]);

  useEffect(() => {
    loadDisablement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disablement]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Timbrado:
              </CLabel>
            </CCol>
            <CCol>
              <StampSelect onChange={onStampChange} value={stamp} />
              <FieldErrors
                errors={getFieldErrors("stampId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Establecimiento:
              </CLabel>
            </CCol>
            <CCol>
              <EstablishmentSelect
                onChange={onEstablishmentChange}
                value={establishment}
              />
              <FieldErrors
                errors={getFieldErrors("establishmentId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Punto de Expedición:
              </CLabel>
            </CCol>
            <CCol>
              <DispatchPointSelect
                onChange={onDispatchPointChange}
                value={dispatchPoint}
              />
              <FieldErrors
                errors={getFieldErrors("dispatchPointId", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Tipo Documento:
              </CLabel>
            </CCol>
            <CCol md={3}>
              <CSelect
                type="text"
                defaultValue={
                  disablementRef.current
                    ? disablementRef.current.documentType
                    : DOCUMENT_TYPE_ELECTRONIC_INVOICE
                }
                onChange={onDocumentTypeChange}
              >
                <option value={""} disabled>
                  -----
                </option>
                {Array.from(DOCUMENT_TYPE_CHOICES.entries()).map((entry) => {
                  return (
                    <option key={entry[0]} value={entry[0]}>
                      {entry[1]}
                    </option>
                  );
                })}
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("documentType", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Desde:
              </CLabel>
            </CCol>
            <CCol md={2}>
              <CInput
                type="number"
                defaultValue={
                  disablementRef.current
                    ? disablementRef.current.from
                    : undefined
                }
                onChange={onFromChange}
                placeholder="Desde"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("from", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Hasta:
              </CLabel>
            </CCol>
            <CCol md={2}>
              <CInput
                type="number"
                defaultValue={
                  disablementRef.current ? disablementRef.current.to : undefined
                }
                onChange={onToChange}
                placeholder="Hasta"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("to", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>
                <span className="text-danger">*</span> Motivo:
              </CLabel>
            </CCol>
            <CCol md={10}>
              <CTextarea
                defaultValue={
                  disablementRef.current
                    ? disablementRef.current.reason
                    : undefined
                }
                placeholder="Motivo de inutilización"
                onChange={onReasonChange}
              ></CTextarea>
              <FieldErrors
                errors={getFieldErrors("reason", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default DisablementForm;
