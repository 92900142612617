import {
  CButton,
  CButtonGroup,
  CCol,
  CFormGroup,
  CInput,
  CInputFile,
  CLabel,
  CLink,
  CRow,
  CSelect,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

import { createItem, ItemRequestStatus, updateItem } from "../../api/generics";
import Stamp, { newStamp } from "../../models/stamp";
import Errors, { getFieldErrors } from "../../models/errors";
import { SUCCESS } from "../../utils/constants/tags";
import { FieldErrors } from "../form/FieldErrors";
import { errorAlert } from "../utils/messages";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { formatDate } from "../../utils/dates";
import {
  SET_ENVIRONMENT_CHOICES,
  SET_ENVIRONMENT_DEVELOPMENT,
} from "../../models/set-environment";
import AnimatedCheckbox from "../checkbox/AnimatedCheckbox";
import { putObjectMinioUrl } from "../../minio/urls";

interface StampFormProps {
  stamp?: Stamp;
  onCancel: () => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
  clearOnSuccess?: boolean;
}

const StampForm: React.FC<StampFormProps> = ({
  stamp,
  onCancel,
  onSuccess,
  clearOnSuccess,
}) => {
  const taxPayerId = useSelector(
    (state: RootState) => state.taxPayer.data.taxPayer.id
  );
  // TODO: verify error with regular clearOnSuccess bool
  // eslint-disable-next-line
  const [shouldClearOnSuccess, _] = useState(clearOnSuccess);

  const [errors, setErrors] = useState<Errors>({});
  const [submitting, setSubmitting] = useState(false);

  const [loadedSignaturePath] = useState<string | undefined>(
    stamp?.signaturePath
  );
  const [loadedLogoPath] = useState<string | undefined>(stamp?.logoPath);

  const stampRef = useRef<Stamp | null>(stamp ? stamp : null);
  const signature = useRef<HTMLInputElement>(null);
  const signatureFileName = useRef<string>("");
  const signatureUploaded = useRef<boolean>(
    stamp ? stamp.signaturePath !== undefined : false
  );
  const signatureModified = useRef<boolean>(false);
  const logo = useRef<HTMLInputElement>(null);
  const logoFileName = useRef<string>("");
  const logoUploaded = useRef<boolean>(
    stamp ? stamp.logoPath !== undefined : false
  );
  const logoModified = useRef<boolean>(false);

  const updateStampRef = (stamp: Stamp) => {
    stampRef.current = stamp;
  };

  const onIdentifierChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.identifier = e.target.value;
  };

  const onBeginDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.beginDate = e.target.value;
  };

  const onSetEnvironmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.setEnvironment = e.target.value;
  };

  const onUseLotsByDefaultChange = (useLotsByDefault: boolean) => {
    if (stampRef.current === null) {
      return;
    }

    stampRef.current.useLotsByDefault = useLotsByDefault;
  };

  const onUploadDocumentsChange = (uploadDocuments: boolean) => {
    if (stampRef.current === null) {
      return;
    }

    stampRef.current.uploadDocuments = uploadDocuments;
  };

  const onIdCscChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.idCsc = e.target.value;
  };

  const onCscChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.csc = e.target.value;
  };

  const onEnabledChange = (enabled: boolean) => {
    if (stampRef.current === null) {
      return;
    }

    stampRef.current.enabled = enabled;
  };

  const onSignaturePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (stampRef.current === null) {
      return;
    }
    stampRef.current.signaturePassword = e.target.value;
  };

  const onSignatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }

    signatureModified.current = true;
    let fileParts = e.target.value.split("\\");
    signatureFileName.current = fileParts[fileParts.length - 1];
    stampRef.current.signaturePath = signatureFileName.current;
  };

  const onLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (stampRef.current === null) {
      return;
    }

    logoModified.current = true;
    let fileParts = e.target.value.split("\\");
    logoFileName.current = fileParts[fileParts.length - 1];
    stampRef.current.logoPath = logoFileName.current;
  };

  const onEmailEnableSendChange = (emailEnableSend: boolean) => {
    if (stampRef.current === null) {
      return;
    }

    stampRef.current.emailEnableSend = emailEnableSend;
  };

  const onEmailAdditionalReceptorsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (stampRef.current === null) {
      return;
    }

    stampRef.current.emailAdditionalReceptors = e.target.value;
  };

  const handleSignatureUpload = async () => {
    if (!signatureModified.current && signatureUploaded.current) {
      return true;
    }

    const stampSignatureData = stampRef.current;

    if (stampSignatureData === null) {
      return false;
    }

    stampSignatureData.taxPayerId = taxPayerId;
    if (stampSignatureData.setEnvironment === undefined) {
      stampSignatureData.setEnvironment = SET_ENVIRONMENT_DEVELOPMENT;
    }

    if (stampSignatureData.useLotsByDefault === undefined) {
      stampSignatureData.useLotsByDefault = true;
    }

    if (stampSignatureData.uploadDocuments === undefined) {
      stampSignatureData.uploadDocuments = true;
    }

    const signatureUrlStatus = await createItem(
      "/stamps/create_signature_url/",
      stampSignatureData
    );

    if (signatureUrlStatus.status !== SUCCESS) {
      if (signatureUrlStatus.errors !== undefined) {
        setErrors(signatureUrlStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (signatureUrlStatus.detail !== undefined) {
        message = signatureUrlStatus.detail;
      }
      errorAlert(message);
      return false;
    }

    if (signatureUrlStatus.data === undefined) {
      return false;
    }

    const urlData = signatureUrlStatus.data as { url: string };

    if (!signature.current || !signature.current.files) {
      return false;
    }
    const signatureFile = signature.current.files[0];
    if (!signatureFile) {
      return false;
    }

    const uploadStatus = await fetch(putObjectMinioUrl(urlData.url!), {
      method: "PUT",
      body: signatureFile,
      headers: {
        "Content-type": signatureFile.type,
      },
    });

    return uploadStatus.status === 200;
  };

  const handleLogoUpload = async () => {
    if (!logoModified.current && logoUploaded.current) {
      return true;
    }

    const stampLogoData = stampRef.current;

    if (stampLogoData === null) {
      return false;
    }

    stampLogoData.taxPayerId = taxPayerId;
    if (stampLogoData.setEnvironment === undefined) {
      stampLogoData.setEnvironment = SET_ENVIRONMENT_DEVELOPMENT;
    }

    if (stampLogoData.useLotsByDefault === undefined) {
      stampLogoData.useLotsByDefault = true;
    }

    if (stampLogoData.uploadDocuments === undefined) {
      stampLogoData.uploadDocuments = true;
    }

    const logoUrlStatus = await createItem(
      "/stamps/create_logo_url/",
      stampLogoData
    );

    if (logoUrlStatus.status !== SUCCESS) {
      if (logoUrlStatus.errors !== undefined) {
        setErrors(logoUrlStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (logoUrlStatus.detail !== undefined) {
        message = logoUrlStatus.detail;
      }
      errorAlert(message);
      return false;
    }

    if (logoUrlStatus.data === undefined) {
      return false;
    }

    const urlData = logoUrlStatus.data as { url: string };

    if (!logo.current || !logo.current.files) {
      return false;
    }
    const logoFile = logo.current.files[0];
    if (!logoFile) {
      return false;
    }

    const uploadStatus = await fetch(putObjectMinioUrl(urlData.url!), {
      method: "PUT",
      body: logoFile,
      headers: {
        "Content-type": logoFile.type,
      },
    });

    return uploadStatus.status === 200;
  };

  const onSave = async () => {
    const stamp = stampRef.current;

    if (stamp === null) {
      return;
    }

    if (taxPayerId === undefined) {
      return;
    }

    setSubmitting(true);

    const signatureUploaded = await handleSignatureUpload();

    if (!signatureUploaded) {
      setSubmitting(false);
      return;
    }

    const logoUploaded = await handleLogoUpload();

    if (!logoUploaded) {
      setSubmitting(false);
      return;
    }

    stamp.taxPayerId = taxPayerId;
    if (stamp.setEnvironment === undefined) {
      stamp.setEnvironment = SET_ENVIRONMENT_DEVELOPMENT;
    }

    if (stamp.enabled === undefined) {
      stamp.enabled = true;
    }

    if (stamp.useLotsByDefault === undefined) {
      stamp.useLotsByDefault = true;
    }

    if (stamp.uploadDocuments === undefined) {
      stamp.uploadDocuments = true;
    }

    let requestPromise: Promise<ItemRequestStatus<Stamp>>;
    if (stamp.id === 0 || stamp.id === undefined) {
      requestPromise = createItem("/stamps/create/", stamp);
    } else {
      requestPromise = updateItem(`/stamps/${stamp.id}/`, stamp);
    }

    const stampStatus = await requestPromise;

    if (stampStatus.status !== SUCCESS) {
      if (stampStatus.errors !== undefined) {
        setErrors(stampStatus.errors);
      }

      let message = "Ha ocurrido un error!!";
      if (stampStatus.detail !== undefined) {
        message = stampStatus.detail;
      }
      errorAlert(message);
      setSubmitting(false);
    } else {
      if (shouldClearOnSuccess) {
        setErrors({});
        clearForm();
      }

      onSuccess();
    }

    if (shouldClearOnSuccess) {
      setSubmitting(false);
    }
  };

  const onClose = () => {
    clearForm();
    onCancel();
  };

  const clearForm = () => {
    updateStampRef(newStamp());
  };

  useEffect(() => {
    updateStampRef(stamp ? stamp : newStamp());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stamp]);

  return (
    <>
      <fieldset disabled={submitting}>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>N° de Timbrado:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="number"
                defaultValue={
                  stampRef.current ? stampRef.current.identifier : ""
                }
                onChange={onIdentifierChange}
                placeholder="N° de Timbrado"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("identifier", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Fecha de Timbrado:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="date"
                defaultValue={
                  stampRef.current
                    ? formatDate(
                        new Date(
                          stampRef.current.beginDate
                            ? stampRef.current.beginDate.slice(0, -1)
                            : ""
                        )
                      )
                    : ""
                }
                onChange={onBeginDateChange}
                placeholder="Ej: Fecha de Timbrado"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("beginDate", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CRow>
          <CCol>
            <h4>Configuración</h4>
          </CCol>
        </CRow>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Habilitado:</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  defaultValue={
                    stampRef.current ? stampRef.current.enabled : true
                  }
                  onChange={onEnabledChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("enabled", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Tipo:</CLabel>
            </CCol>
            <CCol>
              <CSelect
                type="text"
                defaultValue={
                  stampRef.current
                    ? stampRef.current.setEnvironment
                    : SET_ENVIRONMENT_DEVELOPMENT
                }
                onChange={onSetEnvironmentChange}
              >
                <option value={""} disabled>
                  -----
                </option>
                {Array.from(SET_ENVIRONMENT_CHOICES.entries()).map((entry) => {
                  return (
                    <option key={entry[0]} value={entry[0]}>
                      {entry[1]}
                    </option>
                  );
                })}
              </CSelect>
              <FieldErrors
                errors={getFieldErrors("setEnvironment", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Usar lotes:</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  defaultValue={
                    stampRef.current ? stampRef.current.useLotsByDefault : true
                  }
                  onChange={onUseLotsByDefaultChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("useLotsByDefault", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Subir documentos:</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  defaultValue={
                    stampRef.current ? stampRef.current.uploadDocuments : true
                  }
                  onChange={onUploadDocumentsChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("uploadDocuments", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>ID CSC:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={stampRef.current ? stampRef.current.idCsc : ""}
                onChange={onIdCscChange}
                placeholder="ID CSC"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("idCsc", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>CSC:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={stampRef.current ? stampRef.current.csc : ""}
                onChange={onCscChange}
                placeholder="CSC"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("csc", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Firma Digital:</CLabel>
            </CCol>
            <CCol>
              <CInputFile
                accept=".p12, "
                type="file"
                innerRef={signature}
                onChange={onSignatureChange}
              ></CInputFile>
              {loadedSignaturePath ? (
                <small>
                  Seleccionado actualmente:
                  <CLink href={`#`} target="_blank">
                    {loadedSignaturePath}
                  </CLink>
                </small>
              ) : (
                <></>
              )}

              <FieldErrors
                errors={getFieldErrors("signaturePath", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Contraseña de Firma Digital:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="password"
                defaultValue={
                  stampRef.current ? stampRef.current.signaturePassword : ""
                }
                onChange={onSignaturePasswordChange}
                placeholder="Contraseña"
              ></CInput>
              <FieldErrors
                errors={getFieldErrors("signaturePassword", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Logo:</CLabel>
            </CCol>
            <CCol>
              <CInputFile
                accept=".png, .jpg"
                type="file"
                innerRef={logo}
                onChange={onLogoChange}
              ></CInputFile>
              {loadedLogoPath ? (
                <small>
                  Seleccionado actualmente:
                  <CLink href={`#`} target="_blank">
                    {loadedLogoPath}
                  </CLink>
                </small>
              ) : (
                <></>
              )}

              <FieldErrors
                errors={getFieldErrors("logoPath", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Enviar DEs por Correo:</CLabel>
            </CCol>
            <CCol>
              <CRow>
                <AnimatedCheckbox
                  defaultValue={
                    stampRef.current ? stampRef.current.emailEnableSend : true
                  }
                  onChange={onEmailEnableSendChange}
                ></AnimatedCheckbox>
              </CRow>

              <FieldErrors
                errors={getFieldErrors("emailEnableSend", errors) as string[]}
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup>
          <CRow>
            <CCol md={2}>
              <CLabel>Receptores de correo adicionales:</CLabel>
            </CCol>
            <CCol>
              <CInput
                type="text"
                defaultValue={
                  stampRef.current
                    ? stampRef.current.emailAdditionalReceptors
                    : ""
                }
                onChange={onEmailAdditionalReceptorsChange}
                placeholder="Dirección de email"
              ></CInput>
              <FieldErrors
                errors={
                  getFieldErrors("emailAdditionalReceptors", errors) as string[]
                }
              ></FieldErrors>
            </CCol>
          </CRow>
        </CFormGroup>
        <CFormGroup className="float-right">
          <CButtonGroup>
            <CButton type="button" color="secondary" onClick={onClose}>
              Salir
            </CButton>
            <CButton type="submit" color="primary" onClick={onSave}>
              {submitting ? (
                <Spinner
                  animation="grow"
                  style={{
                    height: "17px",
                    width: "17px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                />
              ) : (
                <></>
              )}
              {submitting ? "Guardando..." : "Guardar"}
            </CButton>
          </CButtonGroup>
        </CFormGroup>
      </fieldset>
    </>
  );
};

export default StampForm;
