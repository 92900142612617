import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { login } from "../redux/actions/user";
import { UpdateUserAction } from "../redux/types/user";
import LocationState from "../routes/LocationState";
import { errorAlert } from "../components/utils/messages";
import { getRoutePath } from "../routes";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<LocationState>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(async (dispatch: ThunkDispatch<{}, {}, UpdateUserAction>) => {
      const success = await dispatch(login(email, password));
      if (success) {
        if (location.state !== undefined) {
          history.push(location.state.from);
        } else {
          history.push(getRoutePath("dashboard"));
        }
      } else {
        errorAlert("Error al iniciar Sesión");
      }
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="8">
            <CCardGroup>
              <CCard className="text-white  py-5">
                <CCardBody className="text-center">
                  <img
                    src="/SmartDoc.png"
                    alt="smart-doc"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </CCardBody>
              </CCard>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Iniciar Sesión</h1>
                    <p className="text-muted">Ingrese a su cuenta</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="text"
                        placeholder="Correo Electrónico"
                        autoComplete="username"
                        onChange={handleEmailChange}
                        value={email}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type="password"
                        placeholder="Contraseña"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        value={password}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={handleLogin}
                        >
                          Iniciar Sesión
                        </CButton>
                      </CCol>
                      <CCol xs="6" className="text-right">
                        <CButton color="link" className="px-0">
                          Olvidaste tu contraseña?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
